import { Close } from '@mui/icons-material'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { useMediaQuery, useTheme, IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import useSelectedAlbums from '../../hooks/useSelectedAlbums'
import useSelectedMedia from '../../hooks/useSelectedMedia'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
    fontWeight: 900
  }
}))

export default function DeselectButton() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { clearSelectedMedia, selectedMedia } = useSelectedMedia()
  const { clearSelectedAlbums, selectedAlbums, selectedModeEnabled } = useSelectedAlbums()

  // Displays Select Mode for Albums
  if (selectedModeEnabled) {
    if (isMobile) {
      return (
        <IconButton aria-label="Deselect Album" onClick={clearSelectedAlbums}>
          <StyledBadge badgeContent={selectedMedia.size.toString() || '0'}>
            <Close fontSize="large" />
          </StyledBadge>
        </IconButton>
      )
    }
    return (
      <Button aria-label="Deselect Album" startIcon={<Close />} onClick={clearSelectedAlbums} variant="text">
        {selectedAlbums.size || 0} Selected
      </Button>
    )
  }

  // Displays Select Mode for Media
  if (isMobile) {
    return (
      <IconButton aria-label="Deselect Media" onClick={clearSelectedMedia} data-test="appbar-selected-media">
        <StyledBadge badgeContent={selectedMedia.size.toString() || '0'}>
          <Close fontSize="large" />
        </StyledBadge>
      </IconButton>
    )
  }

  return (
    <Button aria-label="Deselect Media" data-test="appbar-selected-media" startIcon={<Close />} onClick={clearSelectedMedia} variant="text">
      {selectedMedia.size || 0} Selected
    </Button>
  )
}

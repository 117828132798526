import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined'
import { IconButton, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'
import { activeAlbumIdVar } from '../../../../../providers/apollo/cache'
import useBucketDownload from '../../../hooks/useBucketDownload'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'

export default function AlbumDownload() {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const { selectedAlbums } = useSelectedAlbums()
  const { downloadAlbums, downloadMultipleMediaResults } = useBucketDownload()
  const { loading } = downloadMultipleMediaResults
  const containsRootAlbum = Array.from(selectedAlbums.keys()).includes(bucketId as string)
  const disabled = !bucketId || containsRootAlbum || (selectedAlbums.size < 1 && !albumId)

  const onClick = () => {
    if (disabled) {
      return
    }

    const selectedAlbumIds = Array.from(selectedAlbums.keys())

    if (albumId) {
      activeAlbumIdVar(albumId)
      downloadAlbums(bucketId, [albumId])
    } else if (selectedAlbumIds.length > 0) {
      downloadAlbums(bucketId, selectedAlbumIds)
    } else {
      downloadAlbums(bucketId)
    }
  }

  return (
    <IconButton disabled={disabled} color="inherit" aria-label="Download Album" onClick={onClick} id="download-button" data-test="download-button">
      {loading && <CircularProgress size={20} />}
      {!loading && (
        <Tooltip title="Download Album" arrow>
          <DownloadIcon />
        </Tooltip>
      )}
    </IconButton>
  )
}

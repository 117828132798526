import { useApolloClient, useQuery } from '@apollo/client'
import { ArrowForward, Clear } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { debounce } from 'lodash'
import { useState } from 'react'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import { useMatch, useParams } from 'react-router-dom'
import useSelectedMedia from '../../../hooks/useSelectedMedia'
import useBucketGallerySettings from '../../../hooks/useBucketGallerySettings'
import useAlerts from '../../../hooks/useAlerts'
import useBucketMediaMove from '../../../hooks/useBucketMediaMove'
import {
  BucketAlbumFragmentFragmentDoc,
  BucketAlbumsDocument,
  BucketMediaByAlbumIdDocument,
  BucketMediaFragmentFragmentDoc
} from '../../../../../graphql/generated'
import logger from '../../../../../utils/logger'
import { activeAlbumIdVar, drawerVar } from '../../../../../providers/apollo/cache'
import AlbumSubHeader from './AlbumSubHeader'
import SelectAlbum from './SelectAlbum'

export default function MediaMoveWithInBucket() {
  const { selectedMedia, clearSelectedMedia } = useSelectedMedia()
  const { settings } = useBucketGallerySettings()
  const { filterBy, sortBy } = settings
  const { bucketId, albumId: currentAlbumId } = useParams<{ bucketId: string; albumId: string }>()
  const isRootBucket = useMatch('/bucket/:bucketId')
  const client = useApolloClient()
  const mediaIds = Array.from(selectedMedia.keys())
  const { createAlert } = useAlerts()
  const { mutation } = useBucketMediaMove()
  const [destinationAlbumId, setDestinationAlbumId] = useState<string | null>(null)
  const [selected, setSelected] = useState(false)

  const currentAlbum = client.cache.readFragment({ id: `BucketAlbum:${currentAlbumId}`, fragment: BucketAlbumFragmentFragmentDoc })

  const results = useQuery(BucketAlbumsDocument, {
    skip: !bucketId,
    variables: { bucketId: bucketId! },
    onError: (e) => {
      e.graphQLErrors.forEach((err) => {
        createAlert(err.message)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('We are having trouble retrieving your albums. Please try again later.')
      }
    }
  })
  const albums = results.data?.bucketAlbums?.items ?? []
  const nextToken = results.data?.bucketAlbums?.nextToken

  const func = () => {
    if (nextToken) {
      results.fetchMore({ variables: { nextToken } })
    }
  }
  const fetchMore = debounce(func, 500, { leading: true })

  const handleClose = () => {
    activeAlbumIdVar(null)
    drawerVar(null)
  }

  const setAlbumId = (albumId: string) => {
    setDestinationAlbumId(albumId)
  }

  const handleMove = () => {
    if (!bucketId) {
      return
    }

    mutation({
      variables: {
        bucketId,
        mediaIds,
        data: { destinationAlbumId }
      },
      update: (cache, { data }) => {
        if (!data?.bucketMediaMove) return

        data.bucketMediaMove?.forEach((id) => {
          // Retrieve current media
          const media = cache.readFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc })
          if (!media) return

          // Remove media from source album
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                albumId: media.albumId,
                filterBy,
                sortBy
              }
            },
            (cachedData) => {
              if (!media || !cachedData) return null

              const { bucketMediaByAlbumId } = cachedData
              const items = bucketMediaByAlbumId?.items ?? []
              const updatedItems = items.filter((item) => item.id !== media.id)

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items: [...updatedItems]
                }
              }
            }
          )

          // Add media to destination album
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                albumId: destinationAlbumId,
                filterBy,
                sortBy
              }
            },
            (cachedData) => {
              if (!media || !cachedData) return null

              const { bucketMediaByAlbumId } = cachedData
              const items = bucketMediaByAlbumId?.items ?? []

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items: [media, ...items]
                }
              }
            }
          )

          // Updated album id on media
          cache.updateFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc }, (current) => {
            if (!current) return null
            return {
              ...current,
              albumId: destinationAlbumId
            }
          })

          // Update source album media count
          cache.updateFragment(
            {
              id: `BucketAlbum:${media.albumId}`,
              fragment: BucketAlbumFragmentFragmentDoc
            },
            (current) => {
              if (!current) return null

              const totalMedia = Math.max((current?.counters?.totalMedia ?? 0) - 1, 0)

              return {
                ...current,
                counters: {
                  ...current.counters,
                  totalMedia
                }
              }
            }
          )
          // Update destination album media count
          cache.updateFragment(
            {
              id: `BucketAlbum:${destinationAlbumId}`,
              fragment: BucketAlbumFragmentFragmentDoc
            },
            (current) => {
              if (!current) return null

              const totalMedia = (current?.counters?.totalMedia ?? 0) + 1

              return {
                ...current,
                counters: {
                  ...current.counters,
                  totalMedia
                }
              }
            }
          )
        })
      },
      onCompleted: () => {
        createAlert('Media moved successfully', 'success')
        setSelected(false)
        clearSelectedMedia()
        handleClose()
      }
    })
  }

  const handleClick = () => {
    if (destinationAlbumId) {
      setDestinationAlbumId(null)
    }
    handleMove()
  }

  return (
    <>
      {!isRootBucket && (
        <Box>
          <Button
            data-test="move-media-drawer-submit"
            onClick={handleClick}
            variant="outlined"
            color="error"
            fullWidth
            sx={{ justifyContent: 'flex-start safe', gap: '5px' }}
          >
            <Clear />
            <Box component="span" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              Remove from {currentAlbum?.title} Album
            </Box>
          </Button>
          <Typography variant="caption" sx={{ display: 'flex', justifyContent: 'center', opacity: 0.5, marginTop: '4px' }}>
            This item will still be available in &quot;All Media&quot; after removal.
          </Typography>
        </Box>
      )}
      <Box>
        <List data-test="album">
          <AlbumSubHeader setAlbumId={setAlbumId} />
          <ReactInfiniteScroll
            dataLength={albums.length || 0}
            loader={
              <Typography variant="h6" align="center">
                Loading...
              </Typography>
            }
            next={fetchMore}
            hasMore={Boolean(nextToken)}
          >
            {albums.map((album) => (
              <SelectAlbum
                key={album.id}
                allItems={selected}
                setAllItems={setSelected}
                album={album}
                selectedAlbumId={destinationAlbumId}
                setSelectedAlbumId={setDestinationAlbumId}
              />
            ))}
          </ReactInfiniteScroll>
        </List>
        {(selected || destinationAlbumId) && (
          <Tooltip title={currentAlbumId === destinationAlbumId ? 'Media is already in this album.' : null} arrow>
            <div>
              <Button
                data-test="move-media-drawer-submit"
                onClick={handleMove}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={currentAlbumId === destinationAlbumId}
              >
                Move
                <ArrowForward sx={{ paddingLeft: '5px' }} />
              </Button>
            </div>
          </Tooltip>
        )}
      </Box>
    </>
  )
}

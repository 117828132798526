import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'

interface PasswordProps {
  showPassword: boolean
  handleClickShowPassword: () => void
}

export default function PasswordVisibilityAdornment({ showPassword, handleClickShowPassword }: PasswordProps) {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )
}

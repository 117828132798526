import { AwsRum } from 'aws-rum-web'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { useAnalytics } from 'use-analytics'
import { environment, rumConfig, rum as rumSettings } from '../../config'
import logger from '../../utils/logger'

interface Props {
  children: React.ReactNode
}

/**
 * As a general practice we should NOT be using Context as a global state management machine.
 * Our global state management should be handled by Apollo Client.
 *
 * This is a RARE scenario where we need to use Context to provide a global instance of the RUM "pixel" that is attached to the DOM.
 */
export const RumContext = createContext<AwsRum | null>(null)

export default function RumProvider({ children }: Readonly<Props>) {
  const [value, setValue] = useState<AwsRum | null>(null)
  const valueRef = useRef(value)
  const { page } = useAnalytics()
  const pageRef = useRef(page)
  const location = useLocation()

  useEffectOnce(() => {
    try {
      if (!rumSettings.applicationId) {
        logger.warn('RUM applicationId is not set')
        return
      }

      if (value) {
        return
      }

      const awsRum = new AwsRum(rumSettings.applicationId, rumSettings.version, rumSettings.region, rumConfig)
      awsRum.enable()
      setValue(awsRum)
      if (environment === 'stage') {
        logger.info('RUM initialized')
      }
    } catch (error) {
      logger.error(error)
      setValue(null)
    }
  })

  useEffect(() => {
    /* Track a page view */
    if (valueRef.current) {
      valueRef?.current.recordPageView({ pageId: location.pathname })
    }
    pageRef?.current()
  }, [location.pathname])

  return <RumContext.Provider value={value}>{children}</RumContext.Provider>
}

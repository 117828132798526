import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import useSelectedAlbums from '../hooks/useSelectedAlbums'
import useSelectedMedia from '../hooks/useSelectedMedia'

export default function ClearSelected() {
  const location = useLocation()
  const { clearSelectedMedia } = useSelectedMedia()
  const { clearSelectedAlbums } = useSelectedAlbums()
  const clearSelectedMediaRef = useRef(clearSelectedMedia)
  const clearSelectedAlbumsaRef = useRef(clearSelectedAlbums)

  useEffect(() => {
    const clearMedia = clearSelectedMediaRef.current
    const clearAlbums = clearSelectedAlbumsaRef.current
    clearMedia()
    clearAlbums()

    return () => {
      clearMedia()
      clearAlbums()
    }
  }, [location.pathname])

  return null
}

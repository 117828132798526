import { createContext } from 'react'
import { BucketUserRole } from '../../graphql/generated'

export type InviteCache = {
  [key in BucketUserRole]?: string
}

type ContextType = {
  inviteCache: InviteCache
  setInviteCache: (inviteCache: InviteCache) => void
  clearInviteFromCache: (invitationId: string) => void
}

export const InvitationContext = createContext<ContextType>({
  inviteCache: {},
  setInviteCache: () => {},
  clearInviteFromCache: () => {}
})

import { useLazyQuery } from '@apollo/client'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { isEmpty } from 'lodash'
import { ChangeEvent, useState } from 'react'
import { EmailAvailableDocument } from '../../../../../graphql/generated'
import { StyledFormError } from '../../../../auth/components/StyledFormAlert'
import useAlerts from '../../../hooks/useAlerts'

const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
const isEmail = (email: string) => emailPattern.test(email)

interface Props {
  goToScreen: (newState: 'REAUTHENTICATE' | 'SUCCESS') => void
  newEmail: string
  setNewEmail: (email: string) => void
}

export default function EnterEmail({ goToScreen, newEmail, setNewEmail }: Readonly<Props>) {
  const { createAlert } = useAlerts()
  const [emailAvailable, { loading }] = useLazyQuery(EmailAvailableDocument)
  const [error, setError] = useState<string>('')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value)
    setError('')
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (loading) return
    const user = getAuth().currentUser
    if (!user) {
      createAlert('There was an issue with your session, please logout and try again.')
      return
    }
    if (!isEmail(newEmail)) {
      setError('The email you entered is not valid.')
      return
    }
    emailAvailable({
      variables: { email: newEmail },
      onCompleted: (data) => {
        if (!data.emailAvailable) {
          setError('The email you entered is not available')
          return
        }
        goToScreen('REAUTHENTICATE')
      }
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography>Current Email</Typography>
      <Typography variant="h6">{getAuth().currentUser?.email}</Typography>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={5}>
          {error && <StyledFormError title="Error changing email" body={error} />}
          <TextField
            fullWidth
            disabled={loading}
            error={!isEmpty(error)}
            id="email-new-email"
            name="new-email"
            label="New Email"
            helperText={error}
            value={newEmail}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 1 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button fullWidth id="email-submit" type="submit" variant="contained" disabled={loading}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

import { Grid, Tooltip, Box } from '@mui/material'
import { Facebook as FacebookIcon, Pinterest as PinterestIcon, X as TwitterIcon } from '@mui/icons-material'
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from 'react-share'
import { useApolloClient } from '@apollo/client'
import { generateDirectLink, generateShareLink } from '../../../utils/urlUtils'
import useSelectedMedia from '../../../hooks/useSelectedMedia'
import { BucketMedia, BucketMediaFragmentFragmentDoc } from '../../../../../graphql/generated'

interface SharingPostProps {
  mediaCount: number
}

function SharingPost({ mediaCount }: Readonly<SharingPostProps>) {
  const { selectedMedia } = useSelectedMedia()
  const client = useApolloClient()
  const mediaIds = Array.from(selectedMedia.keys())
  const bucketMedia: BucketMedia[] = []

  mediaIds.forEach((id) => {
    const media = client.readFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc }) as BucketMedia
    if (media) {
      bucketMedia.push(media)
    }
  })

  const tooltipTitle = mediaCount === 1 ? '' : 'This option is only available for individual media items. Please select one photo or video.'
  const shareUrl = bucketMedia.length > 0 ? generateDirectLink(bucketMedia[0].imageUrl ?? '') : generateShareLink('', '')

  return (
    <Box sx={{ padding: '20px 24px' }}>
      <Grid container justifyContent="center" sx={{ gap: '10px' }}>
        <Grid item xs="auto">
          <Tooltip arrow title={tooltipTitle}>
            <div>
              <FacebookShareButton url={shareUrl} data-test="facebook" id="share-facebook-btn" disabled={mediaCount !== 1}>
                <FacebookIcon
                  sx={{
                    padding: '10px',
                    height: '60px',
                    width: '60px',
                    borderRadius: '50%',
                    color: 'white',
                    backgroundColor: 'rgb(59, 89, 152)',
                    cursor: mediaCount === 1 ? 'pointer' : 'not-allowed',
                    opacity: mediaCount === 1 ? 1 : 0.5
                  }}
                  fontSize="large"
                />
              </FacebookShareButton>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip arrow title={tooltipTitle}>
            <div>
              <TwitterShareButton url={shareUrl} data-test="twitter" id="share-twitter-btn" disabled={mediaCount !== 1}>
                <TwitterIcon
                  sx={{
                    padding: '10px',
                    height: '60px',
                    width: '60px',
                    borderRadius: '50%',
                    color: 'white',
                    backgroundColor: 'rgb(38, 166, 209)',
                    cursor: mediaCount === 1 ? 'pointer' : 'not-allowed',
                    opacity: mediaCount === 1 ? 1 : 0.5
                  }}
                  fontSize="large"
                />
              </TwitterShareButton>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip arrow title={tooltipTitle}>
            <div>
              <PinterestShareButton url={shareUrl} media={shareUrl} data-test="pinterest" id="share-pinterest-btn" disabled={mediaCount !== 1}>
                <PinterestIcon
                  sx={{
                    padding: '10px',
                    height: '60px',
                    width: '60px',
                    borderRadius: '50%',
                    color: 'white',
                    backgroundColor: 'rgb(200, 35, 44)',
                    cursor: mediaCount === 1 ? 'pointer' : 'not-allowed',
                    opacity: mediaCount === 1 ? 1 : 0.5
                  }}
                  fontSize="large"
                />
              </PinterestShareButton>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SharingPost

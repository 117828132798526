import { useMutation } from '@apollo/client'
import { BanImagesDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

export default function useBanImages() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(BanImagesDocument, {
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message)
          logger.error(e)
        })
        return
      }
      logger.error(error)
      createAlert('There was a problem baning the selected images. Please try again later.')
    },
    onCompleted: () => {
      createAlert('Images have been successfully banned.', 'success')
    }
  })

  return { mutation, results }
}

import { useApolloClient, useReactiveVar } from '@apollo/client'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import { BucketShareFragmentFragmentDoc, BucketShareStatus } from '../../../../graphql/generated'
import { activeShareIdVar, modalVar } from '../../../../providers/apollo/cache'
import useBucketShareUpdate from '../../hooks/useBucketShareUpdate'
import useAlerts from '../../hooks/useAlerts'

export default function MediaShareDelete() {
  const { createAlert } = useAlerts()
  const modal = useReactiveVar(modalVar)
  const shareId = useReactiveVar(activeShareIdVar)
  const client = useApolloClient()
  const share = client.readFragment({ id: `BucketShare:${shareId}`, fragment: BucketShareFragmentFragmentDoc })
  const theme = useTheme()
  const { bucketId } = useParams<{ bucketId: string }>()
  const { mutation, results } = useBucketShareUpdate()

  const handleClose = () => {
    modalVar(null)
    activeShareIdVar(null)
  }

  const handleUpdateLink = () => {
    if (!bucketId || !shareId) return

    const status = BucketShareStatus.Deleted
    const variables = { bucketId, shareId, status }
    mutation({
      variables,
      update: (cache) => {
        modalVar(null)
        activeShareIdVar(null)
        cache.evict({ id: `BucketShare:${shareId}` })
      },
      onCompleted: () => {
        createAlert(`Link sharing stopped for ${share?.title}`, 'success')
      }
    })
  }

  return (
    <Dialog open={modal === 'bucketMediaShareDelete'} onClose={handleClose} maxWidth="md" fullWidth disableScrollLock>
      {results.loading && <LinearProgress />}
      <DialogTitle>Remove Sharing Link {share?.title ? `'${share.title}'` : null}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.down('md')]: {
            padding: '20px'
          }
        }}
      >
        <Typography>
          Are you sure you want to remove this Sharing Link? This will remove the Sharing Link and stop sharing its public sharing page. This action
          cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions style={{ marginTop: '12px' }}>
        <Button variant="outlined" color="primary" disabled={results.loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" disabled={results.loading} onClick={handleUpdateLink}>
          Remove Sharing Link
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { useApolloClient } from '@apollo/client'
import Backdrop from '@mui/material/Backdrop'
import { styled } from '@mui/material/styles'
import { isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  BucketAlbumFragmentFragmentDoc,
  BucketMedia,
  BucketMediaByAlbumIdDocument,
  BucketMediaFragmentFragmentDoc
} from '../../../../../graphql/generated'
import { selectModeEnabledVar, selectedMediaVar } from '../../../../../providers/apollo/cache'
import useAlerts from '../../../hooks/useAlerts'
import useBucketGallerySettings from '../../../hooks/useBucketGallerySettings'
import useBucketMediaMove from '../../../hooks/useBucketMediaMove'
import useSelectedMedia from '../../../hooks/useSelectedMedia'

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  zIndex: theme.zIndex.drawer - 1
}))

interface Props {
  albumId: string
}

export default function DragAndDropToAlbum({ albumId: destinationAlbumId }: Props) {
  const { createAlert } = useAlerts()
  const client = useApolloClient()
  const { bucketId, albumId: sourceAlbumId } = useParams<{ bucketId: string; albumId: string }>()
  const { mutation } = useBucketMediaMove()
  const { settings } = useBucketGallerySettings()
  const { selectedMedia } = useSelectedMedia()
  const { filterBy, sortBy } = settings
  const ref = useRef<HTMLDivElement>(null)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const el = ref.current?.parentElement
    if (!el) return () => {}

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setIsActive(true)
    }

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setIsActive(false)
    }

    const handleDrop = (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()

      setIsActive(false)

      const id = e.dataTransfer?.getData('id')
      e.dataTransfer?.clearData()

      if (!id || isEmpty(id) || sourceAlbumId === destinationAlbumId) return

      const ids: string[] = []

      // If all selected media
      if (id === 'all') {
        ids.push(...Array.from(selectedMedia.keys()))
      } else {
        ids.push(id)
      }

      // Filter out all media that already exists in the target album
      const mediaIds = ids.filter((mediaId) => {
        const media = client.readFragment<BucketMedia>({ id: `BucketMedia:${mediaId}`, fragment: BucketMediaFragmentFragmentDoc })
        return media && media.albumId !== destinationAlbumId
      })

      // Compare the filtered ids to the original array of ids to determine if some media already existed in album
      if (mediaIds.length !== ids.length) {
        createAlert('One or more images already exist in selected album', 'info')
      }

      if (!bucketId || isEmpty(mediaIds)) {
        return
      }

      mutation({
        variables: { bucketId, mediaIds, data: { destinationAlbumId } },
        update: (cache, { data }) => {
          if (!data?.bucketMediaMove) return

          data.bucketMediaMove?.forEach((mediaId) => {
            // Retrieve current media
            const media = cache.readFragment({ id: `BucketMedia:${mediaId}`, fragment: BucketMediaFragmentFragmentDoc })
            if (!media) return

            // Remove media from source album
            cache.updateQuery(
              {
                query: BucketMediaByAlbumIdDocument,
                variables: {
                  bucketId,
                  albumId: media.albumId,
                  filterBy,
                  sortBy
                }
              },
              (cachedData) => {
                if (!media || !cachedData) return null

                const { bucketMediaByAlbumId } = cachedData
                const items = bucketMediaByAlbumId?.items || []
                const updatedItems = items.filter((item) => item.id !== media.id)

                return {
                  bucketMediaByAlbumId: {
                    ...bucketMediaByAlbumId,
                    items: [...updatedItems]
                  }
                }
              }
            )

            // Add media to destination album
            cache.updateQuery(
              {
                query: BucketMediaByAlbumIdDocument,
                variables: {
                  bucketId,
                  albumId: destinationAlbumId,
                  filterBy,
                  sortBy
                }
              },
              (cachedData) => {
                if (!media || !cachedData) return null

                const { bucketMediaByAlbumId } = cachedData
                const items = bucketMediaByAlbumId?.items || []

                return {
                  bucketMediaByAlbumId: {
                    ...bucketMediaByAlbumId,
                    items: [media, ...items]
                  }
                }
              }
            )

            // Updated album id on media
            cache.updateFragment({ id: `BucketMedia:${mediaId}`, fragment: BucketMediaFragmentFragmentDoc }, (current) => {
              if (!current) return null
              return {
                ...current,
                albumId: destinationAlbumId
              }
            })

            // Update source album media count
            cache.updateFragment(
              {
                id: `BucketAlbum:${media.albumId}`,
                fragment: BucketAlbumFragmentFragmentDoc
              },
              (current) => {
                if (!current) return null

                const totalMedia = Math.max((current?.counters?.totalMedia ?? 0) - 1, 0)

                return {
                  ...current,
                  counters: {
                    ...current.counters,
                    totalMedia
                  }
                }
              }
            )
            // Update destination album media count
            cache.updateFragment(
              {
                id: `BucketAlbum:${destinationAlbumId}`,
                fragment: BucketAlbumFragmentFragmentDoc
              },
              (current) => {
                if (!current) return null

                const totalMedia = (current?.counters?.totalMedia ?? 0) + 1

                return {
                  ...current,
                  counters: {
                    ...current.counters,
                    totalMedia
                  }
                }
              }
            )
          })
        }
      })

      selectModeEnabledVar({})
      selectedMediaVar([])
    }

    el.addEventListener('dragover', handleDragOver)
    el.addEventListener('drop', handleDrop)
    el.addEventListener('dragleave', handleDragLeave)

    return () => {
      el.removeEventListener('dragover', handleDragOver)
      el.removeEventListener('drop', handleDrop)
      el.removeEventListener('dragleave', handleDragLeave)
    }
  }, [bucketId, destinationAlbumId, mutation, client, createAlert, sourceAlbumId, filterBy, selectedMedia, sortBy])

  return <CustomBackdrop ref={ref} open={sourceAlbumId !== destinationAlbumId && isActive} />
}

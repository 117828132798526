import { useReactiveVar } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { modalVar } from '../../../../providers/apollo/cache'
import useUserProfileUpdate from '../../hooks/useUserProfileUpdate'

export default function TermsAndConditions() {
  const modal = useReactiveVar(modalVar)
  const { mutation, loading } = useUserProfileUpdate()
  const [showCancel, setShowCancel] = useState(false)

  const decline = () => {
    setShowCancel(true)
  }

  const declineAndCancel = () => {
    if (loading) return
    mutation({
      variables: { data: { termsAccepted: false } },
      onCompleted() {
        window.location.href = 'https://my.photobucket.com/photobucket-account-deletion-step1'
      }
    })
  }

  const accept = () => {
    if (loading) return
    mutation({
      variables: { data: { termsAccepted: true } },
      onCompleted() {
        modalVar(null)
      }
    })
  }

  return (
    <Dialog data-test="invite-people-dialog" open={modal === 'termsAndConditions'}>
      <DialogTitle data-test="invite-users-title" sx={{ textAlign: 'center', padding: { xs: '10px', sm: '20px 24px' } }}>
        We&apos;ve Updated Our Policies
      </DialogTitle>
      <DialogContent sx={{ padding: { xs: '20px 10px', sm: '20px 24px' } }}>
        <Typography>
          Please agree to our{' '}
          <Link to="https://photobucket.com/legal/terms-of-service" target="_blank" data-test="terms-tou">
            Terms of Use
          </Link>
          ,{' '}
          <Link to="https://photobucket.com/legal/privacy-policy" target="_blank" data-test="terms-privacy">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link to="https://photobucket.com/legal/biometric-information-privacy-policy" target="_blank" data-test="terms-biometric">
            Biometric Information Privacy Policy
          </Link>{' '}
          before continuing.
        </Typography>
      </DialogContent>
      {showCancel && (
        <DialogContent sx={{ padding: { xs: '20px 10px', sm: '20px 24px' } }}>
          <Typography>
            If you do not agree to our updated policies, you will need to delete your account. If you need support, you can{' '}
            <Link to="https://support.photobucket.com/hc/en-us" target="_blank" data-test="terms-contact">
              contact our support team.
            </Link>
          </Typography>
        </DialogContent>
      )}
      <DialogActions sx={{ mx: 2, mb: 1, justifyContent: 'space-between' }}>
        {!showCancel && (
          <Button color="error" variant="text" onClick={decline} disabled={loading} data-test="terms-disagree">
            I Do Not Agree
          </Button>
        )}
        {showCancel && (
          <Button color="error" variant="text" onClick={declineAndCancel} disabled={loading} data-test="terms-delete">
            I Do Not Agree, Delete My Account
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={accept}
          disabled={loading}
          data-test={showCancel ? 'terms-agree-second' : 'terms-agree-first'}
        >
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}

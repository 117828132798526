import { Button } from '@mui/material'
import { styled } from '@mui/styles'

export default styled(Button)(({ theme }) => ({
  boxShadow: '0px 1px 4px rgba(16, 24, 40, 0.25)',
  background: 'linear-gradient(180deg, #202CD3 5.3%, #02E39F 102.34%)',
  color: theme.palette.primary.contrastText,
  fontWeight: 500,
  fontSize: '1.125rem',
  lineHeight: '20px',
  textTransform: 'none',
  borderRadius: '40px',
  padding: '12px 16px',
  marginTop: 0,
  marginBottom: '32px',
  '&:hover': {
    background: 'linear-gradient(180deg, #202CD3 5.3%, #02E39F 140.34%)',
    boxShadow: 'none'
  },
  '&:focus, &:active': {
    color: theme.palette.primary.contrastText
  },
  '&:disabled': {
    opacity: 0.4,
    color: theme.palette.primary.contrastText
  }
}))

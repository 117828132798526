import { createTheme } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'
import { merge } from 'lodash'
import { DefaultThemeValues } from '../expanded-theme'

export const defaultLightThemeValues: DefaultThemeValues = {
  palette: {
    brand: {
      50: '#EFF6FF',
      100: '#DBEAFE',
      200: '#BFDBFE',
      300: '#93C5FD',
      400: '#60A5FA',
      500: '#3B82F6',
      600: '#2563EB',
      700: '#1D4ED8',
      800: '#1E40AF',
      900: '#1E3A8A'
    },
    customBlue: {
      50: '#E8F0FE',
      100: '#B9D6FA',
      200: '#89BBF7',
      300: '#5AA0F3',
      400: '#2A85F0',
      500: '#1C47CB',
      600: '#183B9C',
      700: '#14306D',
      800: '#10253E',
      900: '#0B1A0F'
    },
    customGrey: {
      50: '#F8FAFC',
      100: '#F1F5F9',
      200: '#E2E8F0',
      300: '#CBD5E1',
      400: '#94A3B8',
      500: '#64748B',
      600: '#475569',
      700: '#334155',
      800: '#1E293B',
      900: '#0F172A'
    },
    customError: {
      50: '#FFF1F2',
      100: '#FFE4E6',
      200: '#FECDD3',
      300: '#FDA4AF',
      400: '#FB7185',
      500: '#F43F5E',
      600: '#E11D48',
      700: '#BE123C',
      800: '#9F1239',
      900: '#881337'
    },
    customSuccess: {
      50: '#ECFDF5',
      100: '#D1FAE5',
      200: '#A7F3D0',
      300: '#6EE7B7',
      400: '#34D399',
      500: '#10B981',
      600: '#059669',
      700: '#047857',
      800: '#065F46',
      900: '#064E3B'
    }
  }
}

const theme = createTheme(
  merge(defaultLightThemeValues, {
    palette: {
      mode: 'light' as PaletteMode,
      primary: {
        main: '#1C47CB',
        contrastText: '#fff',
        highlight: '#E8EDFA'
      },
      secondary: {
        main: '#344054',
        contrastText: '#fff'
      },
      warning: {
        main: '#FEC84B',
        contrastText: '#fff'
      },
      error: {
        main: '#DC362E',
        contrastText: '#fff'
      }
    },
    components: {
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '0 !important'
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '&.Mui-expanded': {
              alignItems: 'center'
            },
            '&.Mui-content': {
              alignItems: 'center'
            }
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          barColorPrimary: {
            backgroundColor: `#1C47CB !important`
          },
          colorPrimary: {
            backgroundColor: '#dfdfdf !important',
            opacity: 0.7
          },
          barColorSecondary: {
            backgroundColor: `#344054 !important`
          },
          colorSecondary: {
            backgroundColor: '#dfdfdf !important',
            opacity: 0.7
          }
        }
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: 12,
            justifyContent: 'flex-end'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none' as const
          }
        }
      }
    },
    typography: {
      fontFamily: '"Outfit", Arial, sans-serif'
    }
  })
)

export default theme

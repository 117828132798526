import { useReactiveVar } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BucketAlbumFragmentFragmentDoc,
  BucketFragmentFragmentDoc,
  BucketMediaByAlbumIdDocument,
  BucketUserRole
} from '../../../../graphql/generated'
import { activeAlbumIdVar, modalVar } from '../../../../providers/apollo/cache'
import logger from '../../../../utils/logger'
import useBucketAlbumsMove from '../../hooks/useBucketAlbumsMove'
import useBucketGallerySettings from '../../hooks/useBucketGallerySettings'
import useSelectedAlbums from '../../hooks/useSelectedAlbums'
import useBucket from '../../hooks/useBucket'

export default function AlbumDeleteModal() {
  const { bucketId } = useParams<{ bucketId: string }>()
  const { mutation } = useBucketAlbumsMove()
  const { bucketUser } = useBucket()
  const isAdminOrOwner = bucketUser?.userRole === BucketUserRole.Admin || bucketUser?.userRole === BucketUserRole.Owner
  const modal = useReactiveVar(modalVar)
  const { settings } = useBucketGallerySettings()
  const { filterBy, sortBy } = settings
  const { selectedAlbums, clearSelectedAlbums } = useSelectedAlbums()
  const activeAlbumId = useReactiveVar(activeAlbumIdVar)
  const theme = useTheme()
  const count = Math.max(selectedAlbums.size, 1)
  const noun = count === 1 ? 'album' : 'albums'
  const navigate = useNavigate()

  const handleClose = () => {
    modalVar(null)
  }

  const handleSubmit = (action: 'saveContent' | 'deleteContent') => () => {
    const albumIds = activeAlbumId ? [activeAlbumId] : Array.from(selectedAlbums.keys())
    logger.debug('Delete Albums', albumIds)
    if (!bucketId) return
    mutation({
      variables: {
        bucketId,
        albumIds,
        data: {
          deleteAlbum: true,
          moveMediaToTrash: action === 'deleteContent'
        }
      },
      update: (cache, { data }) => {
        if (!data?.bucketAlbumsMove) return

        data.bucketAlbumsMove?.forEach((albumId) => {
          const bucketAlbum = cache.readFragment({
            id: `BucketAlbum:${albumId}`,
            fragment: BucketAlbumFragmentFragmentDoc
          })

          const bucketAlbumId = bucketAlbum?.bucketId !== bucketAlbum?.parentId ? bucketAlbum?.parentId : undefined

          // Update the Parent Bucket Album's counters if it exists
          cache.updateFragment({ id: `BucketAlbum:${bucketAlbumId}`, fragment: BucketAlbumFragmentFragmentDoc }, (current) => {
            if (!current) return null

            const subAlbumCount = Math.max((current.subAlbumCount || 0) - 1, 0)
            const totalSubalbums = Math.max((current.counters?.totalSubalbums || 0) - 1, 0)

            return {
              ...current,
              subAlbumCount,
              counters: {
                ...current.counters,
                totalSubalbums
              }
            }
          })

          if (action === 'deleteContent') {
            const album = cache.readQuery({
              query: BucketMediaByAlbumIdDocument,
              variables: {
                albumId,
                bucketId,
                filterBy,
                sortBy
              }
            })

            const albumItems = album?.bucketMediaByAlbumId?.items || []

            albumItems.forEach((media) => {
              // Modify bucket media count
              cache.updateFragment(
                {
                  id: `Bucket:${bucketId}`,
                  fragment: BucketFragmentFragmentDoc
                },
                (current) => {
                  if (!current) return null

                  const totalMediaMinusTrash = current.counters?.totalMediaMinusTrash ?? 0
                  const totalMediaInTrash = current?.counters?.totalMediaInTrash ?? 0
                  const fileSize = media?.fileSize ?? 0
                  const totalSizeInTrash = current?.counters?.totalSizeInTrash ?? 0

                  return {
                    ...current,
                    counters: {
                      ...current.counters,
                      totalMediaMinusTrash: totalMediaMinusTrash - 1,
                      totalMediaInTrash: totalMediaInTrash + 1,
                      totalSizeInTrash: totalSizeInTrash + fileSize
                    }
                  }
                }
              )

              // Remove media from bucket
              cache.updateQuery(
                {
                  query: BucketMediaByAlbumIdDocument,
                  variables: {
                    bucketId,
                    filterBy,
                    sortBy
                  }
                },
                (cachedData) => {
                  if (!media || !cachedData) return null

                  const { bucketMediaByAlbumId } = cachedData
                  const currentItems = bucketMediaByAlbumId?.items || []
                  const items = currentItems.filter((item) => item.id !== media.id)

                  return {
                    bucketMediaByAlbumId: {
                      ...bucketMediaByAlbumId,
                      items
                    }
                  }
                }
              )
            })
          }
          cache.evict({ id: cache.identify({ __typename: 'BucketAlbum', id: albumId }) })
          cache.gc()
        })
      },
      onCompleted: () => {
        clearSelectedAlbums()
        modalVar(null)
      }
    })
    activeAlbumIdVar(null)
    navigate(`/bucket/${bucketId}`)
  }

  return (
    <Dialog data-test="delete-modal" open={modal === 'bucketAlbumDelete'} onClose={handleClose} maxWidth="sm" fullWidth disableScrollLock>
      <CloseIcon sx={{ position: 'absolute', cursor: 'pointer', right: 10, top: 10 }} onClick={handleClose} data-test="close-modal" />
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '80px',
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            padding: '20px'
          }
        }}
      >
        <div style={{ background: 'rgba(6, 114, 203, 0.08)', borderRadius: '50%', width: '64px', height: '64px', textAlign: 'center' }}>
          <DeleteIcon color="primary" style={{ width: '30px', height: '100%' }} />
        </div>
        <Typography sx={{ fontWeight: 600, fontSize: 28, lineHeight: '32px', color: '#081230' }}>Are you sure you want to delete?</Typography>
        <Typography sx={{ color: '#2F364E', lineHeight: '24px', marginTop: '24px', marginBottom: '48px', maxWidth: '80%' }}>
          You are about to delete {count} {noun}.
        </Typography>
        <DialogActions
          sx={{
            width: '100%',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            },
            '& button': {
              width: '50%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                margin: '4px 0'
              }
            }
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            sx={{ backgroundColor: theme.palette.warning.main }}
            onClick={handleSubmit('deleteContent')}
            data-testid="album-confirm-delete-album-and-contents"
            disabled={!isAdminOrOwner}
          >
            Delete {noun} and its contents
          </Button>
          <Button
            data-testid="album-confirm-delete-album-keep-contents"
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleSubmit('saveContent')}
            data-test="secondary-confirm-modal"
          >
            Delete {noun} and keep its content in the bucket
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

import { useApolloClient } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { useParams } from 'react-router-dom'
import { ProfileMigrationStatus, UserProfileFragmentFragmentDoc } from '../../../graphql/generated'

export default function useBucketMigrationProgress() {
  const client = useApolloClient()
  const { bucketId } = useParams<{ bucketId: string }>()
  const userId = getAuth().currentUser?.uid
  const userProfile = client.cache.readFragment({
    id: `UserProfile:${userId}`,
    fragment: UserProfileFragmentFragmentDoc,
    fragmentName: 'UserProfileFragment'
  })
  const isMigrationInProgressForBucket =
    userProfile?.migrationMetadata?.migrationStatus !== ProfileMigrationStatus.Completed &&
    userProfile?.migrationMetadata?.migrationStatus !== ProfileMigrationStatus.NotNeeded &&
    userProfile?.migrationMetadata?.bucketId === bucketId

  return isMigrationInProgressForBucket
}

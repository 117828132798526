import { Code as CodeIcon, Link as LinkIcon, ContentCopyOutlined } from '@mui/icons-material'
import { TextField, Box, Button, IconButton, Tooltip } from '@mui/material'
import { MouseEvent, useState, useEffect, useRef } from 'react'
import { joinLinks } from '../../../utils/urlUtils'
import SharingLink from './SharingLink'
import useShareLinkBuilder from './hooks/useShareLinkBuilder'

interface SizeProps {
  width: number
  height: number
}

interface ImageSize {
  title: string
  key: string
  size: SizeProps
}

export interface MediaProps {
  url: string
  title: string
  id: string
  albumId: string
  owner: string
}

interface ShareProps {
  setCurrentLink: (link: string) => void
  handleHostingLinkCopy: () => void
}

const sharingLinksConfig = [
  {
    title: 'Direct link',
    icon: <LinkIcon fontSize="large" />,
    key: 'direct'
  },
  {
    title: 'Embed link',
    icon: <CodeIcon fontSize="large" />,
    key: 'embed'
  }
]

const defaultEmbedTypes = [
  {
    title: 'HTML',
    key: 'html'
  },
  {
    title: 'Img',
    key: 'img'
  },
  {
    title: 'iFrame',
    key: 'iframe'
  }
]

const imageSizes: ImageSize[] = [
  {
    title: 'Thumb',
    key: 'xs',
    size: {
      height: 180,
      width: 180
    }
  },
  {
    title: 'Small',
    key: 'sm',
    size: {
      height: 370,
      width: 590
    }
  },
  {
    title: 'Medium',
    key: 'md',
    size: {
      height: 720,
      width: 960
    }
  },
  {
    title: 'Large',
    key: 'lg',
    size: {
      height: 1080,
      width: 1920
    }
  }
]

function Sharing({ setCurrentLink, handleHostingLinkCopy }: Readonly<ShareProps>) {
  // Use a ref here to avoid unnecessary re-renders, since using a ref does not
  // require being specified as a dependency in useEffect below
  const setCurrentLinkRef = useRef(setCurrentLink)
  const embedTypes = defaultEmbedTypes
  const [activeTab, setActiveTab] = useState(sharingLinksConfig[0].key)
  const [activeEmbedTab, setActiveEmbedTab] = useState(embedTypes[0].key)
  const [selectedImageSize, setSelectedImageSize] = useState(imageSizes[0].key)
  const { sharingLinks } = useShareLinkBuilder(imageSizes.filter((s) => s.key === selectedImageSize)[0].size)

  const neededTab = activeTab === 'direct' ? activeTab : activeEmbedTab
  const currLink = sharingLinks.reduce(
    (acc, current) => ({
      direct: joinLinks(acc.direct, current.direct),
      html: joinLinks(acc.html, current.html),
      img: joinLinks(acc.img, current.img),
      iframe: joinLinks(acc.iframe, current.iframe)
    }),
    {}
  )[neededTab]

  useEffect(() => {
    setCurrentLinkRef.current(currLink)
  }, [currLink])

  const onIconClickHandler = (key: string) => {
    setActiveTab(key)
  }

  const onEmbedButtonsHandler = (event: MouseEvent<HTMLButtonElement>) => {
    setActiveEmbedTab(event.currentTarget.name)
  }

  const onImageSizeButtonsHandler = (imageSize: ImageSize) => {
    setSelectedImageSize(imageSize.key)
  }

  return (
    <Box sx={{ padding: '20px 24px' }}>
      <Box display="flex" alignItems="center" justifyContent="center" gap="10px">
        {sharingLinksConfig.map((link) => {
          const { icon, title, key } = link
          return (
            <SharingLink
              key={key}
              icon={icon}
              tooltip={title}
              linkType={key}
              isActiveTab={activeTab === key}
              onIconClickHandler={onIconClickHandler}
            />
          )
        })}
      </Box>

      {activeTab === 'embed' && (
        <Box sx={{ margin: '20px 0' }}>
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap="6px">
            {embedTypes.map((button) => {
              const { key, title } = button

              return (
                <Button
                  key={key}
                  onClick={onEmbedButtonsHandler}
                  name={key}
                  variant={activeEmbedTab === key ? 'contained' : 'outlined'}
                  id={`share-embed-type-${key}-btn`}
                >
                  {title}
                </Button>
              )
            })}
          </Box>

          <Box display="flex" justifyContent="center" flexWrap="wrap" gap="6px" marginTop="16px">
            {imageSizes.map((imageSize) => {
              const { key, title } = imageSize
              const imageSizeButtonHandler = () => onImageSizeButtonsHandler(imageSize)

              return (
                <Button
                  key={key}
                  onClick={imageSizeButtonHandler}
                  name={key}
                  variant={selectedImageSize === key ? 'contained' : 'outlined'}
                  id={`share-embed-size-${key}-btn`}
                >
                  {title}
                </Button>
              )
            })}
          </Box>
        </Box>
      )}

      {(activeTab === 'direct' || activeTab === 'embed') && sharingLinks.length ? (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              id="outlined-multiline-static"
              label="Links"
              multiline
              fullWidth
              minRows={sharingLinks.length * 4}
              maxRows={12}
              variant="outlined"
              value={currLink}
            />
          </Box>

          <Box sx={{ paddingLeft: '12px' }}>
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={handleHostingLinkCopy}>
                <ContentCopyOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default Sharing

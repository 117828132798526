import { PropsWithChildren } from 'react'
import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import { AnalyticsProvider } from 'use-analytics'
import config from '../config'

export default function GoogleTagManagerProvider({ children }: PropsWithChildren<{}>) {
  const analytics = Analytics({
    app: config.app.name,
    version: config.app.version,
    plugins: [
      googleTagManager({
        containerId: config.analytics.gtm
      })
    ]
  })

  return <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>
}

import { makeVar, useReactiveVar } from '@apollo/client'

// Do not export this. This is being temporarily placed here until we can deprecate the other selectedAlbumVar
const selectedAlbumsVar = makeVar<Map<string, boolean>>(new Map())
const selectedModeEnabledAlbumsVar = makeVar<boolean>(false)

export default function useSelectedAlbums() {
  const selectedAlbums = useReactiveVar(selectedAlbumsVar)
  const selectedModeAlbumEnabled = useReactiveVar(selectedModeEnabledAlbumsVar)
  const selectedModeEnabled = selectedModeAlbumEnabled || selectedAlbums.size > 0

  const selectAlbum = (id: string) => {
    selectedAlbums.set(id, true)
    selectedAlbumsVar(new Map(selectedAlbums))
  }

  const unSelectAlbum = (id: string) => {
    selectedAlbums.delete(id)
    selectedAlbumsVar(new Map(selectedAlbums))
  }

  const toggleSelectionMode = () => {
    selectedModeEnabledAlbumsVar(!selectedModeAlbumEnabled)
  }

  const toggleSelectAlbum = (id: string) => {
    if (selectedAlbums.get(id)) {
      selectedAlbums.delete(id)
    } else {
      selectedAlbums.set(id, true)
    }

    selectedAlbumsVar(new Map(selectedAlbums))
  }

  const clearSelectedAlbums = () => {
    selectedAlbumsVar(new Map())
    selectedModeEnabledAlbumsVar(false)
  }

  return {
    clearSelectedAlbums,
    selectAlbum,
    selectedAlbums,
    selectedModeEnabled,
    toggleSelectAlbum,
    toggleSelectionMode,
    unSelectAlbum
  }
}

import { useLazyQuery } from '@apollo/client'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, LinearProgress, Tooltip, Typography, useTheme } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { debounce } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BucketAlbum, BucketAlbumsDocument } from '../../../../../graphql/generated'
import { drawerVar } from '../../../../../providers/apollo/cache'
import logger from '../../../../../utils/logger'
import useAlerts from '../../../hooks/useAlerts'
import useBucketDownload from '../../../hooks/useBucketDownload'
import useBucketPlan from '../../../hooks/useBucketPlan'
import AlbumPopover from './AlbumPopover'
import CreateAlbumForm from './CreateAlbumForm'
import DragAndDropToAlbum from './DragAndDropToAlbum'
import SecondaryActionsMenu from './SecondaryActionsMenu'

interface Props {
  bucketId: string
  album: BucketAlbum
}

export default function Album({ bucketId, album }: Props) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { restrictions, tooltipTitle } = useBucketPlan()
  const { downloadAlbums } = useBucketDownload()
  const { createAlert } = useAlerts()
  const [popOverEl, setpopOverEl] = useState<HTMLElement | null>(null)
  const [openSubAlbums, setOpenSubAlbums] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [subAlbumCreated, setSubAlbumCreated] = useState(false)
  const [execute, results] = useLazyQuery(BucketAlbumsDocument, {
    variables: { bucketId, albumId: album.id },
    onError: (e) => {
      e.graphQLErrors.forEach((err) => {
        switch (err.extensions?.code) {
          default:
            createAlert(err.message)
        }
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('We are having trouble retrieving your albums. Please try again later.')
      }
    }
  })
  const fetchMore = async (nextToken?: string | null) => {
    if (!nextToken) return
    results
      .fetchMore({
        variables: { bucketId, albumId: album.id, nextToken }
      })
      .then((res) => {
        if (res.data?.bucketAlbums?.nextToken) {
          const debouncedFetchMore = debounce(() => fetchMore(res.data?.bucketAlbums?.nextToken), 750)
          debouncedFetchMore()
        }
      })
  }

  const restricted = restrictions.modifyAlbums
  const hideTooltip = restricted === false
  const title = tooltipTitle('modifyAlbums')
  const albums = results.data?.bucketAlbums?.items || []

  const handleClick = () => {
    drawerVar(null)
    navigate(`/bucket/${bucketId}/album/${album.id}`)
  }

  const handleToggle = () => {
    if (!openSubAlbums && album.subAlbumCount) {
      execute().then((res) => {
        fetchMore(res.data?.bucketAlbums?.nextToken)
      })
    }

    setOpenSubAlbums(!openSubAlbums)
  }

  const handleOpenForm = () => {
    setOpenForm(true)
    setOpenSubAlbums(true)
    // This populates the cache with subalbums
    execute()
  }

  const handleCloseForm = () => {
    setOpenForm(false)
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setpopOverEl(event.currentTarget)
  }

  const handleClose = () => {
    setpopOverEl(null)
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <DragAndDropToAlbum albumId={album.id} />
        {!results.loading && (!!album.subAlbumCount || subAlbumCreated) && (
          <Tooltip
            PopperProps={{
              disablePortal: hideTooltip
            }}
            disableFocusListener={hideTooltip}
            disableHoverListener={hideTooltip}
            disableTouchListener={hideTooltip}
            title={title}
          >
            <IconButton
              size="small"
              disableRipple
              onClick={handleToggle}
              aria-label={!openSubAlbums ? 'Expand' : 'Collapse'}
              sx={{
                color: 'primary.main',
                height: '20px',
                width: '20px',
                '&:hover': { color: theme.palette.secondary.main, backgroundColor: theme.palette.primary.highlight }
              }}
            >
              {openSubAlbums ? <ArrowDropDown fontSize="small" /> : <ArrowRight fontSize="small" />}
            </IconButton>
          </Tooltip>
        )}
        <ListItemButton
          sx={{
            paddingLeft: '5px',
            paddingRight: 0,
            '&:hover': {
              background: 'transparent',
              color: theme.palette.primary.main
            }
          }}
          disableRipple
        >
          <ListItemText
            onClick={handleClick}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            aria-haspopup="true"
            data-testid={album.title}
            primary={album.title}
            sx={{
              paddingRight: '25px',
              span: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }
            }}
          />
          <SecondaryActionsMenu album={album} openCreateSubAlbumForm={handleOpenForm} downloadAlbums={downloadAlbums} />
        </ListItemButton>
        <AlbumPopover id={album.id} anchorEl={popOverEl} handleClose={handleClose} />
      </Box>
      <Dialog open={openForm} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1 }}>
            Create Album
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: 3, paddingTop: 0 }}>
          <CreateAlbumForm bucketId={album.bucketId} albumId={album.id} handleClose={handleCloseForm} setSubAlbumCreated={setSubAlbumCreated} />
        </DialogContent>
      </Dialog>
      <Collapse in={openSubAlbums} timeout="auto" unmountOnExit sx={{ background: theme.palette.primary.highlight }}>
        {results.loading && <LinearProgress />}
        {albums.map((subalbum) => (
          <Box key={subalbum.id} sx={{ paddingLeft: '15px' }}>
            <Album album={subalbum} bucketId={bucketId} />
          </Box>
        ))}
      </Collapse>
    </>
  )
}

import { Box, Typography } from '@mui/material'

export default function Maintenance() {
  return (
    <Box style={{ background: '#FF0000', padding: 12 }}>
      <Typography variant="h6" align="center" style={{ background: '#FF0000', color: 'white', fontWeight: 400 }}>
        Some functionality may be disabled as we complete some upgrades.
      </Typography>
    </Box>
  )
}

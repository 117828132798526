import { makeVar, useApolloClient, useReactiveVar } from '@apollo/client'
import { BucketMedia, BucketMediaByAlbumIdDocument } from '../../../graphql/generated'
import { MediaData } from '../utils/sharedTypes'
import useBucketGallerySettings from './useBucketGallerySettings'

export type Status = 'IN_PROGRESS' | 'COMPLETE' | 'ERROR' | number
export const uploadsInProgressVar = makeVar<Map<string, Status>>(new Map())

export default function useUploadsInProgress() {
  const client = useApolloClient()
  const uploadsInProgress = useReactiveVar(uploadsInProgressVar)
  const { settings } = useBucketGallerySettings()
  const { filterBy, sortBy } = settings

  const updateStatus = (id: string, status: Status) => {
    uploadsInProgress.set(id, status)
    uploadsInProgressVar(new Map(uploadsInProgress))
  }

  const clearUploadsInProgress = () => {
    uploadsInProgressVar(new Map())
  }

  const beginTrackingProgress = (bucketId: string, id: string, data: MediaData) => {
    uploadsInProgress.set(id, 'IN_PROGRESS')

    const media: BucketMedia = {
      bucketId,
      id,
      ...data,
      isFavorite: data.isFavorite ?? false,
      filename: data.filename ?? '',
      height: data.height ?? null,
      width: data.width ?? null,
      albumId: data.albumId ?? null,
      __typename: 'BucketMedia'
    }
    const { albumId } = media

    client.cache.updateQuery({ query: BucketMediaByAlbumIdDocument, variables: { bucketId, filterBy, sortBy } }, (current) => {
      if (!current?.bucketMediaByAlbumId) return null

      return {
        bucketMediaByAlbumId: {
          ...current.bucketMediaByAlbumId,
          items: [media, ...current.bucketMediaByAlbumId.items]
        }
      }
    })

    if (!albumId) {
      return
    }

    client.cache.updateQuery({ query: BucketMediaByAlbumIdDocument, variables: { bucketId, albumId, filterBy, sortBy } }, (current) => {
      if (!current?.bucketMediaByAlbumId) return null

      return {
        bucketMediaByAlbumId: {
          ...current.bucketMediaByAlbumId,
          items: [media, ...current.bucketMediaByAlbumId.items]
        }
      }
    })
  }

  return { beginTrackingProgress, clearUploadsInProgress, uploadsInProgress, updateStatus }
}

import { useApolloClient, useReactiveVar } from '@apollo/client'
import { useMemo, useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Dialog, IconButton, LinearProgress, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import {
  BucketFragmentFragmentDoc,
  BucketMedia,
  BucketMediaByAlbumIdDocument,
  BucketMediaFragmentFragmentDoc,
  BucketMediaStatus,
  BucketUserRole
} from '../../../../graphql/generated'
import { modalVar } from '../../../../providers/apollo/cache'
import useBucketGallerySettings from '../../hooks/useBucketGallerySettings'
import useBucketMediaMove from '../../hooks/useBucketMediaMove'
import useSelectedMedia from '../../hooks/useSelectedMedia'
import useBucket from '../../hooks/useBucket'

export default function MediaDelete() {
  const client = useApolloClient()
  const cacheRef = useRef(client.cache)
  const { settings } = useBucketGallerySettings()
  const { bucketUser } = useBucket()
  const { filterBy, sortBy } = settings
  const { bucketId } = useParams<{ bucketId: string }>()
  const modal = useReactiveVar(modalVar)
  const { selectedMedia, clearSelectedMedia } = useSelectedMedia()
  const mediaIds = Array.from(selectedMedia.keys())
  const { mutation, results } = useBucketMediaMove()
  const theme = useTheme()
  const noun = selectedMedia.size === 1 ? 'image' : 'images'

  const handleClose = () => {
    modalVar(null)
  }

  const mediaToDelete = useMemo(() => {
    if (modal !== 'bucketMediaDelete') {
      return []
    }

    const isContributorOrViewer = bucketUser?.userRole === BucketUserRole.Contributor || bucketUser?.userRole === BucketUserRole.Viewer
    const newMediaToDelete: BucketMedia[] = []

    mediaIds.forEach((id) => {
      const media = cacheRef.current.readFragment({
        id: `BucketMedia:${id}`,
        fragment: BucketMediaFragmentFragmentDoc
      })

      if (!media || (bucketUser?.id !== media?.userId && isContributorOrViewer)) {
        return
      }

      newMediaToDelete.push(media)
    })

    return newMediaToDelete
  }, [mediaIds, bucketUser?.id, bucketUser?.userRole, modal])

  const hasUndeletableMedia = mediaIds.length !== mediaToDelete.length
  const hasOnlyUndeletableMedia = mediaIds.length > 0 && mediaToDelete.length === 0

  const handleSubmit = () => {
    if (!bucketId || !mediaIds.length) return
    mutation({
      variables: { bucketId, mediaIds, data: { moveMediaToTrash: true } },
      update: (cache, { data }) => {
        if (!data?.bucketMediaMove) return

        mediaToDelete.forEach((media) => {
          // Modify album media count
          cache.modify({
            id: `BucketAlbum:${media?.albumId}`,
            fields: {
              counters: (cachedCounters) => ({
                ...cachedCounters,
                totalMedia: Math.max(cachedCounters.totalMedia - 1, 0),
                totalMediaMinusTrash: Math.max(cachedCounters.totalMediaMinusTrash - 1, 0)
              })
            }
          })

          // Modify bucket media count
          cache.updateFragment(
            {
              id: `Bucket:${bucketId}`,
              fragment: BucketFragmentFragmentDoc
            },
            (current) => {
              if (!current) return null

              const totalMediaMinusTrash = current.counters?.totalMediaMinusTrash ?? 0
              const totalMediaInTrash = current?.counters?.totalMediaInTrash ?? 0
              const fileSize = media?.fileSize ?? 0
              const totalSizeInTrash = current?.counters?.totalSizeInTrash ?? 0
              const totalSizeMinusTrash = current?.counters?.totalSizeMinusTrash ?? 0

              return {
                ...current,
                counters: {
                  ...current.counters,
                  totalMediaMinusTrash: Math.max(totalMediaMinusTrash - 1, 0),
                  totalMediaInTrash: Math.max(totalMediaInTrash + 1, 0),
                  totalSizeInTrash: Math.max(totalSizeInTrash + fileSize, 0),
                  totalSizeMinusTrash: Math.max(totalSizeMinusTrash - fileSize, 0)
                }
              }
            }
          )

          // Remove media from album
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                albumId: media?.albumId,
                filterBy,
                sortBy
              }
            },
            (cachedData) => {
              if (!media || !cachedData) return null

              const { bucketMediaByAlbumId } = cachedData
              const currentItems = bucketMediaByAlbumId?.items || []
              const items = currentItems.filter((item) => item.id !== media.id)

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items
                }
              }
            }
          )

          // Remove media from bucket
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                filterBy,
                sortBy
              }
            },
            (cachedData) => {
              if (!media || !cachedData) return null

              const { bucketMediaByAlbumId } = cachedData
              const currentItems = bucketMediaByAlbumId?.items || []
              const items = currentItems.filter((item) => item.id !== media.id)

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items
                }
              }
            }
          )

          // Remove album Id from media
          const updatedMedia = cache.updateFragment({ id: `BucketMedia:${media.id}`, fragment: BucketMediaFragmentFragmentDoc }, (current) => {
            if (!current) return null
            return {
              ...current,
              albumId: null
            }
          })

          // Add album to trash
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                filterBy: { status: BucketMediaStatus.Trash },
                sortBy: null
              }
            },
            (cachedData) => {
              if (!updatedMedia || !cachedData) return null

              const { bucketMediaByAlbumId } = cachedData
              const currentItems = bucketMediaByAlbumId?.items || []

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items: [updatedMedia, ...currentItems]
                }
              }
            }
          )
        })
        cache.gc()
      },
      onCompleted: () => {
        handleClose()
        clearSelectedMedia()
      }
    })
  }

  const imageLabel = mediaToDelete.length === 1 ? 'image' : 'images'

  return (
    <Dialog data-test="delete-modal" open={modal === 'bucketMediaDelete'} onClose={handleClose} maxWidth="sm" fullWidth disableScrollLock>
      <IconButton sx={{ position: 'absolute', cursor: 'pointer', right: 10, top: 10, zIndex: 10 }} onClick={handleClose} data-test="close-modal">
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '80px',
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            padding: '20px'
          }
        }}
      >
        <div style={{ background: 'rgba(6, 114, 203, 0.08)', borderRadius: '50%', width: '64px', height: '64px', textAlign: 'center' }}>
          <DeleteIcon color="primary" style={{ width: '30px', height: '100%' }} />
        </div>
        <Typography sx={{ fontWeight: 600, fontSize: 28, lineHeight: '32px', color: '#081230' }}>
          {hasOnlyUndeletableMedia ? 'You do not have permission to delete items uploaded by others.' : 'Are you sure you want to move to trash?'}
        </Typography>

        <Typography sx={{ color: '#2F364E', lineHeight: '24px', marginTop: '24px', marginBottom: '48px', maxWidth: '80%' }}>
          {!hasOnlyUndeletableMedia && (
            <>
              Deleting {mediaToDelete.length} {imageLabel}.
              {hasUndeletableMedia && <> You do not have permission to delete items uploaded by others.</>}
            </>
          )}
        </Typography>

        <DialogActions
          sx={{
            width: '100%',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            },
            '& button': {
              width: '50%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                margin: '4px 0'
              }
            }
          }}
        >
          {hasOnlyUndeletableMedia ? (
            <Button
              size="large"
              variant="contained"
              color="primary"
              sx={{ backgroundColor: theme.palette.info.main }}
              onClick={handleClose}
              data-test="confirm-modal"
            >
              Continue
            </Button>
          ) : (
            <Button
              size="large"
              variant="contained"
              color="primary"
              sx={{ backgroundColor: theme.palette.warning.main }}
              onClick={handleSubmit}
              data-testid="buckets-appbar-modal-delete-confirm"
            >
              Delete {noun}
            </Button>
          )}
        </DialogActions>
      </DialogContent>
      {results.loading && <LinearProgress />}
    </Dialog>
  )
}

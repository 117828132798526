import CircularProgress from '@mui/material/CircularProgress'
import LogoMark from '../svg/LogoMark'

export default function Loader() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: '20px', left: '20px' }}>
          <LogoMark width="40px" />
        </div>
        <CircularProgress size={80} thickness={2} />
      </div>
    </div>
  )
}

import { IconButton, styled } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { ReactElement } from 'react'

interface ShareLinkProps {
  icon: ReactElement
  tooltip: string
  linkType: string
  isActiveTab: boolean
  onIconClickHandler: (key: string) => void
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop: string) => prop !== 'isActiveTab' && prop !== 'isDisabled'
})<{ isActiveTab?: boolean; disabled?: boolean }>(({ isActiveTab, disabled, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `2px solid ${theme.palette.primary.light}`,
  color: 'white',
  width: 60,
  height: 60,
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: isActiveTab && !disabled ? `${theme.palette.primary.light} !important` : 'white',
  '& svg': {
    fill: isActiveTab && !disabled ? 'white' : theme.palette.primary.light
  },
  borderColor: disabled ? 'gray' : '',
  opacity: disabled ? 0.5 : ''
}))

export default function SharingLink({ icon, tooltip, isActiveTab, onIconClickHandler, linkType }: Readonly<ShareLinkProps>) {
  const iconClickHandler = () => {
    onIconClickHandler(linkType)
  }

  return (
    <Tooltip arrow title={tooltip}>
      <StyledIconButton onClick={iconClickHandler} isActiveTab={isActiveTab} id={`share-${linkType}-btn`} data-test="sharing-icon" size="large">
        {icon}
      </StyledIconButton>
    </Tooltip>
  )
}

import { useApolloClient, useLazyQuery, useReactiveVar } from '@apollo/client'
import { styled, Typography } from '@mui/material'
import { Auth, UserCredential, getAuth, signInWithCustomToken, signOut } from 'firebase/auth'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Loader from '../components/Loader'
import config, { superUglyHackToClearAllCookies } from '../config'
import { Image, MediaFragmentFragmentDoc, UserProfile, UserProfileDocument } from '../graphql/generated'
import logger from '../utils/logger'
import useSelectMedia from '../views/account/views/gallery/hooks/useSelectMedia'
import { adminUserIdVar, selectedMediaVar } from './apollo/cache'
import useBanImages from '../views/account/hooks/useBanImages'
import useUnbanImages from '../views/account/hooks/useUnBanImages'
import { AdminAuthState } from './types/AdminAuthState'

interface AdminDetails {
  adminUserToken: string | null
  userId: string
  currentBucketId: string | null
}

const LabelBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'fit-content',
  height: '45px',
  padding: '0 16px',
  position: 'fixed',
  left: 0,
  right: 0,
  margin: '0 auto',
  minWidth: '350px',
  backgroundColor: '#099dde',
  color: '#fff',
  zIndex: 9999
}))

const BanLabelBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'fit-content',
  height: '45px',
  padding: '0 105px',
  position: 'absolute',
  left: 0,
  right: 0,
  margin: '0 auto',
  minWidth: '350px',
  backgroundColor: '#099dde',
  color: '#fff',
  zIndex: 9999,
  bottom: '5px',
  fontWeight: 'bold',
  cursor: 'pointer',
  textTransform: 'uppercase'
}))

const ActiveBox = styled('div')(() => ({
  border: '5px solid #099dde',
  height: '100vh',
  width: '100%',
  position: 'fixed',
  top: 0,
  right: 0,
  zIndex: 9999,
  pointerEvents: 'none'
}))

const ExitBtn = styled('div')(() => ({
  cursor: 'pointer',
  textTransform: 'uppercase'
}))

export default function AdminProvider(props: PropsWithChildren<Record<string, unknown>>) {
  const navigate = useNavigate()
  const location = useLocation()
  const isAdmin = location.pathname.includes('view-by-admin')
  const admin = useReactiveVar(adminUserIdVar)
  const [state, setState] = useState(isAdmin ? AdminAuthState.NEEDS_SIGNIN : AdminAuthState.NOT_IN_USE)
  const client = useApolloClient()
  const { selectedMedia } = useSelectMedia()
  const [banEnabled, setBanEnabled] = useState<boolean>(false)
  const [unbanEnabled, setUnbanEnabled] = useState<boolean>(false)
  const currLocation = location.pathname
  const { mutation: banImagesMutation } = useBanImages()
  const { mutation: unbanImagesMutation } = useUnbanImages()
  const parseUrl = (url: string): AdminDetails => {
    const path = url.split('/')
    const adminUserToken = isAdmin ? path[4] : null
    const userId = isAdmin ? path[3] : ''
    const currentBucketId = isAdmin ? path[5] : null
    return { adminUserToken, userId, currentBucketId }
  }
  const { adminUserToken, userId, currentBucketId } = parseUrl(currLocation)
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null)
  const [userProfileQuery] = useLazyQuery(UserProfileDocument, {
    onCompleted: (res) => {
      setUserProfile(res.userProfile ?? null)
    },
    onError: (error) => {
      logger.error(error)
    }
  })
  const userProfileQueryRef = useRef(userProfileQuery)
  const navigateRef = useRef(navigate)

  const handleLogout = useRef(async () => {
    const cookies = new Cookies()
    try {
      const auth = getAuth()
      await signOut(auth)
    } catch (error) {
      logger.error(error)
    }

    cookies.remove('app_auth', config.cookieOptions)
    superUglyHackToClearAllCookies.forEach((cookieConfig) => cookies.remove('app_auth', cookieConfig))
  })

  const closeWindow = () => {
    handleLogout.current()
    window.close()
  }

  useEffect(() => {
    if (state !== AdminAuthState.SIGNED_IN) {
      return
    }
    if (admin) {
      const getStatus = (id: string) => {
        const media = client.readFragment<Image>({ id: `Image:${id}`, fragment: MediaFragmentFragmentDoc })
        if (!media?.status) return
        if (media?.status.length > 0) {
          setUnbanEnabled(true)
        } else {
          setBanEnabled(true)
        }
      }
      setUnbanEnabled(false)
      setBanEnabled(false)
      selectedMedia.forEach((imageId) => getStatus(imageId))
    }
  }, [state, selectedMedia, admin, client])

  useEffect(() => {
    const cookies = new Cookies()

    // no need to run the effect if not attempting to assume user
    if (!adminUserToken) {
      return
    }

    // we don't want to run this effect if admin provider is not in use
    // or it is currently loading a user
    if (state !== AdminAuthState.NEEDS_SIGNIN) {
      return
    }

    const handleError = (err: unknown) => {
      logger.error('Sign in failed')
      logger.error(err)
      setState(AdminAuthState.SIGNIN_FAILED)
    }

    const signIn = async () => {
      try {
        const auth: Auth = getAuth()
        const credential: UserCredential = await signInWithCustomToken(auth, adminUserToken)
        const token: string = await credential.user.getIdToken()

        cookies.set('app_auth', token, config.cookieOptions)
        localStorage.setItem('_pb_userData:username', userId)
        adminUserIdVar(credential.user.uid)
        setState(AdminAuthState.SIGNED_IN)
      } catch (error) {
        handleError(error)
      }
    }

    // logout previous firebase user, then assume user
    if (getAuth().currentUser) {
      handleLogout.current().then(signIn).catch(handleError)
      return
    }

    // no previous firebase user, simply assume user
    signIn()
      .then(() => {
        logger.info('assume user success')
      })
      .catch((err) => {
        handleError(err)
      })

    setState(AdminAuthState.ATTEMPTING_SIGNIN)
  }, [adminUserToken, state, userId])

  useEffect(() => {
    if (!userProfile && state === AdminAuthState.SIGNED_IN) {
      userProfileQueryRef.current()
    }

    if (userProfile && adminUserToken && userId && admin && state === AdminAuthState.SIGNED_IN) {
      if (
        userProfile.currentBucketId &&
        (userProfile.currentBucketId === userProfile.id || userProfile.currentBucketId === userProfile.legacyBucketId)
      ) {
        navigateRef.current(`/u/${userId}`, { replace: true })
      } else if (userProfile.currentBucketId) {
        navigateRef.current(`/bucket/${userProfile.currentBucketId}`, { replace: true })
      } else {
        navigateRef.current(`/buckets`, { replace: true })
      }
    }
  }, [adminUserToken, userId, currentBucketId, admin, state, userProfile])

  const banImages = () => {
    banImagesMutation({ variables: { imageIds: selectedMedia } })
    selectedMediaVar([])
  }

  const unBanImages = () => {
    unbanImagesMutation({ variables: { imageIds: selectedMedia } })
    selectedMediaVar([])
  }

  if (isAdmin && (state === AdminAuthState.ATTEMPTING_SIGNIN || state === AdminAuthState.NEEDS_SIGNIN)) {
    return (
      <>
        <Typography variant="h2">Signing in as {userId}</Typography>
        <Loader />
      </>
    )
  }

  if (isAdmin && state === AdminAuthState.SIGNIN_FAILED) {
    return <Typography variant="h2">Assume user failed</Typography>
  }

  if (admin && state === AdminAuthState.SIGNED_IN) {
    return (
      <>
        <ActiveBox> </ActiveBox>
        <LabelBox>
          <div>You see as {admin}</div>
          <ExitBtn onClick={closeWindow}>Exit</ExitBtn>
        </LabelBox>
        {selectedMedia.length > 0 && !unbanEnabled && banEnabled && (
          <BanLabelBox onClick={banImages}>
            <div>Ban Images</div>
          </BanLabelBox>
        )}
        {selectedMedia.length > 0 && !banEnabled && unbanEnabled && (
          <BanLabelBox onClick={unBanImages}>
            <div>Unban Images</div>
          </BanLabelBox>
        )}
        <>{props.children}</>
      </>
    )
  }

  // state === AdminAuthState.NOT_IN_USE || !admin
  return <>{props.children}</>
}

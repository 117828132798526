import { AddCircle } from '@mui/icons-material'
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import CreateAlbumForm from './CreateAlbumForm'
import useBucket from '../../../hooks/useBucket'

interface Props {
  setAlbumId: (albumId: string) => void
}

export const AlbumLink = styled(Button)(({ theme }) => ({
  display: 'block',
  width: '100%',
  fontSize: '1rem',
  '&:hover': { color: theme.palette.primary.main },
  textAlign: 'left',
  color: 'inherit'
}))

export default function AlbumSubHeader({ setAlbumId }: Props) {
  const { bucketUser } = useBucket()
  const [open, setOpen] = useState(false)
  const { bucketId } = useParams<{ bucketId: string }>()

  const disabled = !bucketUser?.permissions?.albumCreate
  const hideTooltip = disabled === false

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!bucketId) {
    return null
  }

  return (
    <>
      <ListItem disablePadding>
        <ListItemText primary="Albums" />
        <ListItemSecondaryAction sx={{ right: 0 }}>
          <Tooltip
            PopperProps={{
              disablePortal: hideTooltip
            }}
            disableFocusListener={hideTooltip}
            disableHoverListener={hideTooltip}
            disableTouchListener={hideTooltip}
            title="Your viewer role for this bucket does not allow you to create albums."
          >
            <div>
              <Collapse in={!open}>
                <IconButton onClick={handleOpen} disabled={disabled} size="small" color="primary">
                  <AddCircle fontSize="small" />
                </IconButton>
              </Collapse>
            </div>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1 }}>
            Create Album
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: 3, paddingTop: 0 }}>
          <CreateAlbumForm bucketId={bucketId} handleClose={handleClose} setAlbumId={setAlbumId} />
        </DialogContent>
      </Dialog>
    </>
  )
}

import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function NotFound() {
  const handleClick = () => {
    window.location.href = '/buckets'
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography sx={{ mt: 2 }} variant="h2">
        404
      </Typography>
      <Typography variant="h4">Not found</Typography>
      <Typography sx={{ mt: 1 }}>The resource you are trying to access may be deleted or not exist.</Typography>
      <Button onClick={handleClick} variant="contained" sx={{ mt: 4 }}>
        View all buckets
      </Button>
      <Typography sx={{ mt: 2 }}>
        <Link to="https://support.photobucket.com/hc/en-us">Contact support</Link>
      </Typography>
    </div>
  )
}

import { Box, Button, Stack, useTheme } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import LinearProgress from '@mui/material/LinearProgress'
import { ChangeEvent, FormEvent, useState } from 'react'
import useBucketAlbumCreate from '../../../hooks/useBucketAlbumCreate'

interface Props {
  bucketId: string
  albumId?: string | null
  handleClose: () => void
  setAlbumId: (albumId: string) => void
}

export default function CreateAlbumForm({ bucketId, albumId, handleClose, setAlbumId }: Props) {
  const [title, setTitle] = useState<string | null>()
  const [error, setError] = useState<string | null>()
  const { mutation, results } = useBucketAlbumCreate()
  const theme = useTheme()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
    setError(null)
  }

  // Create SubAlbum Handler
  const handleSubmit = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    if (!bucketId || !title) {
      return
    }

    mutation({
      variables: {
        bucketId,
        data: { title, parentId: albumId || bucketId }
      },
      onCompleted: ({ bucketAlbumCreate }) => {
        if (!bucketAlbumCreate) return
        setAlbumId(bucketAlbumCreate.id)
        setTitle(null)
        setError(null)
        handleClose()
      }
    })
  }

  const handleCancel = () => {
    setTitle(null)
    setError(null)
    handleClose()
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <InputBase
        autoFocus
        value={title || ''}
        error={!!error}
        margin="dense"
        onChange={handleChange}
        fullWidth
        name="title"
        placeholder="Album Title"
        required
        size="small"
        sx={{ border: `1px solid ${theme.palette.secondary.main}`, borderRadius: '4px', padding: '8px 10px 4px' }}
      />
      {results.loading && <LinearProgress color="primary" />}
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Button onClick={handleCancel} size="medium" variant="outlined" color="error">
          Cancel
        </Button>
        <Button type="submit" size="medium" variant="outlined">
          Save
        </Button>
      </Stack>
    </Box>
  )
}

CreateAlbumForm.defaultProps = {
  albumId: null
}

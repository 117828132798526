import { Box, Hidden } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMatch } from 'react-router-dom'
import useSelectedAlbums from '../../hooks/useSelectedAlbums'
import useSelectedMedia from '../../hooks/useSelectedMedia'
import DeselectButton from './DeselectButton'
import IconDropDownMenu from './IconDropDownMenu'
import IconsMenu from './IconsMenu'
import LogoMenu from './LogoMenu'
import SearchBar from './SearchBar'
import SelectButton from './SelectButton'
import SettingsMenu from './SettingsMenu'
import UploadButton from './UploadButton'

interface Props {
  searchBar?: boolean
  currentBucketMenu?: boolean
  displayDrawerToggle?: boolean
  iconMenu?: boolean
  selectButton?: boolean
  uploadButton?: boolean
  settingsMenu?: boolean
}

export default function Appbar({ currentBucketMenu, searchBar, displayDrawerToggle, uploadButton, iconMenu, selectButton, settingsMenu }: Props) {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const { selectedModeEnabled: selectedModeEnabledAlbums } = useSelectedAlbums()
  const { selectedModeEnabled: selectedModeEnabledMedia } = useSelectedMedia()
  const selectedModeEnabled = selectedModeEnabledAlbums || selectedModeEnabledMedia
  const path = useMatch('/bucket/:bucketId/:slug')
  const isTrash = path?.params.slug === 'trash'

  return (
    <MuiAppBar
      position="static"
      elevation={0}
      color="inherit"
      sx={{ borderBottom: '1px solid #dfdfdf', color: theme.palette.primary.main, alignItems: 'center' }}
    >
      <Toolbar
        disableGutters={isTablet}
        sx={{
          padding: { xs: '10px' },
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: !iconMenu ? theme.breakpoints.values.xl : '100%'
        }}
      >
        <Box sx={{ display: 'flex', flex: 1 }}>
          {!selectedModeEnabled && <LogoMenu displayDrawerToggle={displayDrawerToggle} />}
          {selectedModeEnabled && <DeselectButton />}
          <Hidden lgDown>{searchBar && <SearchBar />}</Hidden>
        </Box>
        {uploadButton && !selectedModeEnabled && !isTrash && <UploadButton />}
        {selectButton && !selectedModeEnabled && <SelectButton />}
        {iconMenu && !isTrash && <IconsMenu />}
        {iconMenu && !isTrash && <IconDropDownMenu />}
        {settingsMenu && <SettingsMenu currentBucketMenu={currentBucketMenu} />}
      </Toolbar>
    </MuiAppBar>
  )
}

Appbar.defaultProps = {
  searchBar: true,
  currentBucketMenu: true,
  displayDrawerToggle: true,
  iconMenu: true,
  selectButton: true,
  uploadButton: true,
  settingsMenu: true
}

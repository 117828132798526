import { PlayCircleOutline } from '@mui/icons-material'
import { IconButton, Tooltip, MenuItem } from '@mui/material'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'
import useBucketMedia from '../../../hooks/useBucketMedia'

interface Props {
  isWithinOverflowMenu?: boolean
}

// Play Slideshow icon button as seen on the following pages:
// All Media (desktop)
// Image Details (desktop/mobile)
// Albums (desktop)
// Individual Album (desktop)
export default function PlaySlideshow({ isWithinOverflowMenu }: Readonly<Props>) {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const navigate = useNavigate()
  const { selectedAlbums } = useSelectedAlbums()
  const { media } = useBucketMedia()
  const match = useMatch('/bucket/:bucketId/:slug')
  const disabled = (match?.params.slug === 'albums' && selectedAlbums.size !== 1) || (match?.params.slug !== 'albums' && !media.length)

  const onClick = () => {
    let path = albumId ? `/bucket/${bucketId}/album/${albumId}` : `/bucket/${bucketId}`
    path += `/slideshow`

    navigate(path)
  }

  return (
    <>
      {isWithinOverflowMenu ? (
        <MenuItem
          color="inherit"
          onClick={onClick}
          disabled={disabled}
          aria-label="Play Slideshow"
          id="play-slideshow-menu-item"
          data-test="play-slideshow-menu-item"
        >
          <PlayCircleOutline style={{ marginRight: 8 }} /> Play Slideshow
        </MenuItem>
      ) : (
        <IconButton color="primary" aria-label="Play Slideshow" onClick={onClick} disabled={disabled} id="play-slideshow" data-test="play-slideshow">
          <Tooltip title="Play Slideshow" arrow>
            <PlayCircleOutline />
          </Tooltip>
        </IconButton>
      )}
    </>
  )
}

PlaySlideshow.defaultProps = {
  isWithinOverflowMenu: false
}

import { useMutation } from '@apollo/client'
import { BucketShareCreateDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

export default function useBucketShareCreate() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(BucketShareCreateDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was a problem creating your share link. Please try again later.')
      }
    }
  })

  return { mutation, results }
}

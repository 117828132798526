import { InputLabel, styled } from '@mui/material'

export default styled(InputLabel)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: 'left',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '20px',
  marginTop: 0
}))

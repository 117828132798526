import { AccountCircleOutlined, CreditCard, EmailOutlined, ImageOutlined, LockOutlined, SwitchAccount } from '@mui/icons-material'
import { Box, Tab, Tabs, styled } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SettingsLayout } from '../Layout'
import LinkedAccounts from './LinkedAccounts'
import Media from './Media'
import Password from './Password'
import Profile from './Profile'
import Email from './email'
import Membership from './membership/index'
import logger from '../../../../utils/logger'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}

const StyledTab = styled(Tab)({ textTransform: 'none' })

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null
}

const MEMBERSHIP = 'membership'
const PROFILE = 'profile'
const PASSWORD = 'password'
const LINKED_ACCOUNTS = 'linked-accounts'
const EMAIL = 'email'
const MEDIA = 'media'

export default function Settings() {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const [tab, setTab] = useState(location.hash.replace('#', '') || PROFILE) // use || not ?? because ?? is falsey for empty string
  const hasIdp = Boolean(getAuth().currentUser?.providerData.find((provider) => ['google.com', 'apple.com'].includes(provider.providerId)))
  const isOnlyIdpUser = hasIdp && getAuth().currentUser?.providerData.length === 1
  logger.info({ hasIdp, isOnlyIdpUser, idps: getAuth().currentUser?.providerData })

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const setHash = (hash: string) => {
    return () => {
      const redirect = location.hash.replace(tab, hash)
      navigate(redirect)
    }
  }

  return (
    <SettingsLayout>
      <Box sx={{ margin: '80px auto 0', width: '100%', maxWidth: theme.breakpoints.values.xl }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
            allowScrollButtonsMobile
            scrollButtons={false}
            variant="scrollable"
          >
            <StyledTab icon={<CreditCard />} iconPosition="start" label="Membership" value={MEMBERSHIP} onClick={setHash(MEMBERSHIP)} />
            <StyledTab icon={<ImageOutlined />} iconPosition="start" label="Media" value={MEDIA} onClick={setHash(MEDIA)} />
            <StyledTab icon={<AccountCircleOutlined />} iconPosition="start" label="Profile" value={PROFILE} onClick={setHash(PROFILE)} />
            {!isOnlyIdpUser && <StyledTab icon={<EmailOutlined />} iconPosition="start" label="Email" value={EMAIL} onClick={setHash(EMAIL)} />}
            <StyledTab icon={<LockOutlined />} iconPosition="start" label="Password" value={PASSWORD} onClick={setHash(PASSWORD)} />
            <StyledTab
              icon={<SwitchAccount />}
              iconPosition="start"
              label="Linked Accounts"
              value={LINKED_ACCOUNTS}
              onClick={setHash(LINKED_ACCOUNTS)}
            />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={MEMBERSHIP}>
          <Membership />
        </TabPanel>
        <TabPanel value={tab} index={MEDIA}>
          <Media />
        </TabPanel>
        <TabPanel value={tab} index={PROFILE}>
          <Profile />
        </TabPanel>
        <TabPanel value={tab} index={EMAIL}>
          <Email />
        </TabPanel>
        <TabPanel value={tab} index={PASSWORD}>
          <Password />
        </TabPanel>
        <TabPanel value={tab} index={LINKED_ACCOUNTS}>
          <LinkedAccounts />
        </TabPanel>
      </Box>
    </SettingsLayout>
  )
}

import { useMutation } from '@apollo/client'
import { logger } from 'workbox-core/_private'
import { useParams } from 'react-router-dom'
import { BucketBulkMediaUpdateDocument, BucketMediaByAlbumIdDocument, BucketMediaByAlbumIdQueryVariables } from '../../../graphql/generated'
import useAlerts from './useAlerts'
import useSelectedMedia from './useSelectedMedia'
import useBucketGallerySettings from './useBucketGallerySettings'

export default function useBucketBulkMediaUpdate() {
  const { createAlert } = useAlerts()
  const { clearSelectedMedia } = useSelectedMedia()
  const { bucketId } = useParams<{ bucketId: string }>()
  const { settings } = useBucketGallerySettings()
  const [mutation, results] = useMutation(BucketBulkMediaUpdateDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was an error updating your media. Please try again later.')
      }
    },
    onCompleted: () => {
      createAlert('Media updated successfully!', 'success')
      clearSelectedMedia()
    },
    update: (cache, { data }) => {
      if (!data?.bucketBulkMediaUpdate || !bucketId) return
      const { bucketBulkMediaUpdate } = data
      const { filterBy, sortBy } = settings
      const variables: BucketMediaByAlbumIdQueryVariables = {
        bucketId,
        filterBy,
        sortBy
      }

      bucketBulkMediaUpdate.forEach((id) => {
        cache.modify({
          id: `BucketMedia:${id}`,
          fields: {
            isFavorite: (cachedValue) => !cachedValue
          }
        })
      })

      cache.updateQuery(
        {
          query: BucketMediaByAlbumIdDocument,
          variables
        },
        (cachedData) => {
          if (!cachedData) return null
          const { bucketMediaByAlbumId } = cachedData
          const currentItems = bucketMediaByAlbumId?.items ?? []
          let items = currentItems
          // If user is in favorites filter, remove the item from the list
          if (filterBy?.isFavorite) {
            items = currentItems.filter((item) => !bucketBulkMediaUpdate.includes(item.id))
          }
          return {
            bucketMediaByAlbumId: {
              ...bucketMediaByAlbumId,
              items
            }
          }
        }
      )
    }
  })

  const { loading } = results

  return { mutation, results, loading }
}

interface Props {
  width: number
  height: number
}

export default function GoogleLogo({ width, height }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2667 16.2273C26.2667 15.5182 26.2031 14.8364 26.0849 14.1819H16.6667V18.0501H22.0485C21.8167 19.3001 21.1121 20.3592 20.0531 21.0682V23.5773H23.2849C25.1758 21.8364 26.2667 19.2728 26.2667 16.2273Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 26C19.3667 26 21.6303 25.1046 23.2849 23.5773L20.053 21.0682C19.1576 21.6682 18.0121 22.0228 16.6667 22.0228C14.0621 22.0228 11.8576 20.2637 11.0712 17.9H7.73029V20.4909C9.37574 23.7591 12.7576 26 16.6667 26Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0712 17.9001C10.8712 17.3001 10.7575 16.6592 10.7575 16.0001C10.7575 15.341 10.8712 14.7001 11.0712 14.1001V11.5092H7.73026C7.05299 12.8592 6.66663 14.3864 6.66663 16.0001C6.66663 17.6137 7.05299 19.141 7.73026 20.491L11.0712 17.9001Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 9.97732C18.1348 9.97732 19.453 10.4819 20.4894 11.4728L23.3576 8.6046C21.6258 6.99096 19.3621 6.00005 16.6667 6.00005C12.7576 6.00005 9.37574 8.24096 7.73029 11.5091L11.0712 14.1001C11.8576 11.7364 14.0621 9.97732 16.6667 9.97732Z"
        fill="#EA4335"
      />
    </svg>
  )
}

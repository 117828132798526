import { ApolloError, useMutation } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { EmailConfirmationAcceptDocument, EmailConfirmationResendDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useLoginFirebase from '../../auth/hooks/useLogin'
import { State } from '../components/blockers/types'
import useAlerts from './useAlerts'

const convertToUserFriendlyMessage = (message: string) => {
  const m = message.toLowerCase()

  if (m === 'record does not exist or has been deleted') {
    return "This email confirmation link is invalid or doesn't exist. Please request a new one."
  }

  return message
}

const mapErrorMessages = (error: ApolloError) => {
  const messages = new Set<string>()

  error.graphQLErrors.forEach((e) => {
    if (e.message) {
      messages.add(convertToUserFriendlyMessage(e.message))
    }
  })

  if (error.networkError) messages.add('There was a network error. Please reload the page to try again.')

  if (error.clientErrors) {
    error.clientErrors.forEach((e) => {
      messages.add(convertToUserFriendlyMessage(e.message))
    })
  }

  if (error.protocolErrors) {
    error.protocolErrors.forEach((e) => {
      messages.add(convertToUserFriendlyMessage(e.message))
    })
  }

  if (error.message) {
    messages.add(convertToUserFriendlyMessage(error.message))
  }

  return Array.from(messages)
}

export default function useEmailVerification() {
  const { createAlert } = useAlerts()
  const { attemptRedirect } = useLoginFirebase()
  const [resendEmailConfirmation, resendResults] = useMutation(EmailConfirmationResendDocument, {
    onCompleted: () => {
      createAlert('Email sent.', 'success')
    },
    onError: (resendError) => {
      createAlert('There was an error sending you an email.')
      logger.error({ resendError })
    }
  })
  const [acceptEmailConfirmation, acceptResults] = useMutation(EmailConfirmationAcceptDocument, {
    onCompleted: () => {
      createAlert('Email confirmed.', 'success')
      if (getAuth().currentUser) {
        attemptRedirect()
      }
    },
    onError: (confirmationError) => {
      logger.info({ confirmationError })
      createAlert('There was an issue confirming your email. Please send another email and try again.')
    }
  })

  function confirmEmail(id: string) {
    if (acceptResults.loading || acceptResults.called) {
      return
    }

    acceptEmailConfirmation({ variables: { id } })
  }

  const resetSendState = () => {
    resendResults.reset()
  }

  let errorMessages: Array<string> = []

  let resendState = State.DEFAULT
  if (resendResults.error) {
    resendState = State.ERROR
    errorMessages = mapErrorMessages(resendResults.error)
  } else if (resendResults.data) {
    resendState = State.SUCCESS
  } else if (resendResults.loading) {
    resendState = State.LOADING
  }

  let confirmState = State.DEFAULT
  if (acceptResults.error) {
    confirmState = State.ERROR
    errorMessages = mapErrorMessages(acceptResults.error)
  } else if (acceptResults.data) {
    confirmState = State.SUCCESS
  } else if (acceptResults.loading) {
    confirmState = State.LOADING
  }
  return { confirmEmail, confirmState, errorMessages, resendEmailConfirmation, resendState, resetSendState }
}

import { OAuthProvider } from 'firebase/auth'

class AppleAuthProvider extends OAuthProvider {
  static PROVIDER_ID: 'apple.com' = 'apple.com'

  constructor() {
    super(AppleAuthProvider.PROVIDER_ID)
  }
}

export default AppleAuthProvider

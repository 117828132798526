import { Button, Grid, LinearProgress, TextField, Typography } from '@mui/material'
import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'
import { isEmpty } from 'lodash'
import { ChangeEvent, useState } from 'react'
import logger from '../../../../utils/logger'
import useAlerts from '../../hooks/useAlerts'
import PasswordValidator from '../../../../utils/passwordValidator'
import ConfirmPasswordException from '../../../../errors/ConfirmPassword'
import RequiredParameterException from '../../../../errors/RequiredParameter'

const FORM = 1
const LOADING = 2
const SUCCESS = 3

interface Fields {
  currentPassword?: string
  password?: string
  confirmPassword?: string
}
type Field = keyof Fields

type Errors = Fields

export default function Email() {
  const { createAlert } = useAlerts()
  const [state, setState] = useState(FORM)
  const [fields, setFields] = useState<Fields>({})
  const [errors, setErrors] = useState<Errors>({})

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFields = { ...fields }
    const field = event.target.name as Field
    updatedFields[field] = event.target.value
    setFields(updatedFields)

    const updatedErrors = { ...errors }
    delete updatedErrors[field]
    // delete updatedErrors.general
    setErrors(updatedErrors)
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const user = getAuth().currentUser

    if (state !== FORM) {
      return
    }

    setState(LOADING)

    if (!fields.currentPassword) {
      setErrors({ ...errors, currentPassword: 'Current password is required.' })
      setState(FORM)
      return
    }

    if (!user?.email) {
      createAlert('There was an issue with your session, please logout and try again.')
      setState(FORM)
      return
    }

    if (fields.password === fields.currentPassword) {
      setErrors({ ...errors, password: 'Your new password cannot be the same as your current password.' })
      setState(FORM)
      return
    }

    try {
      const validatedPassword = PasswordValidator(fields.password, fields.confirmPassword)

      const credential = EmailAuthProvider.credential(user.email, fields.currentPassword)
      reauthenticateWithCredential(user, credential)
        .then(() => {
          return updatePassword(user, validatedPassword)
        })
        .then(() => {
          createAlert('Your password has been updated!', 'success')
          setState(SUCCESS)
        })
        .catch((err) => {
          if (err.code === 'auth/wrong-password') {
            setErrors({ ...errors, currentPassword: 'Incorrect current password' })
          } else {
            createAlert('There was an error updating your password.')
          }
          setState(FORM)
          logger.error(err)
        })
    } catch (error) {
      if (error instanceof ConfirmPasswordException) {
        setErrors({ ...errors, confirmPassword: error.message })
      } else if (error instanceof RequiredParameterException && error.parameterName) {
        setErrors({ ...errors, [error.parameterName]: error.message })
      } else {
        createAlert('There was an error validating your password.')
      }
      setState(FORM)
    }
  }

  if (state === SUCCESS) {
    return (
      <div>
        <Typography variant="h6">Success!</Typography>
        <Typography>Your password has been updated!</Typography>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6">Update Password</Typography>

      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            disabled={state === LOADING}
            type="password"
            fullWidth
            error={!isEmpty(errors.currentPassword)}
            id="password-current-password"
            inputProps={{ autoCapitalize: 'off' }}
            name="currentPassword"
            label="Current Password"
            helperText={errors.currentPassword}
            value={fields.currentPassword}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            disabled={state === LOADING}
            type="password"
            fullWidth
            error={!isEmpty(errors.password)}
            id="password-new-password"
            inputProps={{ autoCapitalize: 'off' }}
            name="password"
            label="New Password"
            helperText={errors.password}
            value={fields.password}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            disabled={state === LOADING}
            type="password"
            fullWidth
            error={!isEmpty(errors.confirmPassword)}
            id="password-confirm-password"
            inputProps={{ autoCapitalize: 'off' }}
            name="confirmPassword"
            label="Confirm Password"
            helperText={errors.confirmPassword}
            value={fields.confirmPassword}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 1 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          {state === LOADING && <LinearProgress />}
          <Button disabled={state === LOADING} fullWidth id="password-submit" type="submit" variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

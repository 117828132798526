import { AppBar, Container, styled, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import config from '../config'
import Logo from '../svg/Logo'

const Wrapper = styled('div')(() => ({
  width: '100vw',
  height: 'calc(100vh - 64px)',
  marginTop: '64px',
  position: 'relative',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

const StyledLink = styled('a')(() => ({
  color: '#0672CB',
  textDecoration: 'none'
}))

function ErrorFallback() {
  return (
    <>
      <AppBar />
      <Wrapper>
        <Container maxWidth="lg">
          <Logo variant="stacked" width="200px" />
          <Typography variant="h4" sx={{ marginTop: '20px' }} gutterBottom>
            Something went wrong.
          </Typography>
          <Typography>
            {'Please contact '}
            <StyledLink href={config.appUrl.met} rel="noreferrer" target="_blank">
              support
            </StyledLink>
          </Typography>
        </Container>
      </Wrapper>
    </>
  )
}

export default function ErrorBoundaryProvider({ children }: PropsWithChildren<{}>) {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
}

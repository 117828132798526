import { chunk } from 'lodash'
import useS3MulitpartUpload from './useS3MulitpartUpload'

export default function useBucketMediaUpload() {
  const { batchUpload, uploadSingle } = useS3MulitpartUpload()

  const uploadChunk = async (chunks: File[][], num: number, total: number) => {
    const batch = chunks.pop()

    if (!batch) {
      // There are no more batches to upload
      return
    }

    await batchUpload(batch, num, total)
    await uploadChunk(chunks, num + 1, total)
  }

  const uploadMultiple = (files: FileList) => {
    const chunks = chunk(files, 5)
    uploadChunk(chunks, 1, chunks.length)
  }

  return { uploadMultiple, uploadSingle }
}

import { useState } from 'react'
import EnterEmail from './EnterEmail'
import Reauthenticate from './Reauthenticate'
import Success from './Success'

const ENTER_EMAIL = 1
const REAUTHENTICATE = 2
const SUCCESS = 3

export default function Email() {
  const [state, setState] = useState(ENTER_EMAIL)
  const [newEmail, setNewEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const goToScreen = (newState: 'REAUTHENTICATE' | 'SUCCESS') => {
    if (newState === 'REAUTHENTICATE') {
      setState(REAUTHENTICATE)
    }

    if (newState === 'SUCCESS') {
      setState(SUCCESS)
    }
  }

  if (state === SUCCESS) {
    return <Success newEmail={newEmail} />
  }

  if (state === REAUTHENTICATE) {
    return <Reauthenticate goToScreen={goToScreen} newEmail={newEmail} password={password} setPassword={setPassword} />
  }

  if (state === ENTER_EMAIL) {
    return <EnterEmail newEmail={newEmail} setNewEmail={setNewEmail} goToScreen={goToScreen} />
  }

  return <EnterEmail newEmail={newEmail} setNewEmail={setNewEmail} goToScreen={goToScreen} />
}

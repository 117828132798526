import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function Banned() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography sx={{ mt: 2 }} variant="h4">
        This Bucket has been disabled.
      </Typography>
      <Typography>
        <Link to="https://support.photobucket.com/hc/en-us">Contact support</Link>
      </Typography>
    </div>
  )
}

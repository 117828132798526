import { DeleteOutline } from '@mui/icons-material'
import { useMemo } from 'react'
import CameraIcon from '@mui/icons-material/Camera'
import AllItemsIcon from '@mui/icons-material/PhotoLibraryOutlined'
import ScreenShareIcon from '@mui/icons-material/ScreenShareOutlined'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { drawerVar } from '../../../../../providers/apollo/cache'
import useBucketPlan from '../../../hooks/useBucketPlan'
import useBucket from '../../../hooks/useBucket'
import { BucketUserRole } from '../../../../../graphql/generated'
import useBucketShares from '../../../hooks/useBucketShares'

// Styles for descendants of the listItem
export const listItemSx = {
  '& .MuiListItemIcon-root': { minWidth: '36px' },
  '& .MuiListItemButton-root': { borderRadius: '4px', padding: '8px 12px' }
}

const ROUTES = {
  bucket: (id: string | undefined) => `/bucket/${id}`,
  activity: (id: string | undefined) => `/bucket/${id}/activity`,
  trash: (id: string | undefined) => `/bucket/${id}/trash`,
  share: (id: string | undefined) => `/bucket/${id}/share`
}

type RouteKey = keyof typeof ROUTES

export default function StaticAlbums() {
  const { items } = useBucketShares()
  const { restrictions, tooltipTitle } = useBucketPlan()
  const { bucket, bucketUser } = useBucket()
  const bucketId = bucket?.id
  const navigate = useNavigate()
  const location = useLocation()
  const shareMediaRestricted = restrictions.sharing && !items.length
  const hideTooltip = shareMediaRestricted === false

  const isCurrentRoute = useMemo(() => {
    return (route: string) => location.pathname === route
  }, [location])

  const handleNavigation = (path: RouteKey) => () => {
    const route = ROUTES[path](bucketId)
    if (route) {
      navigate(route)
      drawerVar(null)
    }
  }
  return (
    <List>
      <ListItem disablePadding sx={listItemSx}>
        <ListItemButton onClick={handleNavigation('activity')} selected={isCurrentRoute(ROUTES.activity(bucketId))} data-test="activity-feed">
          <ListItemIcon>
            <CameraIcon />
          </ListItemIcon>
          <ListItemText primary="Activity" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={listItemSx}>
        <ListItemButton onClick={handleNavigation('bucket')} selected={isCurrentRoute(ROUTES.bucket(bucketId))}>
          <ListItemIcon>
            <AllItemsIcon />
          </ListItemIcon>
          <ListItemText primary="All Media" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={listItemSx}>
        <Tooltip
          arrow
          title={tooltipTitle('sharing')}
          PopperProps={{
            disablePortal: true
          }}
          disableFocusListener={hideTooltip}
          disableHoverListener={hideTooltip}
          disableTouchListener={hideTooltip}
        >
          <div style={{ width: '100%' }}>
            <ListItemButton
              onClick={handleNavigation('share')}
              selected={isCurrentRoute(ROUTES.share(bucketId)) && !shareMediaRestricted}
              disabled={shareMediaRestricted}
              data-test="bucket-left-drawer-sharing-links"
            >
              <ListItemIcon>
                <ScreenShareIcon />
              </ListItemIcon>
              <ListItemText primary="Sharing Links" />
            </ListItemButton>
          </div>
        </Tooltip>
      </ListItem>

      {bucketUser?.userRole !== BucketUserRole.Viewer && (
        <ListItem disablePadding sx={listItemSx}>
          <ListItemButton
            onClick={handleNavigation('trash')}
            selected={isCurrentRoute(ROUTES.trash(bucketId))}
            data-testid="bucket-left-drawer-trash"
          >
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            <ListItemText primary="Trash" />
          </ListItemButton>
        </ListItem>
      )}
    </List>
  )
}

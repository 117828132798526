import { DefaultContext, GraphQLRequest } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getAuth } from 'firebase/auth'
import Cookies from 'universal-cookie'
import { v4 } from 'uuid'
import logger from '../../utils/logger'

interface Headers {
  authorization?: string
  'X-Correlation-Id': string
  'apollographql-client-name': string
  'apollographql-client-version': string
  'x-forwarded-requestor'?: string
}

async function contextSetter(_operation: GraphQLRequest, prevContext: DefaultContext) {
  const cookies = new Cookies()
  const headers: Headers = {
    'X-Correlation-Id': v4(),
    'apollographql-client-name': prevContext.clientAwareness.name,
    'apollographql-client-version': prevContext.clientAwareness.version
  }
  const context = { headers }

  // Attempt to authorize request with firebase token
  try {
    const idToken: string | undefined = await getAuth().currentUser?.getIdToken()
    // the idToken should be the priority here
    if (idToken) {
      context.headers.authorization = idToken
    }
  } catch (err) {
    logger.error(err)
  }

  const facebookDtp: string | undefined = cookies.get('facebook_dtp')

  if (facebookDtp) {
    context.headers['x-forwarded-requestor'] = 'facebook'
  }

  return context
}

export default setContext(contextSetter)

import { useQuery } from '@apollo/client'
import { debounce, isEmpty } from 'lodash'
import { useMatch, useParams } from 'react-router-dom'
import { BucketMediaByAlbumIdDocument, BucketMediaStatus } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'
import useBucketGallerySettings from './useBucketGallerySettings'

export default function useBucketMedia() {
  const { createAlert } = useAlerts()
  const { settings } = useBucketGallerySettings()
  const path = useMatch('/bucket/:bucketId/:slug')
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  let sortBy = settings.sortBy ? { ...settings.sortBy } : null
  let filterBy = settings.filterBy ? { ...settings.filterBy } : null

  if (isEmpty(sortBy)) {
    sortBy = null
  }

  if (isEmpty(filterBy)) {
    filterBy = null
  }

  if (path?.params.slug === 'trash') {
    filterBy = { status: BucketMediaStatus.Trash }
    sortBy = null
  }

  const results = useQuery(BucketMediaByAlbumIdDocument, {
    skip: !bucketId,
    variables: { bucketId: bucketId!, albumId, filterBy, sortBy },
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message, 'error', e)
        })
        return
      }

      createAlert('There was a problem dretrieving your bucket details. Please try again later.')
      logger.error(error)
    }
  })

  const media = results.data?.bucketMediaByAlbumId?.items || []
  const nextToken = results.data?.bucketMediaByAlbumId?.nextToken
  const loading = !results.called || results.loading

  const func = () => {
    if (nextToken) {
      results.fetchMore({ variables: { nextToken } })
    }
  }
  const fetchMore = debounce(func, 500, { leading: true })

  return { media, nextToken, loading, fetchMore }
}

interface Props {
  width: number
  height: number
}

export default function AppleLogo({ width, height }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2255 4C20.4068 5.21533 19.9088 6.40467 19.2521 7.24667C18.5488 8.14933 17.3375 8.848 16.1675 8.81133C15.9541 7.648 16.5028 6.45133 17.1675 5.64467C17.9001 4.75533 19.1535 4.07333 20.2255 4ZM23.5581 21.9113C24.1634 20.9913 24.3881 20.5273 24.8581 19.486C21.4461 18.196 20.8974 13.374 24.2748 11.5227C23.2434 10.238 21.7968 9.49467 20.4314 9.49467C19.4458 9.49467 18.7706 9.75006 18.1571 9.98217C17.646 10.1755 17.1777 10.3527 16.6081 10.3527C15.9927 10.3527 15.4475 10.1583 14.8765 9.95477C14.2486 9.73096 13.5895 9.496 12.7714 9.496C11.2368 9.496 9.60478 10.4273 8.57011 12.0207C7.11478 14.2613 7.36145 18.4773 9.72145 22.0693C10.5654 23.3547 11.6921 24.7993 13.1668 24.8127C13.7774 24.8186 14.184 24.6375 14.6241 24.4414C15.1281 24.2168 15.6762 23.9727 16.6254 23.9673C17.5805 23.962 18.1197 24.2088 18.6173 24.4365C19.046 24.6328 19.4438 24.8149 20.0501 24.8093C21.5248 24.796 22.7148 23.196 23.5581 21.9113Z"
        fill="black"
      />
    </svg>
  )
}

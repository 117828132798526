import { getAuth } from 'firebase/auth'
import { ComponentWithAppleId, generateAppleIdConfig } from '../../../config/firebase'
import logger from '../../../utils/logger'

export default function useAppleLogin() {
  const init = (component: ComponentWithAppleId) => {
    // log failures
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      logger.error({ event })
    })

    // generate config for a given component
    const uid = getAuth()?.currentUser?.uid
    const appleIdConfig: AppleSignInAPI.ClientConfigI = generateAppleIdConfig({ component, uid })

    // init Apple auth sdk
    window.AppleID?.auth?.init(appleIdConfig)
    window.AppleID?.auth?.renderButton()
  }

  return { init }
}

import ConfirmPasswordException from '../errors/ConfirmPassword'
import RequiredParameterException from '../errors/RequiredParameter'

const THRESHOLD_REPEAT_CHARS = 5
const blackListWords: string[] = ['password', 'photobucket', 'qwerty']

const comparePasswordAndUser = (password?: string, username?: string, email?: string) => {
  // Check that password does not contain username
  const emailId = email?.substring(0, email.indexOf('@'))
  const userDataSensativeRegex = new RegExp(`^.*(${username}|${emailId}).*$`, 'i')
  return !!password?.match(userDataSensativeRegex)
}

function verifySequenceThreshold(num: number) {
  if (num >= THRESHOLD_REPEAT_CHARS) {
    return true
  }
  return false
}

function validateSequencePattern(password: string) {
  const toAscii = (a: string) => a.charCodeAt(0)
  const asciiPassword = password.split('').map(toAscii)
  const passwordLength = asciiPassword.length

  let floorSequenceCounter = 1
  let ceilSequenceCounter = 1
  let startIndex = 0
  let endIndex = passwordLength - 1
  while (startIndex < passwordLength) {
    if (asciiPassword[startIndex + 1]) {
      if (asciiPassword[startIndex + 1] - asciiPassword[startIndex] === 1) {
        floorSequenceCounter += 1
      } else {
        // reset
        floorSequenceCounter = 1
      }
    }
    if (asciiPassword[endIndex - 1]) {
      if (asciiPassword[endIndex - 1] - asciiPassword[endIndex] === 1) {
        ceilSequenceCounter += 1
      } else {
        ceilSequenceCounter = 1
      }
    }
    const floorSequence = verifySequenceThreshold(floorSequenceCounter)
    const ceilngSequence = verifySequenceThreshold(ceilSequenceCounter)
    startIndex += 1
    endIndex -= 1
    if (floorSequence || ceilngSequence) {
      return true
    }
  }
  return false
}

export const blackListedWord = (password?: string) => {
  if (password) {
    const blackListedRegEx = /^.*(password|photobucket|qwerty).*$/i
    const matchedValue = password.match(blackListedRegEx)
    if (matchedValue && matchedValue?.length > 1) {
      return matchedValue[1]
    }
  }
  return false
}

export default function PasswordValidator(password?: string, confirmPassword?: string, username?: string, email?: string): string {
  if (!password || password.length < 8) {
    throw new RequiredParameterException('Minimum length of 8 characters', 'password')
  }

  if (validateSequencePattern(password)) {
    throw new RequiredParameterException('Maximum of 5 consecutive characters', 'password')
  }

  if (comparePasswordAndUser(password, username, email)) {
    throw new RequiredParameterException('Cannot contain username or email', 'password')
  }

  if (blackListWords.some((blackListWord) => password?.toLowerCase().includes(blackListWord))) {
    throw new RequiredParameterException(`Password cannot contain: ${blackListedWord(password)}`, 'password')
  }

  if (password !== confirmPassword) {
    throw new ConfirmPasswordException('Passwords do not match')
  }

  return password
}

import { MutationFunctionOptions, makeVar, useReactiveVar } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { isEmpty, merge, omit } from 'lodash'
import { isMobile } from 'mobile-device-detect'
import {
  BucketAndAlbumsAndUserDocument,
  BucketMediaFilter,
  BucketMediaGroupedBy,
  BucketMediaSorter,
  BucketSortingField,
  BucketSortingOrder,
  BucketUserFilterBy,
  Exact,
  UserProfile,
  UserProfileInput,
  UserProfileUpdateMutation
} from '../../../graphql/generated'
import useUserProfileUpdate from './useUserProfileUpdate'
import logger from '../../../utils/logger'

export interface GallerySettings {
  zoom: number
  cropMode?: boolean
  filterBy?: BucketMediaFilter | null
  showImageTitle?: boolean
  sortBy: BucketMediaSorter | null
}

const defaultSettings = {
  cropMode: false,
  filterBy: null,
  sortBy: {
    order: BucketSortingOrder.Desc,
    field: BucketSortingField.DateTaken
  },
  showImageTitle: false,
  zoom: isMobile ? 3 : 5
}

const bucketGallerySettingsVar = makeVar<GallerySettings>(defaultSettings)

export default function useBucketGallerySettings() {
  const userId = getAuth().currentUser?.uid
  const settings = useReactiveVar(bucketGallerySettingsVar)
  const { mutation, loading } = useUserProfileUpdate()

  const updateSettings = (
    newSettings: Partial<GallerySettings>,
    options?: { skipMutation?: boolean; refetchAlbumsandUsers?: boolean; suppressAlerts?: boolean }
  ) => {
    if (!userId) return Promise.reject(new Error('Invalid userId'))

    // Update local settings variable
    const zoom = newSettings.zoom ?? settings.zoom
    const desktopSettings = merge(settings, newSettings, { zoom })
    bucketGallerySettingsVar({ ...desktopSettings })

    // Skip mutation if specified
    if (options?.skipMutation) return Promise.resolve()

    // Prepare the mutation data
    const filterBy = isEmpty(desktopSettings.filterBy) ? null : desktopSettings.filterBy
    const data: UserProfileInput = {
      desktopSettings: {
        ...desktopSettings,
        filterBy,
        sortBy: desktopSettings.sortBy ? { sortingOrder: desktopSettings.sortBy.order, field: desktopSettings.sortBy.field } : null,
        zoom: desktopSettings.zoom ?? null,
        cropMode: desktopSettings.cropMode ?? null,
        showImageTitle: desktopSettings.showImageTitle ?? null
      }
    }

    const mutationOptions: MutationFunctionOptions<UserProfileUpdateMutation, Exact<{ data: UserProfileInput }>> = {
      variables: { data }
    }

    if (options?.suppressAlerts) {
      mutationOptions.onCompleted = () => {
        logger.info('UserProfile update: Success without alert.')
      }
    }

    if (options?.refetchAlbumsandUsers) {
      mutationOptions.refetchQueries = [BucketAndAlbumsAndUserDocument]
      mutationOptions.awaitRefetchQueries = true
    }

    return mutation(mutationOptions)
  }

  const normalize = (newSettings: UserProfile['desktopSettings']) => {
    const cropMode = newSettings?.cropMode ?? defaultSettings.cropMode
    let filterBy: Omit<BucketUserFilterBy, '__typename'> | null = omit(newSettings?.filterBy ?? defaultSettings.filterBy, ['__typename'])
    const groupedBy = newSettings?.groupedBy ?? BucketMediaGroupedBy.AllPhotos
    const field = newSettings?.sortBy?.field ?? defaultSettings.sortBy.field
    const order = newSettings?.sortBy?.sortingOrder ?? defaultSettings.sortBy.order
    const showImageTitle = newSettings?.showImageTitle ?? defaultSettings.showImageTitle
    const sortBy = { field, order }
    const zoom = newSettings?.zoom ?? defaultSettings.zoom

    if (isEmpty(filterBy)) {
      filterBy = null
    }

    return { cropMode, filterBy, groupedBy, sortBy, showImageTitle, zoom }
  }

  return { loading, normalize, settings, updateSettings }
}

import { LinearProgress, Tooltip } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import formatBytes from '../../../../../utils/formatBytes'
import useBucket from '../../../hooks/useBucket'

export default function UtilizationMenu() {
  const { bucket } = useBucket()
  const trashSize = bucket?.counters?.totalSizeInTrash || 0
  const totalUsedSpace = bucket?.counters?.totalSize || 0
  const maxSpace = 1000000000000
  const spaceUtilization = (totalUsedSpace / maxSpace) * 100
  const trashStorage = formatBytes(trashSize, 2) || 0

  return (
    <List>
      <Tooltip title={`Trash Storage: ${trashStorage}`}>
        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItemText
            primary={
              <LinearProgress
                aria-label={`Used ${formatBytes(totalUsedSpace)} of ${maxSpace ? formatBytes(maxSpace) : 'Unlimited'}`}
                variant="determinate"
                value={Math.min(spaceUtilization, 100)}
                color={spaceUtilization > 75 ? 'secondary' : 'primary'}
              />
            }
            secondary={`Used ${formatBytes(totalUsedSpace)} of ${maxSpace ? formatBytes(maxSpace) : 'Unlimited'}`}
          />
        </ListItem>
      </Tooltip>
    </List>
  )
}

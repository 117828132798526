import { useMutation } from '@apollo/client'
import { logger } from 'workbox-core/_private'
import { BucketMediaMoveDocument } from '../../../graphql/generated'
import useAlerts from './useAlerts'
import useSelectedMedia from './useSelectedMedia'

export default function useBucketMediaMove() {
  const { createAlert } = useAlerts()
  const { clearSelectedMedia } = useSelectedMedia()
  const [mutation, results] = useMutation(BucketMediaMoveDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was an error moving your media. Please try again later.')
      }
    },
    onCompleted: () => {
      createAlert('Media moved successfully!', 'success')
      clearSelectedMedia()
    }
  })

  const { loading } = results

  return { mutation, results, loading }
}

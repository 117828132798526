import { getDataSet, reduce } from 'iso3166-2-db'

export const listOfCountries = reduce(getDataSet(), 'en')

export const countries = Object.keys(listOfCountries)
  .sort((a, b) => (listOfCountries[a].name > listOfCountries[b].name ? 1 : 0))
  .map((c) => ({
    value: c,
    label: listOfCountries[c].name
  }))

import { useApolloClient, useReactiveVar } from '@apollo/client'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ChangeEvent, useState } from 'react'
import { BucketAlbumFragmentFragmentDoc } from '../../../../graphql/generated'
import { activeAlbumIdVar, drawerVar } from '../../../../providers/apollo/cache'
import logger from '../../../../utils/logger'
import useBucketAlbumUpdate from '../../hooks/useBucketAlbumUpdate'
import useBucket from '../../hooks/useBucket'

interface Fields {
  title?: string
  description?: string
}

type Field = keyof Fields

interface Errors extends Fields {
  general?: string
}

export default function AlbumDetailsDrawer() {
  const { bucketUser } = useBucket()
  const drawer = useReactiveVar(drawerVar)
  const albumId = useReactiveVar(activeAlbumIdVar)
  const [fields, setFields] = useState<Fields>({})
  const [errors, setErrors] = useState<Errors>({})
  const { mutation } = useBucketAlbumUpdate()
  const cache = useApolloClient()
  const album = cache.readFragment({ id: `BucketAlbum:${albumId}`, fragment: BucketAlbumFragmentFragmentDoc })
  const disabled = !bucketUser?.permissions?.albumUpdate

  const handleClose = () => {
    activeAlbumIdVar(null)
    drawerVar(null)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFields = { ...fields }
    const field = event.target.name as Field
    updatedFields[field] = event.target.value
    setFields(updatedFields)

    const updatedErrors = { ...errors }
    delete updatedErrors[field]
    delete updatedErrors.general
    setErrors(updatedErrors)
  }

  const onBlurHandler = () => {
    logger.info('blur')
    if (!album) {
      return
    }
    const variables = {
      albumId: album.id,
      bucketId: album.bucketId,
      data: fields
    }
    logger.info(variables)
    mutation({ variables })
  }

  return (
    <Drawer anchor="right" open={drawer === 'bucketAlbumDetails'} onClose={handleClose} PaperProps={{ sx: { width: '400px', maxWidth: '100%' } }}>
      <Toolbar>
        <Typography sx={{ fontSize: '18px', color: '#081230', flex: 1 }} data-test="album-details-drawer">
          Details
        </Typography>
        <IconButton onClick={handleClose} data-test="close" size="large">
          <Close />
        </IconButton>
      </Toolbar>
      {!disabled && (
        <Box sx={{ padding: 2 }}>
          <TextField
            data-test="title"
            label="Title"
            placeholder="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={handleChange}
            name="title"
            onBlur={onBlurHandler}
            value={fields.title || album?.title || ''}
          />
          <TextField
            data-test="description"
            label="Description"
            placeholder="Description"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            onChange={handleChange}
            name="description"
            onBlur={onBlurHandler}
            value={fields.description || album?.description || ''}
          />
        </Box>
      )}
      {disabled && (
        <Box sx={{ py: 2, px: 3 }}>
          <Typography data-test="title">
            <strong>Title</strong>
          </Typography>
          <Typography>{album?.title ?? 'Untitled'}</Typography>
          <Typography data-test="description" sx={{ mt: 2 }}>
            <strong>Description</strong>
          </Typography>
          <Typography>{album?.description ?? 'No description.'}</Typography>
        </Box>
      )}
    </Drawer>
  )
}

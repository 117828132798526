import { TextField } from '@mui/material'
import { styled } from '@mui/styles'

export default styled(TextField)(() => ({
  backgroundColor: 'white',
  borderBottomColor: 'none',
  borderColor: '#D0D5DD',
  borderRadius: '8px',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  marginTop: '6px',
  marginBottom: '25px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  fontFamily: 'sans-serif',
  '& .MuiInputBase-input': {
    padding: '8px'
  }
}))

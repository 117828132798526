import { FirebaseError } from '@firebase/util'
import { Alert, AlertTitle, Switch, Typography } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import {
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  getRedirectResult,
  linkWithCredential,
  linkWithPopup,
  linkWithRedirect,
  unlink
} from 'firebase/auth'
import { capitalize, filter, intersection } from 'lodash'
import { isMobile } from 'mobile-device-detect'
import { useRef, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { IdpFeatureFlagMap, getLoginProviderConfig, supportedLoginProviderIds } from '../../../../config/firebase'
import AppleAuthProvider from '../../../../config/idps/apple'
import DefaultException from '../../../../errors/Default'
import AppleLogo from '../../../../svg/AppleLogo'
import GoogleLogo from '../../../../svg/GoogleLogo'
import logger from '../../../../utils/logger'
import useAlerts from '../../hooks/useAlerts'
import Loader from '../../../../components/Loader'
import useAppleLogin from '../../../auth/hooks/useAppleLogin'

const LINK_ACCOUNT_LOCAL_STORAGE_VAR = 'link_idp'

type Checked = {
  [GoogleAuthProvider.PROVIDER_ID]?: boolean
  [AppleAuthProvider.PROVIDER_ID]?: boolean
}

export default function LinkedAccounts() {
  const { init } = useAppleLogin()
  const { createAlert } = useAlerts()
  const [checked, setChecked] = useState<Checked>({})
  const [called, setCalled] = useState(false)
  const [loading, setLoading] = useState(true)

  // individual idp flags
  const idpFeatureFlags: IdpFeatureFlagMap = {
    isFirebaseAppleIdpEnabled: useFlag('isFirebaseAppleIdpEnabled'),
    isFirebaseGoogleIdpEnabled: useFlag('isFirebaseGoogleIdpEnabled'),
    isFirebaseFacebookIdpEnabled: useFlag('isFirebaseFacebookIdpEnabled'),
    isFirebaseMicrosoftIdpEnabled: useFlag('isFirebaseMicrosoftIdpEnabled'),
    isFirebasePaypalIdpEnabled: useFlag('isFirebasePaypalIdpEnabled')
  }

  // all valid and enabled idps
  const enabledProviders = useRef(
    // filters out all of the disabled idps
    filter(supportedLoginProviderIds, (providerId) => {
      const { featureFlagName } = getLoginProviderConfig(providerId)
      const enabled = idpFeatureFlags[featureFlagName]

      return enabled
    })
  )

  const linkProvider = async (providerId: keyof Checked) => {
    const provider = getLoginProviderConfig(providerId)
    try {
      const { currentUser } = getAuth()
      if (!currentUser) {
        createAlert('There is an issue with your session, please reload the page and try again.', 'error')
        return
      }

      // custom apple flow
      if (providerId === AppleAuthProvider.PROVIDER_ID) {
        const result = await window.AppleID?.auth?.signIn().catch((e) => {
          logger.error('Error during Apple signIn', e)
          throw e
        })

        // prevent errors for redirect flow
        if (!result) {
          return
        }

        const appleCredential = new AppleAuthProvider().credential({ idToken: result.authorization.id_token })
        await linkWithCredential(currentUser, appleCredential)
        setChecked({ ...checked, [providerId]: true })
        createAlert('Successfully linked your Apple account.', 'success')
        return
      }

      if (isMobile) {
        localStorage.setItem(LINK_ACCOUNT_LOCAL_STORAGE_VAR, providerId)
        await linkWithRedirect(currentUser, provider.getProvider())
        return
      }

      await linkWithPopup(currentUser, provider.getProvider())
      setChecked({ ...checked, [providerId]: true })
      createAlert(`Successfully linked your ${capitalize(provider.name)} account.`, 'success')
    } catch (err) {
      createAlert(`There was an error linking your ${capitalize(provider.name)} account. Please try again.`)
      logger.error(err)
    }
  }

  const unlinkProvider = async (providerId: string) => {
    const { name } = getLoginProviderConfig(providerId)

    try {
      const { currentUser } = getAuth()
      if (!currentUser) {
        throw new DefaultException('There is an issue with your session, please reload the page and try again.')
      }

      await unlink(currentUser, providerId)
      const map = { ...checked }
      delete map[providerId as keyof Checked]
      setChecked(map)
      createAlert(`Successfully unlinked your ${capitalize(name)} account`, 'success')
    } catch (err) {
      const error = err as DefaultException
      switch (error.code) {
        case 'DefaultException':
          createAlert(error.message, 'error')
          break
        default:
          createAlert(`There was an error unlinking your ${capitalize(name)} account. Please try again.`)
      }
      logger.error(err)
    }
  }

  const handleChange = (providerId: keyof Checked) => {
    return () => {
      const user = getAuth().currentUser
      if (!user) {
        createAlert('There was an error with your session.')
        return
      }

      if (checked[providerId]) {
        unlinkProvider(providerId)
      } else {
        linkProvider(providerId)
      }
    }
  }

  const updateIdentityProvidersRef = useRef(async () => {
    try {
      const email = getAuth().currentUser?.email
      if (!email) {
        throw new Error('Issue with session. Please try reloading the page and try again.')
      }

      const fetchedIdentityProviders = await fetchSignInMethodsForEmail(getAuth(), email)
      const validLinkedIdentityProviders = intersection(fetchedIdentityProviders, enabledProviders.current)
      filter(supportedLoginProviderIds, (providerId) => {
        const { featureFlagName } = getLoginProviderConfig(providerId)
        const enabled = idpFeatureFlags[featureFlagName]
        return enabled
      })

      const map: Checked = {}
      validLinkedIdentityProviders.forEach((provider) => {
        const key = provider as keyof Checked
        map[key] = true
      })

      setChecked(map)
    } catch (err) {
      const error = err as FirebaseError
      logger.error(err)
      createAlert(error.message, 'error')
    }
  })

  useEffectOnce(() => {
    init('link-account')
  })

  useEffectOnce(() => {
    const email = getAuth().currentUser?.email
    if (!email) {
      createAlert('There was an issue fetching your account.')
      return
    }

    fetchSignInMethodsForEmail(getAuth(), email)
      .then((results) => {
        const validLinkedIdentityProviders = intersection(results, enabledProviders.current)
        const map: Checked = {}
        validLinkedIdentityProviders.forEach((provider) => {
          const key = provider as keyof Checked
          map[key] = true
        })
        setLoading(false)
        setCalled(true)
        setChecked(map)
      })
      .catch((error) => {
        setLoading(false)
        setCalled(true)
        createAlert('There was an error fetching your account.')
        logger.error(error)
      })
  })

  // fetches linked idps
  useEffectOnce(() => {
    const checkForRedirect = async () => {
      const redirectProviderId = localStorage.getItem(LINK_ACCOUNT_LOCAL_STORAGE_VAR)
      if (!redirectProviderId) {
        return
      }

      try {
        await getRedirectResult(getAuth())

        const { name } = getLoginProviderConfig(redirectProviderId)
        createAlert(`Successfully linked your ${capitalize(name)} account.`, 'success')
      } catch (err) {
        const error = err as FirebaseError
        createAlert(error.message, 'error')
      } finally {
        localStorage.setItem(LINK_ACCOUNT_LOCAL_STORAGE_VAR, '')
      }
    }

    checkForRedirect().catch(logger.error).finally(updateIdentityProvidersRef.current)
  })

  if (!called && loading) {
    return <Loader />
  }

  return (
    <form>
      <Typography variant="h6">Social Sign In Options</Typography>
      <Alert variant="standard" severity="info">
        <AlertTitle>Notice</AlertTitle>
        Toggling an account on will require you to sign in to connect the account to your Photobucket account.
      </Alert>
      {idpFeatureFlags.isFirebaseGoogleIdpEnabled && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <GoogleLogo width={64} height={64} />
          <Switch onChange={handleChange('google.com')} checked={Boolean(checked['google.com'])} inputProps={{ 'aria-label': 'controlled' }} />
        </div>
      )}
      {idpFeatureFlags.isFirebaseAppleIdpEnabled && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AppleLogo width={64} height={64} />
          <Switch onChange={handleChange('apple.com')} checked={Boolean(checked['apple.com'])} inputProps={{ 'aria-label': 'controlled' }} />
        </div>
      )}
    </form>
  )
}

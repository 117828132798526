import Hidden from '@mui/material/Hidden'
import { getAuth } from 'firebase/auth'
import { useParams } from 'react-router-dom'
import DesktopDrawer from './desktop'
import MobileDrawer from './mobile'

export default function Drawer() {
  const { bucketId } = useParams<{ bucketId: string }>()
  const userId = getAuth().currentUser?.uid

  if (!bucketId || !userId) {
    return null
  }

  return (
    <>
      <Hidden lgUp>
        <MobileDrawer bucketId={bucketId} userId={userId} />
      </Hidden>
      <Hidden lgDown>
        <DesktopDrawer bucketId={bucketId} userId={userId} />
      </Hidden>
    </>
  )
}

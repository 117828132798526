import { ApolloClient, useApolloClient, useQuery, useReactiveVar } from '@apollo/client'
import { ArrowForward } from '@mui/icons-material'
import Close from '@mui/icons-material/Close'
import { ListItemButton, ListItemText } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { debounce } from 'lodash'
import { useState } from 'react'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import { useMatch, useParams } from 'react-router-dom'
import theme from '../../../../config/theme'
import { BucketAlbum, BucketAlbumFragmentFragmentDoc, BucketAlbumsDocument, BucketAlbumsQueryVariables } from '../../../../graphql/generated'
import { activeAlbumIdVar, drawerVar } from '../../../../providers/apollo/cache'
import logger from '../../../../utils/logger'
import useAlerts from '../../hooks/useAlerts'
import useBucketAlbumsMove from '../../hooks/useBucketAlbumsMove'
import useSelectedAlbums from '../../hooks/useSelectedAlbums'
import AlbumSubHeader from './components/AlbumSubHeader'
import SelectAlbum from './components/SelectAlbum'

const compareFn = (a: BucketAlbum, b: BucketAlbum) => {
  if (a.title.toLowerCase() > b.title.toLowerCase()) {
    return 1
  }

  if (a.title.toLowerCase() < b.title.toLowerCase()) {
    return -1
  }

  return 0
}

// Recursively check if an album is a (grand)child of another album
const albumIsChild = (client: ApolloClient<object>, bucketId: string, albumId: string, targetAlbumId: string): boolean => {
  const targetAlbum = client.readFragment<BucketAlbum>({
    id: client.cache.identify({ __typename: 'BucketAlbum', id: targetAlbumId }),
    fragment: BucketAlbumFragmentFragmentDoc
  })

  if (targetAlbum?.parentId === albumId) {
    return true
  }

  if (!targetAlbum?.parentId || targetAlbum.parentId === bucketId) {
    return false
  }

  return albumIsChild(client, bucketId, albumId, targetAlbum.parentId)
}

export default function AlbumMoveDrawer() {
  const client = useApolloClient()
  const { selectedAlbums } = useSelectedAlbums()
  const { bucketId } = useParams<{ bucketId: string }>()
  const drawer = useReactiveVar(drawerVar)
  const activeAlbumId = activeAlbumIdVar()
  let albumIds = Array.from(selectedAlbums.keys())
  if (selectedAlbums.size === 0 && activeAlbumId) {
    albumIds = [activeAlbumId]
  }
  const { createAlert } = useAlerts()
  const { mutation } = useBucketAlbumsMove()
  const [destinationAlbumId, setDestinationAlbumId] = useState<string | null>(null)
  const [selected, setSelected] = useState(false)
  const match = useMatch('/bucket/:bucketId/:slug')
  const albumsPage = match?.params.slug === 'albums'

  const handleClose = () => {
    activeAlbumIdVar(null)
    drawerVar(null)
  }

  const results = useQuery(BucketAlbumsDocument, {
    skip: !bucketId,
    variables: { bucketId: bucketId! },
    onError: (e) => {
      e.graphQLErrors.forEach((err) => {
        createAlert(err.message)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('We are having trouble retrieving your albums. Please try again later.')
      }
    }
  })
  const albums = results.data?.bucketAlbums?.items || []
  const nextToken = results.data?.bucketAlbums?.nextToken

  const func = () => {
    if (nextToken) {
      results.fetchMore({ variables: { nextToken } })
    }
  }
  const fetchMore = debounce(func, 500, { leading: true })

  const handleClick = () => {
    if (destinationAlbumId) {
      setDestinationAlbumId(null)
    }
    setSelected(!selected)
  }

  const handleMove = () => {
    if (!bucketId) {
      return
    }

    // Check if any of the selected albums is the destination album
    if (albumIds.includes(destinationAlbumId!)) {
      createAlert('Cannot move album into itself')
      return
    }

    // Check if the destination album is a child of any of the selected albums
    if (destinationAlbumId) {
      const moveIsAllowed = albumIds.every((albumId) => !albumIsChild(client, bucketId, albumId, destinationAlbumId))
      if (!moveIsAllowed) {
        createAlert('Cannot move album into a child album')
        return
      }
    }

    mutation({
      variables: {
        bucketId,
        albumIds,
        data: { destinationAlbumId }
      },
      update: (cache, { data }) => {
        if (!data?.bucketAlbumsMove) {
          return
        }

        albumIds.forEach((albumId) => {
          if (!bucketId) {
            return
          }

          const album = cache.readFragment({ id: `BucketAlbum:${albumId}`, fragment: BucketAlbumFragmentFragmentDoc })

          const sourceParentAlbumId = album?.parentId

          // Update source album media count
          cache.updateFragment({ id: `BucketAlbum:${sourceParentAlbumId}`, fragment: BucketAlbumFragmentFragmentDoc }, (current) => {
            if (!current) return null

            const subAlbumCount = Math.max((current.subAlbumCount || 0) - 1, 0)
            const totalSubalbums = Math.max((current.counters?.totalSubalbums || 0) - 1, 0)

            return {
              ...current,
              subAlbumCount,
              counters: {
                ...current.counters,
                totalSubalbums
              }
            }
          })

          // Remove album from Source Parent Album
          const sourceVariables: BucketAlbumsQueryVariables = { bucketId }
          if (sourceParentAlbumId !== bucketId) {
            sourceVariables.albumId = sourceParentAlbumId
          }

          cache.updateQuery({ query: BucketAlbumsDocument, variables: sourceVariables }, (cachedData) => {
            if (!cachedData) return null
            const bucketAlbums = cachedData?.bucketAlbums
            const items = bucketAlbums?.items.filter((subalbum) => subalbum.id !== albumId) || []
            return {
              bucketAlbums: {
                ...bucketAlbums,
                items
              }
            }
          })

          // Update target album to include album
          const targetVariables: BucketAlbumsQueryVariables = { bucketId }
          if (destinationAlbumId && destinationAlbumId !== bucketId) {
            targetVariables.albumId = destinationAlbumId
          }

          cache.updateQuery({ query: BucketAlbumsDocument, variables: targetVariables }, (current) => {
            if (!current || !album) return null

            const updatedAlbum = { ...album, parentId: destinationAlbumId } as BucketAlbum
            const items = [updatedAlbum, ...(current.bucketAlbums?.items || [])]
            items.sort(compareFn)

            return {
              bucketAlbums: {
                ...current.bucketAlbums,
                items
              }
            }
          })
        })

        // Update target album media count
        cache.updateFragment({ id: `BucketAlbum:${destinationAlbumId}`, fragment: BucketAlbumFragmentFragmentDoc }, (current) => {
          if (!current) return null

          const subAlbumCount = Math.max((current.subAlbumCount || 0) + 1, 0)
          const totalSubalbums = Math.max((current.counters?.totalSubalbums || 0) + 1, 0)

          return {
            ...current,
            subAlbumCount,
            counters: {
              ...current.counters,
              totalSubalbums
            }
          }
        })
      },
      onCompleted: () => {
        handleClose()
      }
    })
  }

  return (
    <Drawer anchor="right" open={drawer === 'bucketAlbumMove'} onClose={handleClose} PaperProps={{ sx: { width: '400px', maxWidth: '100%' } }}>
      <Toolbar>
        <Typography sx={{ fontSize: '18px', color: '#081230', flex: 1, fontWeight: 500 }} data-test="album-details-drawer">
          Organize Albums
        </Typography>
        <IconButton onClick={handleClose} data-test="close" size="large">
          <Close />
        </IconButton>
      </Toolbar>
      <Box sx={{ padding: 2 }}>
        <List data-test="album">
          <AlbumSubHeader setAlbumId={setDestinationAlbumId} />
          {!albumsPage && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '5px',
                backgroundColor: selected ? theme.palette.primary.main : theme.palette.primary.highlight,
                borderRadius: selected ? '4px' : 0
              }}
            >
              <ListItemButton
                divider
                sx={{
                  paddingLeft: '5px',
                  paddingRight: 0,
                  border: 'none',
                  '&:hover': {
                    background: 'transparent',
                    color: theme.palette.primary.main
                  }
                }}
                disableRipple
                onClick={handleClick}
              >
                <ListItemText
                  sx={{ color: selected ? '#fff' : 'inherit' }}
                  primaryTypographyProps={{
                    sx: { fontWeight: selected ? 'bold' : 'normal' }
                  }}
                  primary="All Media"
                />
              </ListItemButton>
            </Box>
          )}
          <ReactInfiniteScroll
            dataLength={albums.length || 0}
            loader={
              <Typography variant="h6" align="center">
                Loading...
              </Typography>
            }
            next={fetchMore}
            hasMore={Boolean(nextToken)}
          >
            {albums.map((album) => (
              <SelectAlbum
                key={album.id}
                album={album}
                allItems={selected}
                setAllItems={setSelected}
                selectedAlbumId={destinationAlbumId}
                setSelectedAlbumId={setDestinationAlbumId}
                disabled={albumIds.includes(album.id)}
                albumIds={albumIds}
              />
            ))}
          </ReactInfiniteScroll>
        </List>
        {(selected || destinationAlbumId) && (
          <Button data-test="move-media-drawer-submit" onClick={handleMove} variant="outlined" color="primary" fullWidth>
            Move
            <ArrowForward sx={{ paddingLeft: '5px' }} />
          </Button>
        )}
      </Box>
    </Drawer>
  )
}

import { useQuery } from '@apollo/client'
import { Person, PersonOutline } from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import { Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useParams } from 'react-router-dom'
import { Bucket, BucketType, BucketUsersDocument, PermissionPolicy } from '../../../../../graphql/generated'

interface Props {
  bucket: Bucket
  permissions: PermissionPolicy | null | undefined
}

const formatMembersTooltip = (members: Array<string>) => {
  const visible = members.slice(0, 4)
  let tooltip = visible.length > 1 ? visible.join(', ') : visible
  if (members.length > visible.length) {
    const difference = members.length - visible.length
    tooltip += `, and ${difference} more`
  }
  return tooltip
}

export default function BucketAdminActions({ bucket, permissions }: Props) {
  const { bucketId } = useParams<{ bucketId: string }>()
  const results = useQuery(BucketUsersDocument, { skip: !bucketId || !permissions?.userList, variables: { bucketId: bucketId! } })
  const memberNames = results.data?.bucketUsers?.items.map((user) => user.id) || []

  if (!permissions?.userList) {
    return (
      <Stack direction="row" spacing={1}>
        {bucket.bucketType === BucketType.Group ? <GroupsIcon /> : <PersonOutline />}
      </Stack>
    )
  }

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={formatMembersTooltip(memberNames)} placement="bottom-start" arrow>
        {bucket.bucketType === BucketType.Group ? <GroupsIcon /> : <Person />}
      </Tooltip>
    </Stack>
  )
}

import { useReactiveVar } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { AlertColor } from '@mui/material'
import { v4 } from 'uuid'
import { alertsVar } from '../../../providers/apollo/cache'

interface GraphQLExtensions {
  http?: {
    status?: number
  }
}

export default function useAlerts() {
  const alerts = useReactiveVar(alertsVar)

  const createAlert = (originalMessage: string, severity: AlertColor = 'error', graphQLError: GraphQLError | null = null) => {
    let message = originalMessage
    const statusCode = (graphQLError?.extensions as GraphQLExtensions)?.http?.status

    switch (statusCode) {
      case 400:
        message = 'Bad request. Please check your request and try again.'
        break
      case 401:
        message = 'You are not authenticated. Please log in to continue.'
        break
      case 403:
        message = 'You do not have permission to perform this action.'
        break
      case 404:
        message = 'The requested resource was not found.'
        break
      case 408:
        message = 'Request timeout. Please try again later.'
        break
      case 500:
        message = 'Internal server error. Please try again later.'
        break
      case 502:
        message = 'Bad gateway. Please try again later.'
        break
      case 503:
        message = 'Service unavailable. Please try again later.'
        break
      case 504:
        message = 'Gateway timeout. Please check your network connection.'
        break
      default:
      // Keep the original message if no status code is matched
    }

    const alert = { id: v4(), message, severity }
    alertsVar([...alerts, alert])
  }

  return { createAlert }
}

import { initializeApp } from 'firebase/app'
import { getAuth, setPersistence } from 'firebase/auth'
import { firebaseConfig, firebaseDebugToken, persistance } from './config/firebase'

if (firebaseDebugToken && firebaseDebugToken !== 'false') {
  // // https://firebase.google.com/docs/app-check/web/debug-provider#ci
  // @ts-ignore
  // eslint-disable-next-line
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true

  // @ts-ignore
  // eslint-disable-next-line
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = firebaseDebugToken
}

initializeApp(firebaseConfig)
const auth = getAuth()
setPersistence(auth, persistance)

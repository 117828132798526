import { useApolloClient } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { useParams } from 'react-router-dom'
import { BucketConvertStatus, UserProfileFragmentFragmentDoc } from '../../../graphql/generated'

export default function useBucketConvertProgress() {
  const client = useApolloClient()
  const { bucketId } = useParams<{ bucketId: string }>()
  const userId = getAuth().currentUser?.uid
  const userProfile = client.cache.readFragment({
    id: `UserProfile:${userId}`,
    fragment: UserProfileFragmentFragmentDoc,
    fragmentName: 'UserProfileFragment'
  })
  const isBucketConvertInProgressForBucket =
    userProfile?.bucketConvertMetadata?.status === BucketConvertStatus.InProgress && userProfile?.bucketConvertMetadata?.bucketId === bucketId

  return isBucketConvertInProgressForBucket
}

import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useFlag } from '@unleash/proxy-client-react'
import { getAuth } from 'firebase/auth'
import { useEffect, useRef } from 'react'
import { Architecture, BucketDocument, ProfileMigrationStatus, UserProfileDocument, UserProfileUpdateDocument } from '../../../graphql/generated'
import { convertedVar } from '../../../providers/apollo/cache'
import logger from '../../../utils/logger'

const STATUSES_THAT_CANNOT_ACCESS_OLD_ARCHITECTURE: Array<ProfileMigrationStatus | null | undefined> = [
  ProfileMigrationStatus.Completed,
  ProfileMigrationStatus.InProgress
]

export default function useLegacyGraph(automaticallyRedirectAndFixBadData: boolean | undefined = false) {
  const disableLegacyGraphForMigratedUsers = useFlag('disableLegacyGraphForMigratedUsers')
  const [mutation] = useMutation(UserProfileUpdateDocument, {
    onError: (error) => {
      logger.error('Error updating profile', error)
    },
    onCompleted: () => {
      if (window.location.href.includes(`/u/${getAuth().currentUser?.uid}`)) {
        window.location.href = '/buckets'
      }
    }
  })
  const mutationRef = useRef(mutation)
  const profileQuery = useQuery(UserProfileDocument, { fetchPolicy: 'cache-first' })
  const profile = profileQuery.data?.userProfile
  const profileLoaded = Boolean(profile)
  const bucketQuery = useQuery(BucketDocument, { skip: !profile?.currentBucketId, variables: { bucketId: profile?.currentBucketId! } })
  const currentBucketLoaded = Boolean(bucketQuery.data?.bucket)
  const currentBucket = bucketQuery.data?.bucket
  // custom claim (`isConverted`) that throws an error on the legacy graph when `true`
  const converted = useReactiveVar(convertedVar)
  // determine if the current bucket is a legacy bucket
  const currentBucketArchitecture = currentBucket?.architecture
  const isCurrentBucketIdUsername = profile?.currentBucketId === getAuth().currentUser?.uid
  const isCurrentBucketLegacy = isCurrentBucketIdUsername || currentBucketArchitecture === Architecture.Kafka
  // determine if the user has a Kafka bucket
  const hasKafkaBucket = profile?.migrationMetadata?.hasKafkaBucket
  // determine if migration should block user from accessing the old architecture
  const blockedByMigration = STATUSES_THAT_CANNOT_ACCESS_OLD_ARCHITECTURE.includes(profile?.migrationMetadata?.migrationStatus)
  const disableLegacyArchitectureAccess = disableLegacyGraphForMigratedUsers && profileLoaded && (blockedByMigration || converted || !hasKafkaBucket)
  // determine if we should attempt to auto repair and redirect the user
  const autofixEnabled = disableLegacyGraphForMigratedUsers && automaticallyRedirectAndFixBadData
  const setConvertedVar = (value: boolean) => {
    convertedVar(value)
  }

  useEffect(() => {
    if (!profileLoaded || !currentBucketLoaded) return
    if (!autofixEnabled) return
    if (!isCurrentBucketLegacy && !disableLegacyArchitectureAccess) return

    // Setting the currentBucketId to null will prevent the user from being redirected to Kafka
    mutationRef.current({
      variables: {
        data: {
          currentBucketId: null
        }
      }
    })
  }, [autofixEnabled, isCurrentBucketLegacy, profileLoaded, currentBucketLoaded, disableLegacyArchitectureAccess])

  return { setConvertedVar, disableLegacyArchitectureAccess, skipLegacyRequests: disableLegacyArchitectureAccess }
}

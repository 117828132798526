import { DeleteOutline } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import { useParams } from 'react-router-dom'
import { activeAlbumIdVar, modalVar } from '../../../../../providers/apollo/cache'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'

interface Props {
  handleClose: () => void
}

export default function AlbumDelete({ handleClose }: Props) {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const { selectedAlbums } = useSelectedAlbums()
  const containRootAlbum = Array.from(selectedAlbums.keys()).includes(bucketId as string)

  const handleClick = () => {
    if (!albumId && selectedAlbums.size < 1) return

    if (albumId) activeAlbumIdVar(albumId)
    modalVar('bucketAlbumDelete')
    handleClose()
  }

  return (
    <MenuItem
      onClick={handleClick}
      disabled={containRootAlbum || !(albumId || selectedAlbums.size !== 0)}
      id="delete-menu-item"
      data-test="delete-menu-item"
    >
      <DeleteOutline style={{ marginRight: 8 }} /> Delete Album
    </MenuItem>
  )
}

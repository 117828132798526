import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { isMobile } from 'mobile-device-detect'
import { logger } from 'workbox-core/_private'
import { BucketGenerateDownloadLinksDocument, BucketMediaSignedUrlDocument } from '../../../graphql/generated'
import { activeAlbumIdVar } from '../../../providers/apollo/cache'
import useAlerts from './useAlerts'
import useSelectedAlbums from './useSelectedAlbums'

export default function useBucketDownload() {
  const { createAlert } = useAlerts()
  const activeAlbumId = useReactiveVar(activeAlbumIdVar)
  const { selectedAlbums, clearSelectedAlbums } = useSelectedAlbums()
  const [generateDownloadLinks, downloadMultipleMediaResults] = useLazyQuery(BucketGenerateDownloadLinksDocument, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message, 'error', e)
        })
        return
      }

      createAlert('There was a problem downloading your album. Please try again later.')
      logger.error(error)
    },
    onCompleted: () => {
      createAlert('Your media is being prepared and will be emailed to you.', 'success')
    }
  })

  const [retrieveMediaSignedUrl, downloadSingleMediaResults] = useLazyQuery(BucketMediaSignedUrlDocument, {
    onCompleted: ({ bucketMedia }) => {
      if (!bucketMedia?.signedUrl) return

      if (isMobile) {
        window.open(bucketMedia.signedUrl, '_blank')
        return
      }

      // We have tried this a variety of ways in the past,
      // but this appears to be the most reliable for now
      const a = document.createElement('a')
      a.href = bucketMedia.signedUrl
      a.download = bucketMedia.signedUrl
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      clearSelectedAlbums()
    },
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message, 'error', e)
        })
        return
      }

      createAlert('There was a problem downloading your album. Please try again later.')
      logger.error(error)
    }
  })

  // There is a race condition where the activeAlbumId reactive var doesn't update in
  // time for the generateDownloadLinksMutation, so the `albumIds` parameter exists
  // to provide a value in real time that won't be missed by this cache updating race condition
  const downloadAlbums = (bucketId: string, albumIds?: Array<string>) => {
    let albums = albumIds || []
    if (!albums.length) {
      albums = activeAlbumId ? [activeAlbumId] : Array.from(selectedAlbums.keys())
    }
    generateDownloadLinks({ variables: { bucketId, albums: albumIds } })
  }

  const downloadSingleMedia = (bucketId: string, mediaId: string) => {
    retrieveMediaSignedUrl({ variables: { bucketId, mediaId } })
  }

  const downloadMultipleMedia = (bucketId: string, mediaIds: string[]) => {
    generateDownloadLinks({ variables: { bucketId, media: mediaIds } })
  }

  return { downloadAlbums, downloadMultipleMedia, downloadSingleMedia, downloadMultipleMediaResults, downloadSingleMediaResults }
}

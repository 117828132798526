import { Alert, AlertTitle } from '@mui/material'
import { styled } from '@mui/styles'
import { ReactElement } from 'react'

export const StyledFormAlertError = styled(Alert)(({ theme }) => ({
  borderColor: theme.palette.customError[500],
  borderRadius: '5px',
  borderWidth: '2px',
  color: theme.palette.customError[500],
  textAlign: 'left',
  '& .MuiAlert-icon': {
    color: theme.palette.customError[500]
  }
}))

export const StyledFormAlertInfo = styled(Alert)(({ theme }) => ({
  borderColor: theme.palette.brand[500],
  borderRadius: '5px',
  borderWidth: '2px',
  color: theme.palette.customBlue[500],
  textAlign: 'left',
  '& .MuiAlert-icon': {
    color: theme.palette.customBlue[500]
  }
}))

export const StyledFormAlertSuccess = styled(Alert)(({ theme }) => ({
  borderColor: theme.palette.customSuccess[500],
  borderRadius: '5px',
  borderWidth: '2px',
  color: theme.palette.customSuccess[500],
  textAlign: 'left',
  '& .MuiAlert-icon': {
    color: theme.palette.customSuccess[500]
  }
}))

export const StyledFormAlertTitle = styled(AlertTitle)(({ theme }) => ({
  color: theme.palette.customGrey[700],
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  fontFamily: '"Outfit", Arial, sans-serif',
  width: '100%'
}))

interface Props {
  title?: string
  body?: ReactElement<any, any> | string
}

export function StyledFormError({ body, title }: Readonly<Props>) {
  if (!body) {
    return null
  }

  return (
    <StyledFormAlertError variant="outlined" severity="error" sx={{ my: 2 }}>
      <StyledFormAlertTitle>{title}</StyledFormAlertTitle>
      {body}
    </StyledFormAlertError>
  )
}

export function StyledFormSuccess({ body, title }: Readonly<Props>) {
  if (!body) {
    return null
  }

  return (
    <StyledFormAlertSuccess variant="outlined" severity="success" sx={{ my: 2 }}>
      <StyledFormAlertTitle>{title}</StyledFormAlertTitle>
      {body}
    </StyledFormAlertSuccess>
  )
}

export function StyledFormInfo({ body, title }: Readonly<Props>) {
  if (!body) {
    return null
  }

  return (
    <StyledFormAlertInfo variant="outlined" severity="info" sx={{ my: 2 }}>
      {title && <StyledFormAlertTitle>{title}</StyledFormAlertTitle>}
      {body}
    </StyledFormAlertInfo>
  )
}

const defaultProps = {
  body: ''
}

StyledFormError.defaultProps = defaultProps
StyledFormSuccess.defaultProps = defaultProps
StyledFormInfo.defaultProps = defaultProps

import MoreVert from '@mui/icons-material/MoreVert'
import { useMediaQuery, useTheme } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { MouseEvent, useState } from 'react'
import { useMatch } from 'react-router-dom'
import MediaDelete from './iconButtons/MediaDelete'
import MediaDetails from './iconButtons/MediaDetails'
import MediaDownload from './iconButtons/MediaDownload'
import MediaFavorite from './iconButtons/MediaFavorite'
import MediaShare from './iconButtons/MediaShare'
import AlbumDelete from './menuItems/AlbumDelete'
import AlbumDetails from './menuItems/AlbumDetails'
import AlbumDownload from './menuItems/AlbumDownload'
import AlbumMove from './menuItems/AlbumMove'
import MediaMove from './menuItems/MediaMove'
import MediaPrintShop from './menuItems/MediaPrintShop'
import PlaySlideshow from './menuItems/PlaySlideshow'
import EditImage from './menuItems/EditImage'
import useSelectedMedia from '../../hooks/useSelectedMedia'
import useSelectedAlbums from '../../hooks/useSelectedAlbums'

interface Props {
  anchorEl: null | HTMLElement
  handleClose(): void
}

function MediaDropDownMenu({ anchorEl, handleClose }: Props) {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} keepMounted>
      <MediaMove handleClose={handleClose} />
      <EditImage handleClose={handleClose} />
      <MediaPrintShop />
    </Menu>
  )
}

function AlbumDropDownMenu({ anchorEl, handleClose }: Props) {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} keepMounted>
      <AlbumDetails handleClose={handleClose} />
      <AlbumDownload handleClose={handleClose} />
      <AlbumDelete handleClose={handleClose} />
      <AlbumMove handleClose={handleClose} />
      <PlaySlideshow />
    </Menu>
  )
}

export default function IconDropDownMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const path = useMatch('/bucket/:bucketId/:slug')
  const albumIcons = path?.params.slug === 'albums'
  const { selectedModeEnabled: selectedModeEnabledAlbums } = useSelectedAlbums()
  const { selectedModeEnabled: selectedModeEnabledMedia } = useSelectedMedia()
  const selectedModeEnabled = selectedModeEnabledAlbums || selectedModeEnabledMedia

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!isMobile || !selectedModeEnabled) {
    return null
  }

  return (
    <>
      {!albumIcons && (
        <>
          <MediaDetails />
          <MediaShare />
          <MediaDownload />
          <MediaFavorite />
          <MediaDelete />
        </>
      )}
      <IconButton color="inherit" onClick={handleClick} data-test="appbar-dropdown-toggle" id="appbar-dropdown-toggle" size="large">
        <MoreVert />
      </IconButton>
      {albumIcons ? (
        <AlbumDropDownMenu handleClose={handleClose} anchorEl={anchorEl} />
      ) : (
        <MediaDropDownMenu handleClose={handleClose} anchorEl={anchorEl} />
      )}
    </>
  )
}

import { Grid, Typography } from '@mui/material'
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, signInWithEmailAndPassword } from 'firebase/auth'
import { ChangeEvent, useState } from 'react'
import PasswordVisibilityAdornment from '../../../../../components/forms/PasswordVisibilityAdornment'
import StyledInputLabel from '../../../../../components/forms/StyledInputLabel'
import StyledTextField from '../../../../../components/forms/StyledTextField'
import { IdpFeatureFlag } from '../../../../../config/firebase'
import logger from '../../../../../utils/logger'
import useChangeEmail from '../../../../account/views/settings/hooks/useChangeEmail'
import { StyledFormError } from '../../../../auth/components/StyledFormAlert'
import StyledFormButton from '../../../../auth/components/StyledFormButton'
import useAlerts from '../../../hooks/useAlerts'
import useUserProfileUpdate from '../../../hooks/useUserProfileUpdate'

interface Props {
  goToScreen: (newState: 'REAUTHENTICATE' | 'SUCCESS') => void
  newEmail: string
  password: string
  setPassword: (value: string) => void
}

export type IdpFeatureFlagMap = {
  [featureFlagName in IdpFeatureFlag]: boolean
}

export default function Reauthenticate({ goToScreen, newEmail, password, setPassword }: Readonly<Props>) {
  const { createAlert } = useAlerts()
  const { mutation: changeEmailLegacy } = useChangeEmail()
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const { mutation: userProfileUpdate } = useUserProfileUpdate()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setError('')
  }

  const reauthenticateWithPassword = async () => {
    const user = getAuth().currentUser
    if (!user?.email) {
      createAlert('There was an issue with your session, please logout and try again.')
      return
    }

    setLoading(true)
    const credential = EmailAuthProvider.credential(user.email, password)
    await reauthenticateWithCredential(user, credential)
  }

  const postEmailChange = async () => {
    if (!getAuth().currentUser) {
      createAlert('There was an issue with your session, please logout and try again.')
      return
    }
    await userProfileUpdate({
      variables: { data: { email: newEmail } },
      onError: logger.error,
      onCompleted: () => {
        // Session is killed after email change, so we need to reauthenticate
        signInWithEmailAndPassword(getAuth(), newEmail, password)
        changeEmailLegacy({ variables: { newEmail }, onError: logger.error }).catch(logger.error)
        goToScreen('SUCCESS')
      }
    })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (loading) return
    try {
      await reauthenticateWithPassword()
      await postEmailChange()
    } catch (err) {
      setLoading(false)
      logger.error(err)
      setError('The password you entered is incorrect.')
    }
  }

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Reauthenticate to continue</Typography>
          <Typography variant="h6" sx={{ my: 2 }}>
            Continue with password:
          </Typography>
          {error && <StyledFormError title="Error changing email" body={error} />}

          <StyledInputLabel htmlFor="password">Password</StyledInputLabel>
          <StyledTextField
            id="password"
            data-test="password"
            disabled={loading}
            error={Boolean(error)}
            fullWidth
            helperText={error}
            name="password"
            data-sr-redact
            margin="dense"
            onChange={handleChange}
            required
            type={showPassword ? 'text' : 'password'}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoCapitalize: 'off',
              endAdornment: <PasswordVisibilityAdornment showPassword={showPassword} handleClickShowPassword={handleClickShowPassword} />
            }}
            placeholder=""
          />
          <StyledFormButton type="submit" data-test="submit" color="primary" fullWidth variant="contained" disabled={loading}>
            Continue
          </StyledFormButton>
        </Grid>
      </Grid>
    </form>
  )
}

import { Box, Button, Collapse, LinearProgress, List, Stack, Typography } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { memo, useState } from 'react'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import AutoSizer from 'react-virtualized-auto-sizer'
import useBucket from '../../../hooks/useBucket'
import BucketAlbum from '../components/Album'
import AlbumSubHeader from '../components/AlbumSubHeader'
import BucketAdminActions from '../components/BucketAdminActions'
import BucketMenu from '../components/BucketMenu'
import BucketSwitcher from '../components/BucketSwitcher'
import StaticAlbums from '../components/StaticAlbums'
import UtilizationMenu from '../components/UtilizationMenu'

interface Props {
  bucketId: string
  userId: string
}

const formatCount = (count: number = 0) => {
  switch (count) {
    case 0:
      return 'No images'
    case 1:
      return `1 image`
    default:
      return `${count} images`
  }
}

function DesktopDrawer({ bucketId, userId }: Props) {
  const [isOpen, setIsOpen] = useState(true)
  const { bucket, bucketAlbums, bucketUser, fetchMore, loading, loadingMore } = useBucket()

  const imgCount = formatCount(bucket?.counters?.totalMediaMinusTrash ?? 0)
  const bucketTitle = bucket?.title
  const albums = bucketAlbums?.items || []
  const nextToken = bucketAlbums?.nextToken
  const permissions = bucketUser?.permissions

  const toggle = () => setIsOpen(!isOpen)

  if (!bucketId || !userId) {
    return null
  }

  return (
    <MuiDrawer
      sx={{
        width: 280,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { position: 'static' }
      }}
      variant="permanent"
      PaperProps={{ id: 'album-menu' }}
    >
      <Box sx={{ borderBottom: '1px solid #dfdfdf', padding: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={1} alignItems="flex-start">
              {bucket && <BucketAdminActions bucket={bucket} permissions={permissions} />}
              <Typography component="h3" sx={{ fontSize: '1.25rem', fontWeight: '600', lineHeight: 1.25 }}>
                {bucketTitle}
              </Typography>
            </Stack>
            {!bucket?.isBanned && <Typography variant="caption">{imgCount}</Typography>}
          </div>
          <BucketMenu permissions={permissions} bucketId={bucketId} />
        </Box>
        <BucketSwitcher userId={userId} />
      </Box>
      {!bucket?.isBanned && (
        <div style={{ flex: 1, position: 'relative', overflowY: 'auto', overflowX: 'hidden', padding: '0 10px 14px' }}>
          <AutoSizer>
            {({ height, width }) => (
              <div style={{ height, width, position: 'relative' }} id="scrollable-gallery">
                <Box sx={{ borderBottom: '1px solid #dfdfdf' }}>
                  <StaticAlbums />
                </Box>
                {loading && <LinearProgress />}
                <List sx={{ paddingTop: 0, flex: 'auto' }}>
                  <ReactInfiniteScroll
                    dataLength={albums.length || 0}
                    loader={
                      <Typography variant="h6" align="center">
                        Loading...
                      </Typography>
                    }
                    next={fetchMore}
                    hasMore={Boolean(nextToken)}
                  >
                    {!loading && <AlbumSubHeader isOpen={isOpen} toggle={toggle} hasAlbums={!!albums.length} />}
                    <Collapse in={isOpen}>
                      {albums.map((album) => (
                        <BucketAlbum album={album} key={album.id} bucketId={bucketId} />
                      ))}
                      {Boolean(nextToken) && (
                        <Button disabled={loadingMore} onClick={fetchMore} fullWidth>
                          load more
                        </Button>
                      )}
                    </Collapse>
                  </ReactInfiniteScroll>
                </List>
              </div>
            )}
          </AutoSizer>
        </div>
      )}
      {!bucket?.isBanned && <UtilizationMenu />}
    </MuiDrawer>
  )
}

export default memo(DesktopDrawer)

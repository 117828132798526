import { Chip, InputBase, Tooltip, styled } from '@mui/material'
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { BucketMedia } from '../../../../../graphql/generated'
import useBucketPlan from '../../../hooks/useBucketPlan'

const CustomInput = styled('div')(({ theme }) => ({
  flex: 1,
  borderColor: theme.palette.customGrey[400],
  gap: '4px',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 5,
  padding: '8px 8px',
  marginRight: '8px',
  display: 'flex',
  flexWrap: 'wrap'
}))

interface Props {
  currentImage?: BucketMedia | null
  handleSaveTags: (tags: string[]) => void
}

export default function TagManagement({ handleSaveTags, currentImage }: Props) {
  const { restrictions, tooltipTitle } = useBucketPlan()
  const [userTags, setUserTags] = useState<string[]>([])
  const [newUserTags, setNewUserTags] = useState<string[]>([])
  const [value, setValue] = useState<string | null>(null)

  const restricted = restrictions.userTags
  const hideTooltip = !restricted
  const title = tooltipTitle('userTags')

  useEffect(() => {
    setUserTags(Array.from(currentImage?.userTags ?? []))
  }, [currentImage])

  const handleDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', ' ', ','].includes(event.key)) {
      event.preventDefault()
      setUserTags(newUserTags)
      setValue(null)
      handleSaveTags(newUserTags)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue)
    if (newValue && newValue.trim() && currentImage?.id) {
      const newTagValue = newValue.trim()
      const newTags = [...userTags, newTagValue]
      setNewUserTags(newTags)
    }
  }

  const handleTagDelete = (tag: string) => {
    const newTags = userTags.filter((userTag) => userTag !== tag)
    if (currentImage?.userTags && currentImage.userTags.indexOf(tag) > -1) {
      handleSaveTags(newTags)
    }
    setUserTags(newTags)
  }

  return (
    <div style={{ padding: 16 }}>
      <Tooltip
        PopperProps={{
          disablePortal: hideTooltip
        }}
        disableFocusListener={hideTooltip}
        disableHoverListener={hideTooltip}
        disableTouchListener={hideTooltip}
        title={title}
      >
        <div>
          <CustomInput sx={{ width: { xs: '100%', sm: 'auto' } }}>
            <InputBase
              disabled={restricted}
              value={value || ''}
              placeholder="Add tag..."
              fullWidth
              id={currentImage?.id}
              onKeyDown={handleDown}
              onChange={handleChange}
            />
          </CustomInput>
        </div>
      </Tooltip>
      {!restricted && (
        <div style={{ marginTop: '16px' }}>
          {userTags.map((tag) => (
            <Chip key={tag} color="primary" sx={{ marginLeft: '4px', marginBottom: '4px' }} label={tag} onDelete={() => handleTagDelete(tag)} />
          ))}
        </div>
      )}
    </div>
  )
}

TagManagement.defaultProps = {
  currentImage: undefined
}

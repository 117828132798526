import { makeVar, useMutation, useReactiveVar } from '@apollo/client'
import { BucketMediaUpdateDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

const mediaCellEditTitleId = makeVar<string | null>(null)

export default function useBucketMediaUpdate() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(BucketMediaUpdateDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was a problem updating your media. Please try again later.')
      }
    },
    onCompleted: () => {
      createAlert('Success: Item details have been updated!', 'success')
      logger.info('success')
    }
  })

  const mediaCellId = useReactiveVar(mediaCellEditTitleId)
  const setMediaCellId = (id: string | null) => {
    mediaCellEditTitleId(id)
  }

  return { mediaCellId, mutation, results, setMediaCellId }
}

import { useMutation } from '@apollo/client'
import { UnbanImagesDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

export default function useUnbanImages() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(UnbanImagesDocument, {
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message)
          logger.error(e)
        })
        return
      }
      logger.error(error)
      createAlert('There was a problem un-baning the selected images. Please try again later.')
    },
    onCompleted: () => {
      createAlert('Images have been successfully un-banned.', 'success')
    }
  })

  return { mutation, results }
}

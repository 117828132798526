import { Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LogoMark from '../svg/LogoMark'

interface Props {
  content?: string
}

export default function Loading({ content }: Props) {
  const [counter, setCounter] = useState(0)
  const { mode } = useParams<{ mode: 'slideshow' }>()

  useEffect(() => {
    const interval = setInterval(() => setCounter((state) => state + 1), 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: mode === 'slideshow' ? '#000' : 'transparent',
        height: '100%'
      }}
    >
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: '20px', left: '20px' }}>
          <LogoMark data-testid="loading-logomark" width="40px" />
        </div>
        <CircularProgress size={80} thickness={2} />
      </div>

      {counter === 1 && <Typography style={{ marginTop: '12px' }}>{content || 'Retrieving Site Data'}</Typography>}
      {counter >= 2 && <Typography style={{ marginTop: '12px' }}>Sorry, this is taking longer than normal</Typography>}
    </div>
  )
}

Loading.defaultProps = {
  content: undefined
}

import { ApolloError, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/AddOutlined'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { Box, Button, Collapse, Skeleton, Stack, Tooltip, Typography, styled, useTheme } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import { Architecture, Bucket, BucketType, BucketsByUserIdDocument } from '../../../../../graphql/generated'
import { drawerVar, modalVar } from '../../../../../providers/apollo/cache'
import logger from '../../../../../utils/logger'
import useAlerts from '../../../hooks/useAlerts'
import useProfilePlan from '../../../hooks/useProfilePlan'
import useUserProfileUpdate from '../../../hooks/useUserProfileUpdate'
import useUserSubscription from '../../../hooks/useUserSubcription'
import useLegacyGraph from '../../../../shared/hooks/useLegacyGraph'

interface Props {
  userId: string
}

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1',
  padding: '5px 0',
  color: '#000',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '& .add-bucket-icon': { backgroundColor: theme.palette.primary.main, '& .add-icon': { color: '#fff' } }
  }
}))

const AddBucketIcon = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100px',
  backgroundColor: '#F1F5F9',
  width: '40px',
  height: '40px',
  marginRight: '10px'
}))

export default function BucketSwitcher({ userId }: Readonly<Props>) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { createAlert } = useAlerts()
  const { canCreateBucket } = useProfilePlan()
  const { bucketId } = useParams<{ bucketId: string }>()
  const isManageBuckets = useMatch('buckets')
  const [displayLegacyBucket, setDisplayLegacyBucket] = useState(false)
  const [open, setOpen] = useState(true)
  const { mutation } = useUserProfileUpdate()
  const { isSubscriptionActive } = useUserSubscription()

  const { disableLegacyArchitectureAccess } = useLegacyGraph()

  const disabled = !isSubscriptionActive
  const hideTooltip = !disabled

  const results = useQuery(BucketsByUserIdDocument, {
    variables: { userId },
    onError: (e) => {
      logger.error(e)
      createAlert('We are having trouble retrieving your buckets. Please try again later.')
    },
    onCompleted: (data) => {
      getAuth()
        .currentUser?.getIdTokenResult()
        .then((idTokenResult) => {
          const isConverted = idTokenResult?.claims?.isConverted
          const display = !!data.userProfile?.legacyBucketId && !isConverted
          setDisplayLegacyBucket(display)
        })
    }
  })

  const buckets = results.data?.bucketsByUserId?.items ?? []
  const bucketsWithoutLegacy = buckets.filter((bucket) => bucket.architecture !== Architecture.Kafka)
  const hasLegacyBucket = bucketsWithoutLegacy.length !== buckets.length

  const handleToggle = () => setOpen(!open)

  const openModal = () => {
    drawerVar(null)
    modalVar('createBucket')
  }

  const handleClick = (currentBucketId: string) => () => {
    drawerVar(null)
    mutation({
      variables: { data: { currentBucketId } },
      onCompleted: () => {
        // Todo remove this edge case once buckets are migrated
        if (currentBucketId === userId) {
          window.location.href = `/u/${userId}`
          return
        }

        navigate(`/bucket/${currentBucketId}`)
      },
      onError: (error: ApolloError) => {
        logger.error(error)

        if (currentBucketId === userId) {
          window.location.href = `/u/${userId}`
          return
        }

        navigate(`/bucket/${currentBucketId}`)
      }
    })
  }

  // TODO: delete when deprecating old architecture
  const handleClickLegacy = () => {
    if (!userId) {
      window.location.href = `/u/${userId}`
      return
    }
    handleClick(userId)()
  }

  const getBucketTypes = (bkts: Bucket[]) => {
    const initialBucketTypes = { [BucketType.Group]: false, [BucketType.Mybucket]: false }
    return bkts.reduce((bucketTypes, bucket) => {
      return {
        ...bucketTypes,
        [bucket.bucketType]: true
      }
    }, initialBucketTypes)
  }

  let bucketTypes
  if (buckets) {
    bucketTypes = getBucketTypes(buckets)
  }

  const bucketTotal = displayLegacyBucket ? (buckets?.length ?? 0) + 1 : buckets?.length

  // Do not display the bucket switcher if there is only one bucket
  // If the user is a legacy user, the bucket switcher is not displayed.
  if (
    !bucketTypes || // If no buckets
    (Object.keys(bucketTypes).length === 1 && bucketTypes[BucketType.Mybucket]) ||
    bucketTotal === 1 // If only bucket of any kind
  ) {
    if (canCreateBucket) {
      return (
        <Tooltip
          PopperProps={{
            disablePortal: hideTooltip
          }}
          disableFocusListener={hideTooltip}
          disableHoverListener={hideTooltip}
          disableTouchListener={hideTooltip}
          title="You need to have an active subscription to create a bucket."
        >
          <div>
            <StyledButton data-test="create-bucket" disabled={disabled} onClick={openModal}>
              <AddBucketIcon className="add-bucket-icon">
                <AddIcon className="add-icon" />
              </AddBucketIcon>
              Create New Bucket
            </StyledButton>
          </div>
        </Tooltip>
      )
    }
    return null
  }

  if (!results.called || results.loading) {
    return <Skeleton variant="rectangular" width="100%" height="50px" />
  }

  return (
    <Box sx={{ border: `1px solid ${theme.palette.primary.highlight}`, borderRadius: '8px', padding: '6px 10px 8px' }}>
      <Button
        onClick={handleToggle}
        disableRipple
        data-test="switch-bucket"
        sx={{ padding: 0, display: 'flex', width: '100%', justifyContent: 'space-between', '&:hover': { background: 'transparent' } }}
      >
        <Typography
          sx={{
            fontSize: '0.75rem',
            fontWeight: '600',
            textTransform: 'upperCase',
            color: '#727C8C'
          }}
        >
          Switch Bucket
        </Typography>
        {open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </Button>
      <Collapse in={open}>
        <Stack direction="row" gap="6px" alignItems="flex-start" flexWrap="wrap" sx={{ marginTop: '4px', maxHeight: '200px', overflowY: 'auto' }}>
          {!disableLegacyArchitectureAccess && hasLegacyBucket && (isManageBuckets || bucketId) && (
            <Button
              onClick={handleClickLegacy}
              sx={{
                gap: '2px',
                minWidth: 0,
                padding: '2px 6px',
                border: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.secondary.main,
                justifyContent: 'flex-start',
                '&:hover': {
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText
                }
              }}
            >
              <PersonOutlineOutlinedIcon />
              My Bucket
            </Button>
          )}
          {bucketsWithoutLegacy.map((bucket) => {
            if (bucketId === bucket.id) {
              return null
            }

            return (
              <Button
                onClick={handleClick(bucket.id)}
                data-test={`bucket-switcher-${bucket.id}`}
                key={bucket.id}
                sx={{
                  gap: '2px',
                  minWidth: 0,
                  padding: '2px 6px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: theme.palette.secondary.main,
                  '&:hover': {
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                  }
                }}
              >
                {bucket.bucketType === BucketType.Group ? <GroupsOutlinedIcon /> : <PersonOutlineOutlinedIcon />}
                <Box component="span" sx={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {bucket.title}
                </Box>
              </Button>
            )
          })}
        </Stack>
      </Collapse>
    </Box>
  )
}

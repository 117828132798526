import { Share } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { BucketMedia } from '../../../../../graphql/generated'
import { modalVar } from '../../../../../providers/apollo/cache'
import useSelectedMedia from '../../../hooks/useSelectedMedia'
import useBucketPlan from '../../../hooks/useBucketPlan'

interface Props {
  media?: BucketMedia | null
}
export default function MediaShare({ media }: Props) {
  const { restrictions, tooltipTitle } = useBucketPlan()
  const { selectedMedia } = useSelectedMedia()
  const { bucketId } = useParams<{ bucketId: string }>()
  // bucketId, destinationAlbumId, mediasIds
  const mediaIds: string[] = media ? [media.id] : Array.from(selectedMedia.keys())
  const handleClick = () => {
    if (!bucketId || !mediaIds.length) return
    modalVar('bucketSharingLinkCreate')
  }

  const restricted = restrictions.sharing
  const title = restricted ? tooltipTitle('sharing') : 'Share Media'

  return (
    <Tooltip title={title} arrow>
      <div>
        <IconButton
          color="inherit"
          aria-label="Share Media"
          disabled={restricted || !selectedMedia.size}
          onClick={handleClick}
          id="share-media"
          data-test="share-media"
        >
          <Share />
        </IconButton>
      </div>
    </Tooltip>
  )
}

MediaShare.defaultProps = {
  media: null
}

import * as CryptoJS from 'crypto-js'
import { shareLinkKey } from '../config'

const ENCRYPTION_KEY = shareLinkKey

if (!ENCRYPTION_KEY) {
  throw new Error('Encryption key is not set')
}

/**
 * Decrypts text using AES-256-CBC.
 *
 * @param text - The text to decrypt, consisting of the IV and the encrypted data.
 * @returns The decrypted text.
 */
export default function decrypt(text: string): string {
  if (!text) {
    return ''
  }
  const textParts = text.split(':')
  const iv = CryptoJS.enc.Hex.parse(textParts[0])
  const encryptedText = textParts[1]
  const key = CryptoJS.enc.Base64.parse(ENCRYPTION_KEY)

  const ciphertext = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedText))

  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })

  return decrypted.toString(CryptoJS.enc.Utf8)
}

import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { v4 } from 'uuid'
import App from './App'
import { alertsVar } from './providers/apollo/cache'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import logger from './utils/logger'

interface WorkerEventTarget extends EventTarget {
  state?: string | null
}

interface WorkerEvent extends Event {
  target: WorkerEventTarget | null
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: WorkerEvent) => {
        if (event && event.target?.state === 'activated') {
          alertsVar([{ id: v4(), message: 'Updating Application, Browser will refresh automatically to apply the changes.', severity: 'info' }])
          setTimeout(() => {
            window.location.reload()
          }, 5000)
        }
      })
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
    }
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logger.debug)

import { CookieSetOptions } from 'universal-cookie/es6'
import license from './photoEditor'

/**
 * Application Environemnt
 */

export type Environment = 'stage' | 'development' | 'production'

export const environment: Environment = (process.env.REACT_APP_ENVIRONMENT as Environment | undefined) ?? 'development'

/**
 * API Configuration
 *
 * Currently we have a subdomain for every environment.
 * Therefore we map the current "REACT_APP_ENVIRONMENT" variable to a submdomain
 * Default: stage
 */

const subdomainMap = {
  development: 'dev',
  production: 'app',
  stage: 'stage'
}
const subdomain = subdomainMap[environment]

const baseDomainMap = {
  development: 'dev.photobucket.com',
  production: 'photobucket.com',
  stage: 'stage.photobucket.com'
}
export const baseDomain = baseDomainMap[environment]

const assetsUrl = process.env.REACT_APP_ASSETS ?? `https://content.${baseDomain}`
const assets = {
  url: assetsUrl,
  placeholderThumbnail: `${assetsUrl}/img/placeholder/blockPlaceholder-small.svg`,
  notFoundImage: `${assetsUrl}/img/404_buckethead.jpg`
}

const api = {
  billing: process.env.REACT_BILLING_API ?? `https://billing-api.${baseDomain}`,
  oauth: process.env.REACT_APP_OAUTH ?? `https://auth.${baseDomain}`,
  featureToggles: process.env.REACT_APP_FEATURE_TOGGLES ?? `https://api.${subdomain}.photobucket.com/features`,
  graphql: process.env.REACT_APP_GRAPHQL ?? `https://${subdomain}.photobucket.com/api/graphql/v2`,
  firebaseLogin: process.env.REACT_APP_FIREBASE_LOGIN ?? `https://us-central1-photobucket-${subdomain}.cloudfunctions.net/firebaseAuth`,
  upload: process.env.REACT_APP_UPLOAD ?? `https://${subdomain}.photobucket.com/api/upload`,
  uploadByUrl: process.env.REACT_APP_UPLOAD ?? `https://${subdomain}.photobucket.com/api/upload_by_url`,
  download: process.env.REACT_APP_DOWNLOAD ?? `https://${subdomain}.photobucket.com/api/download`,
  share: `https://api.${baseDomain}/media/share`,
  dtms: `https://${baseDomain}/api/rest/dt`,
  webhooks: `https://webhooks.${baseDomain}`
}

const appUrl = {
  billing: process.env.REACT_APP_BILLING_APP_URL ?? 'https://billing.stage.photobucket.com',
  paypal: process.env.REACT_APP_PAYPAL_APP_URL ?? 'https://www.paypal.com/',
  cancelSubscription: process.env.CANCEL_SUBSCRIPTION ?? 'https://my.photobucket.com/photobucket-cancellation-step-1',
  iTuneCancel: process.env.ITUNES_CANCEL_URL ?? 'https://support.apple.com/en-us/HT202039',
  iTunesChange: process.env.ITUNES_CHANGE_URL ?? 'https://support.apple.com/en-us/HT204939',
  legacy: process.env.REACT_APP_LEGACY_URL ?? `https://${subdomain}.photobucket.com`,
  met:
    process.env.MET_SUPPORT_URL ??
    'https://zendesk.photobucket.com/login/?return_to=https%3A%2F%2Fsupport.photobucket.com%2Fhc%2Fen-us%2Frequests%2Fnew'
}

// This is temporary until we can dix the dns in prod
if (environment === 'production') {
  api.featureToggles = process.env.REACT_APP_FEATURE_TOGGLES ?? ` https://t1pcqg0vci.execute-api.us-east-1.amazonaws.com/v1/features`
}

/**
 * Cookie Configuration
 *
 * Currently we use a cross subdomain cookie.
 * This is required to allow our billing app to have access to the cookie created on our main app
 * but this cross subdomain cookie also makes a cookie created in dev also accessable in stage and prod.
 * Though this may only in pack devs it should be fixed
 *
 * TODO - Fix this
 */

const cookieOptions: CookieSetOptions = {
  httpOnly: false,
  maxAge: 60 * 60 * 24 * 7,
  path: '/',
  sameSite: false,
  domain: window.location.hostname // Explicitly defining this makes the cookie available to subdomains
}

if (window.location.hostname.includes('dev.photobucket.com')) {
  cookieOptions.domain = 'dev.photobucket.com'
} else if (window.location.hostname.includes('stage.photobucket.com')) {
  cookieOptions.domain = 'stage.photobucket.com'
} else if (window.location.hostname.includes('photobucket.com')) {
  cookieOptions.domain = 'photobucket.com'
}

if (window.location.hostname.includes('photobucket.com')) {
  // localhost is not ran on https
  // In the future this will probably only be set for prod but to eliminate confusion this will be set for all pb domains
  cookieOptions.secure = false
}

/**
 * You and I both know this is horrible but its a short term solution to get past the previous management of our cookies and subdomains
 * I guess all hacks started with "its a short term solution". If you're reading this in 2 years I'm sorry. I tried.
 */

export const superUglyHackToClearAllCookies = [
  {
    httpOnly: false,
    maxAge: 60 * 60 * 24 * 7,
    path: '/',
    sameSite: true,
    domain: window.location.hostname
  },
  {
    httpOnly: false,
    maxAge: 60 * 60 * 24 * 7,
    path: '/',
    sameSite: false,
    domain: window.location.hostname
  },
  {
    httpOnly: false,
    maxAge: 60 * 60 * 24 * 7,
    path: '/',
    sameSite: true,
    domain: 'photobucket.com'
  },
  {
    httpOnly: false,
    maxAge: 60 * 60 * 24 * 7,
    path: '/',
    sameSite: false,
    domain: 'photobucket.com'
  },
  {
    httpOnly: false,
    maxAge: 60 * 60 * 24 * 7,
    path: '/',
    sameSite: true,
    domain: 'dev.photobucket.com'
  },
  {
    httpOnly: false,
    maxAge: 60 * 60 * 24 * 7,
    path: '/',
    sameSite: false,
    domain: 'dev.photobucket.com'
  },
  {
    httpOnly: false,
    maxAge: 60 * 60 * 24 * 7,
    path: '/',
    sameSite: true,
    domain: 'stage.photobucket.com'
  },
  {
    httpOnly: false,
    maxAge: 60 * 60 * 24 * 7,
    path: '/',
    sameSite: false,
    domain: 'stage.photobucket.com'
  }
]

const shareLinkKeyMap = {
  development: 'vmtm+xA315hE7AZB1gYqiOUPVvqo+93FRPrt3SgNC68=',
  production: 'qd0xdHuYH4K0o+Af+4R2//oB0HcSsjVcOsxUglK3xaE=',
  stage: 'djrsfRuLgfeVKD6u6rwb/UTae7E5io49DBJVNppIhGE='
}

export const shareLinkKey = shareLinkKeyMap[environment]

/**
 * PayPal Configuration
 */

const paypalMap = {
  development: 'TODO',
  production: 'ATuKt8znuPMAvQIKsdwDKOxNNfgPF12aYAIMYTZjniJLzWgx5CldKK_l24F-So0E60OhYOSuVw67Hj3E',
  stage: 'AUkpBT51jm8dpD_df-UnBYz-6ejOu1QqB-Zau6wCSYNe4rVgG6LrKr38XBWMXBUM-MTjfFygARWdVQQD'
}

export const paypalClientId = paypalMap[environment]

const paddleMap = {
  development: -1,
  production: 157462,
  stage: 8520
}

export const paddleVendorId = paddleMap[environment]

/**
 * Captcha Configuration
 */

const recaptchaMap = {
  development: '6Ld487QZAAAAANIoquvYwOG3-6rkZfln39PkLX2t',
  production: '6Leh87QZAAAAAKlU7ZZNyrUqLWILfyJ5YC6ixomr',
  stage: '6LeW87QZAAAAAFUA1eiEb-73XVxMJOd8IiJAuUF7'
}

const hcaptchaMap = {
  development: 'c2201a9d-0f14-4139-8a86-313b9e9179d7',
  production: '9243ccd2-32fc-4022-8be7-ee5b8db812f3',
  stage: 'e091752c-4c20-4e64-9207-db218b6f665b'
}

const captcha = {
  recaptcha: {
    key: process.env.REACT_APP_RECAPTCHA_KEY ?? recaptchaMap[environment],
    source: 'RECAPTCHA'
  },
  hcaptcha: {
    key: process.env.REACT_APP_HCAPTCHA_KEY ?? hcaptchaMap[environment],
    source: 'HCAPTCHA'
  }
}

/**
 * Unleash config
 */

const clientKeyMap = {
  development: '*:default.b9db4ceda5d8a045ea57fd1d87acc305016d2bbe66ff002ce12dd053',
  production: '*:default.74f7216802bf3212773a2b784de0e4c3a428817cd917792147123893',
  stage: '*:default.8f13016a487fb65bdf6ac6186f9705206cce71df624be2631e684130'
}

const clientKey = clientKeyMap[environment]

const unleash = {
  url: process.env.REACT_APP_UNLEASH_URL ?? `https://${subdomain}.photobucket.com/api/proxy`,
  appName: 'pb-mvp-bff',
  clientKey,
  environment,
  refreshInterval: 30
}

/**
 * S3 config
 */

const s3bucket = {
  development: 'pb-media-development',
  production: 'pb-media-production',
  stage: 'pb-media-stage'
}

export const s3Media = {
  bucket: s3bucket[environment] || 'pb-media-development',
  region: process.env.REACT_APP_AWS_REGION ?? 'us-east-1',
  domain: process.env.REACT_APP_S3_DOMAIN ?? `https://hosting.${baseDomain}`
}

/**
 * Merge all properties to a single config
 */

const config = {
  app: {
    version: process.env.REACT_APP_VERSION ?? '1.0.0',
    name: process.env.REACT_APP_NAME ?? 'photobucket-web'
  },
  api,
  assets,
  captcha,
  cookieOptions,
  siteTitle: 'Photobucket',
  unleash,
  appUrl,
  analytics: {
    gtm: environment === 'production' ? 'GTM-TNHVQV' : 'GTM-PWTBQ4T'
  },
  auth: {
    refreshTokenExpireThresholdHours: process.env.EXPIRE_TOKEN_THRESHOLD_HOURS ?? 24
  }
}

export const photoEditor = { license }

/**
 * To prevent the config from getting to large and complex we will start doing names exports
 */

export const rum = {
  applicationId: process.env.REACT_APP_CLOUDWATCH_ID,
  region: 'us-east-1',
  version: process.env.REACT_APP_VERSION ?? '1.0.0'
}

let sessionSampleRate = Number(process.env.REACT_APP_RUM_SAMPLE_RATE)
if (Number.isNaN(sessionSampleRate) || sessionSampleRate < 0 || sessionSampleRate > 1) {
  sessionSampleRate = 0.25
}

export const rumConfig = {
  allowCookies: true,
  endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
  enableXRay: true,
  guestRoleArn: process.env.REACT_APP_CLOUDWATCH_UNAUTH_ROLE,
  identityPoolId: process.env.REACT_APP_CLOUDWATCH_IDENTITY_POOL,
  sessionSampleRate,
  telemetries: ['performance', 'errors', 'http']
}

export default config

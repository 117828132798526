import { useMutation } from '@apollo/client'
import { BucketInvitationCreateDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

const env = process.env.REACT_APP_ENVIRONMENT
const notProd = env !== 'production'

export default function useBucketInvitationCreate() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(BucketInvitationCreateDocument, {
    onError: (e) => {
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((error) => {
          createAlert(error.message, 'error', error)
          if (error.message.startsWith('Email address is not verified.') && notProd) {
            createAlert(`Open AWS SES (${env}) and register this email as a verified identity.`)
          }
        })
      }

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was a problem creating your invitation. Please try again later.')
      }
    }
  })

  const loading = results?.loading

  return { mutation, results, loading }
}

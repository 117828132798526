/* tslint:disable */
/* eslint-disable */
export enum MembershipView {
  CHANGE_PLANS,
  PLAN_DETAILS
}

export enum PlanView {
  MONTHLY,
  YEARLY
}

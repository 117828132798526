import { useReactiveVar } from '@apollo/client'
import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { BucketType } from '../../../../graphql/generated'
import { modalVar } from '../../../../providers/apollo/cache'
import logger from '../../../../utils/logger'
import useAlerts from '../../hooks/useAlerts'
import useBucket from '../../hooks/useBucket'
import useBucketPlan from '../../hooks/useBucketPlan'
import useBucketUpdate from '../../hooks/useBucketUpdate'

export default function BucketUpgrade() {
  const theme = useTheme()
  const { createAlert } = useAlerts()
  const { isSharingPlan } = useBucketPlan()
  const { mutation } = useBucketUpdate()
  const { bucket, isDefaultTitle } = useBucket()
  const [bucketTitle, setBucketTitle] = useState<string | null>(null)
  const modal = useReactiveVar(modalVar)
  const handleClose = () => modalVar(null)
  const isGroupBucket = bucket?.bucketType === BucketType.Group

  if (!bucket?.id) {
    logger.error('No Bucket Id')
    return (
      <Dialog data-test="invite-people-dialog" open={modal === 'bucketUpgrade'} onClose={handleClose}>
        <DialogTitle data-test="invite-users-title" sx={{ padding: { xs: '10px', sm: '20px 24px' } }}>
          Loading...
        </DialogTitle>
      </Dialog>
    )
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setBucketTitle(inputValue)
  }

  const handleConvertBucket = () => {
    if (isDefaultTitle && !bucketTitle) {
      return
    }

    mutation({
      variables: {
        bucketId: bucket?.id,
        data: { bucketType: BucketType.Group, title: bucketTitle }
      },
      onCompleted: () => {
        modalVar('bucketInvite')
        if (!isGroupBucket) {
          createAlert('Successfully converted to group bucket.', 'success')
        }
      }
    })

    setBucketTitle(null)
  }

  const handleUpgradePlan = () => {
    window.location.href = '/settings#membership'
  }

  if (isSharingPlan) {
    return (
      <Dialog data-test="invite-people-dialog" open={modal === 'bucketUpgrade'} onClose={handleClose}>
        <DialogTitle data-test="invite-users-title" sx={{ padding: { xs: '10px', sm: '20px 24px' } }}>
          Invite People
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: { xs: '7px', sm: '21px' },
            top: { xs: '7px', sm: '17px' },
            color: theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
        <DialogContent sx={{ padding: { xs: '20px 10px', sm: '20px 24px' } }}>
          {!isGroupBucket && (
            <Typography mb={1}>You need to convert your bucket from a personal bucket to a group bucket to invite people.</Typography>
          )}
          {isDefaultTitle && (
            <Box>
              <DialogContentText id="conversion-dialog-text" style={{ color: 'black', marginBottom: '10px' }}>
                Select a great name for the bucket to ensure all your collaborators understand what it&apos;s for.
              </DialogContentText>

              <TextField
                name="rename-bucket-title-field-name"
                label="Bucket Name"
                fullWidth
                required
                autoComplete="off"
                id="rename-bucket-title"
                onChange={handleChange}
                value={bucketTitle}
                placeholder="Wedding, Soccer Team, Work Retreat"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ mb: 1, mx: 1 }}>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConvertBucket}>
            {/* Copy to match RN language */}
            {isGroupBucket ? 'Continue' : 'Convert to Group Bucket'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Dialog data-test="invite-people-dialog" open={modal === 'bucketUpgrade'} onClose={handleClose}>
      <DialogTitle data-test="invite-users-title" sx={{ padding: { xs: '10px', sm: '20px 24px' } }}>
        Invite People
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: { xs: '7px', sm: '21px' },
          top: { xs: '7px', sm: '17px' },
          color: theme.palette.grey[500]
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ padding: { xs: '20px 10px', sm: '20px 24px' } }}>
        <Typography>You need to upgrade your bucket to invite new members.</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleUpgradePlan}>
          Upgrade Plan
        </Button>
      </DialogActions>
    </Dialog>
  )
}

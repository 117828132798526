import { useReactiveVar } from '@apollo/client'
import { Close } from '@mui/icons-material'
import { IconButton, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { modalVar } from '../../../../providers/apollo/cache'
import CopyLink from '../invite/CopyLinkButtons'
import EmailLinkForm from '../invite/EmailLinkForm'

export default function InvitationCreateModal() {
  const theme = useTheme()
  const modal = useReactiveVar(modalVar)
  const handleClose = () => {
    modalVar(null)
  }

  return (
    <Dialog data-test="invite-people-dialog" open={modal === 'bucketInvite'} onClose={handleClose}>
      <DialogTitle data-test="invite-users-title" sx={{ padding: { xs: '10px', sm: '20px 24px' } }}>
        Invite People
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: { xs: '7px', sm: '21px' },
          top: { xs: '7px', sm: '17px' },
          color: theme.palette.grey[500]
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ padding: { xs: '20px 10px', sm: '20px 24px' } }}>
        <CopyLink />
        <Divider color={theme.palette.customGrey[400]} sx={{ mt: 1, fontSize: '12px', color: theme.palette.customGrey[400], marginBottom: '20px' }}>
          OR
        </Divider>
        <EmailLinkForm />
      </DialogContent>
    </Dialog>
  )
}

import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import Pendo from './components/Pendo'
import theme from './config/theme'
import './expanded-theme'
import './global.css'
import './initFirebase'
import AdminProvider from './providers/AdminProvider'
import ErrorBoundaryProvider from './providers/ErrorBoundary'
import FeatureTogglesProvider from './providers/FeatureToggles'
import FirebaseBlockingProvider from './providers/FirebaseProvider'
import GoogleTagManagerProvider from './providers/GoogleTagManagerProvider'
import ApolloProvider from './providers/apollo/Provider'
import RumProvider from './providers/rum/RumProvider'
import Router from './views/Router'

declare global {
  interface Window {
    AppleID: AppleSignInAPI.AppleID
    dataLayer?: Record<string, any>[]
  }
}

export default function App() {
  return (
    <GoogleTagManagerProvider>
      <ErrorBoundaryProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <FeatureTogglesProvider>
                <RumProvider>
                  <FirebaseBlockingProvider>
                    <ApolloProvider>
                      <AdminProvider>
                        <Pendo />
                        <CssBaseline />
                        <Router />
                      </AdminProvider>
                    </ApolloProvider>
                  </FirebaseBlockingProvider>
                </RumProvider>
              </FeatureTogglesProvider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </ErrorBoundaryProvider>
    </GoogleTagManagerProvider>
  )
}

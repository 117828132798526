import { useQuery, useReactiveVar } from '@apollo/client'
import { ArrowForward } from '@mui/icons-material'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ListItemButton, ListItemText } from '@mui/material'
import { debounce } from 'lodash'
import { isMobile } from 'mobile-device-detect'
import { useState } from 'react'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import {
  BucketAlbumFragmentFragmentDoc,
  BucketAlbumsDocument,
  BucketFragmentFragmentDoc,
  BucketMediaByAlbumIdDocument,
  BucketMediaFragmentFragmentDoc,
  BucketMediaStatus
} from '../../../../graphql/generated'
import { activeAlbumIdVar, drawerVar } from '../../../../providers/apollo/cache'
import logger from '../../../../utils/logger'
import useAlerts from '../../hooks/useAlerts'
import useBucketGallerySettings from '../../hooks/useBucketGallerySettings'
import useBucketMediaMove from '../../hooks/useBucketMediaMove'
import useSelectedMedia from '../../hooks/useSelectedMedia'
import AlbumSubHeader from '../drawer/components/AlbumSubHeader'
import SelectAlbum from './components/SelectAlbum'
import theme from '../../../../config/theme'

export default function MediaRestoreDrawer() {
  const { selectedMedia, clearSelectedMedia } = useSelectedMedia()
  const { settings } = useBucketGallerySettings()
  const { filterBy, sortBy } = settings
  const { bucketId } = useParams<{ bucketId: string }>()
  const drawer = useReactiveVar(drawerVar)
  const mediaIds = Array.from(selectedMedia.keys())
  const { createAlert } = useAlerts()
  const { mutation } = useBucketMediaMove()
  const [destinationAlbumId, setDestinationAlbumId] = useState<string | null>(null)
  const [selected, setSelected] = useState(false)

  const results = useQuery(BucketAlbumsDocument, {
    skip: !bucketId,
    variables: { bucketId: bucketId! },
    onError: (e) => {
      e.graphQLErrors.forEach((err) => {
        createAlert(err.message)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('We are having trouble retrieving your albums. Please try again later.')
      }
    }
  })
  const albums = results.data?.bucketAlbums?.items || []
  const nextToken = results.data?.bucketAlbums?.nextToken

  const func = () => {
    if (nextToken) {
      results.fetchMore({ variables: { nextToken } })
    }
  }
  const fetchMore = debounce(func, 500, { leading: true })

  const handleClose = () => {
    activeAlbumIdVar(null)
    drawerVar(null)
  }

  const handleClick = () => {
    if (destinationAlbumId) {
      setDestinationAlbumId(null)
    }

    setSelected(!selected)
  }

  const handleMove = () => {
    if (!bucketId) {
      return
    }

    mutation({
      variables: { bucketId, mediaIds, data: { destinationAlbumId } },
      update: (cache, { data }) => {
        if (!data?.bucketMediaMove) return

        data.bucketMediaMove?.forEach((id) => {
          // Retrieve current media
          const media = cache.readFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc })

          // Modify bucket media count
          cache.updateFragment(
            {
              id: `Bucket:${bucketId}`,
              fragment: BucketFragmentFragmentDoc
            },
            (current) => {
              if (!current) return null

              const totalMediaMinusTrash = current.counters?.totalMediaMinusTrash ?? 0
              const totalMediaInTrash = current?.counters?.totalMediaInTrash ?? 0
              const fileSize = media?.fileSize ?? 0
              const totalSizeInTrash = current?.counters?.totalSizeInTrash ?? 0
              const totalSizeMinusTrash = current?.counters?.totalSizeMinusTrash ?? 0

              return {
                ...current,
                counters: {
                  ...current.counters,
                  totalMediaMinusTrash: totalMediaMinusTrash + 1,
                  totalSizeMinusTrash: totalSizeMinusTrash + fileSize,
                  totalMediaInTrash: totalMediaInTrash - 1,
                  totalSizeInTrash: totalSizeInTrash - fileSize
                }
              }
            }
          )

          // Remove media from trash
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                filterBy: { status: BucketMediaStatus.Trash },
                sortBy: null
              }
            },
            (cachedData) => {
              if (!media || !cachedData) return null
              const { bucketMediaByAlbumId } = cachedData
              const currentItems = bucketMediaByAlbumId?.items || []
              const items = currentItems.filter((item) => item.id !== media.id)

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items: [...items]
                }
              }
            }
          )

          // Move media from trash to root bucket
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                filterBy,
                sortBy
              }
            },
            (cachedData) => {
              if (!media || !cachedData) return null

              const { bucketMediaByAlbumId } = cachedData
              const items = bucketMediaByAlbumId?.items || []

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items: [media, ...items]
                }
              }
            }
          )

          if (!destinationAlbumId) {
            return
          }

          // Add media from trash to destination album
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                albumId: destinationAlbumId,
                filterBy,
                sortBy
              }
            },
            (cachedData) => {
              if (!media || !cachedData) return null

              const { bucketMediaByAlbumId } = cachedData
              const items = bucketMediaByAlbumId?.items || []

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items: [media, ...items]
                }
              }
            }
          )

          // Updated album id on media
          cache.updateFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc }, (current) => {
            if (!current) return null
            return {
              ...current,
              albumId: destinationAlbumId
            }
          })

          // Update destination album media count
          cache.updateFragment(
            {
              id: `BucketAlbum:${destinationAlbumId}`,
              fragment: BucketAlbumFragmentFragmentDoc
            },
            (current) => {
              if (!current) return null

              const totalMedia = (current?.counters?.totalMedia ?? 0) + 1

              return {
                ...current,
                counters: {
                  ...current.counters,
                  totalMedia
                }
              }
            }
          )
        })

        cache.gc()
      },
      onCompleted: () => {
        createAlert('Media Restored successfully', 'success')
        setSelected(false)
        clearSelectedMedia()
        handleClose()
      }
    })
  }

  return (
    <Drawer
      anchor="right"
      open={drawer === 'bucketMediaRestore'}
      onClose={handleClose}
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? undefined : 360
        }
      }}
      elevation={0}
      variant={isMobile ? 'temporary' : 'persistent'}
    >
      <Toolbar>
        <Typography sx={{ fontSize: '18px', color: '#081230', flex: 1 }} data-test="album-details-drawer">
          Move Media to Album
        </Typography>
        <IconButton onClick={handleClose} data-test="close" size="large">
          <Close />
        </IconButton>
      </Toolbar>
      <Box sx={{ padding: 2 }}>
        <List data-test="album">
          <AlbumSubHeader />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '5px',
              backgroundColor: selected ? theme.palette.primary.main : theme.palette.primary.highlight,
              borderRadius: selected ? '4px' : 0
            }}
          >
            <ListItemButton
              divider
              data-test="trash-restore-all-items"
              sx={{
                paddingLeft: '5px',
                paddingRight: 0,
                border: 'none',
                '&:hover': {
                  background: 'transparent',
                  color: theme.palette.primary.main
                }
              }}
              disableRipple
              onClick={handleClick}
            >
              <ListItemText
                sx={{ color: selected ? '#fff' : 'inherit' }}
                primaryTypographyProps={{
                  sx: { fontWeight: selected ? 'bold' : 'normal' }
                }}
                primary="All Items"
              />
            </ListItemButton>
          </Box>
          <ReactInfiniteScroll
            dataLength={albums.length || 0}
            loader={
              <Typography variant="h6" align="center">
                Loading...
              </Typography>
            }
            next={fetchMore}
            hasMore={Boolean(nextToken)}
          >
            {albums.map((album) => (
              <SelectAlbum
                key={album.id}
                data-test={`trash-restore-album-${album.id}`}
                allItems={selected}
                setAllItems={setSelected}
                album={album}
                selectedAlbumId={destinationAlbumId}
                setSelectedAlbumId={setDestinationAlbumId}
              />
            ))}
          </ReactInfiniteScroll>
        </List>
        {(selected || destinationAlbumId) && (
          <Button data-test="trash-restore-move" onClick={handleMove} variant="outlined" color="primary" fullWidth>
            Move
            <ArrowForward sx={{ paddingLeft: '5px' }} />
          </Button>
        )}
      </Box>
    </Drawer>
  )
}

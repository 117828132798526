import { useMutation } from '@apollo/client'
import { UserProfileUpdateDocument } from '../../../graphql/generated'
import useAlerts from './useAlerts'
import logger from '../../../utils/logger'

export default function useUserProfileUpdate() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(UserProfileUpdateDocument, {
    onCompleted: () => createAlert('Your profile settings have been updated', 'success'),
    onError: (error) => {
      logger.error('useUserProfileUpdate', { error })
    }
  })

  const { loading } = results
  const success = Boolean(results.data)
  return { loading, mutation, success }
}

import { useReactiveVar } from '@apollo/client'
import { AlertColor } from '@mui/material'
import { v4 } from 'uuid'
import { alertsVar } from '../../../providers/apollo/cache'

export default function useAlerts() {
  const alerts = useReactiveVar(alertsVar)

  const createAlert = (message: string, severity: AlertColor = 'error') => {
    const alert = { id: v4(), message, severity }
    alertsVar([...alerts, alert])
  }

  return { createAlert }
}

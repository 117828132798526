import { useReactiveVar } from '@apollo/client'
import Close from '@mui/icons-material/Close'
import { Tab, Tabs, styled } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { isMobile } from 'mobile-device-detect'
import { useState } from 'react'
import { activeAlbumIdVar, drawerVar } from '../../../../providers/apollo/cache'
import useBucket from '../../hooks/useBucket'
import MediaCopyMoveToAnotherBucket from './components/MediaCopyMoveToAnotherBucket'
import MediaMoveWithInBucket from './components/MediaMoveWithInBucket'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}

const StyledTab = styled(Tab)({ textTransform: 'none', width: isMobile ? undefined : 180 })

function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null
}

const BUCKETS = 'buckets'
const ALBUMS = 'albums'

export default function MediaMoveDrawer() {
  const { bucket } = useBucket()
  const drawer = useReactiveVar(drawerVar)
  const [tab, setTab] = useState(ALBUMS)

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const handleClose = () => {
    activeAlbumIdVar(null)
    drawerVar(null)
  }

  return (
    <Drawer
      anchor="right"
      open={drawer === 'bucketMediaMove'}
      onClose={handleClose}
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? undefined : 360
        }
      }}
      elevation={0}
      variant={isMobile ? 'temporary' : 'persistent'}
    >
      <Toolbar>
        <Typography sx={{ fontSize: '18px', color: '#081230', flex: 1, fontWeight: 500 }} data-test="album-details-drawer">
          Organize Media
        </Typography>
        <IconButton onClick={handleClose} data-test="close" size="large">
          <Close />
        </IconButton>
      </Toolbar>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" allowScrollButtonsMobile scrollButtons={false} variant="scrollable">
          <StyledTab iconPosition="start" label={bucket?.title ?? 'Not Available'} value={ALBUMS} />
          <StyledTab iconPosition="start" label="Other Buckets" value={BUCKETS} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={ALBUMS}>
        <MediaMoveWithInBucket />
      </TabPanel>
      <TabPanel value={tab} index={BUCKETS}>
        <MediaCopyMoveToAnotherBucket />
      </TabPanel>
    </Drawer>
  )
}

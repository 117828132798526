import { useMutation } from '@apollo/client'
import { BucketAlbumUpdateDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

export default function useBucketAlbumUpdate() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(BucketAlbumUpdateDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        switch (error.extensions?.code) {
          default:
            createAlert(error.message, 'error', error)
        }
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was a problem moving your album. Please try again later.')
      }
    },
    onCompleted: () => {
      createAlert('Success: Album updated!', 'success')
      logger.info('success')
    }
  })

  return { mutation, results }
}

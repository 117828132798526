import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'
import { MenuItem } from '@mui/material'
import { useParams } from 'react-router-dom'
import { BucketMedia } from '../../../../../graphql/generated'
import { drawerVar } from '../../../../../providers/apollo/cache'
import useSelectedMedia from '../../../hooks/useSelectedMedia'

interface Props {
  media?: BucketMedia | null
  handleClose: () => void
}
export default function MediaMove({ media, handleClose }: Props) {
  const { selectedMedia } = useSelectedMedia()
  const { bucketId } = useParams<{ bucketId: string }>()
  // bucketId, destinationAlbumId, mediasIds
  const mediaIds: string[] = media ? [media.id] : Array.from(selectedMedia.keys())
  const handleClick = () => {
    if (!bucketId || !mediaIds.length) return
    drawerVar('bucketMediaMove')
    handleClose()
  }

  return (
    <MenuItem
      color="inherit"
      aria-label="Move Media"
      disabled={!selectedMedia.size}
      onClick={handleClick}
      id="move-media-menu-item"
      data-test="move-media-menu-item"
    >
      <DriveFileMoveOutlinedIcon style={{ marginRight: 8 }} /> Move to Album
    </MenuItem>
  )
}

MediaMove.defaultProps = {
  media: null
}

import { useQuery } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { useEffect } from 'react'
import config from '../config'
import { BucketsByUserIdDocument, BucketType } from '../graphql/generated'
import logger from '../utils/logger'
/*
declare global {
  interface Window {
    pendo: any
  }
}
*/
export default function Pendo() {
  const { currentUser } = getAuth()
  const results = useQuery(BucketsByUserIdDocument, {
    skip: !currentUser?.uid,
    variables: { userId: currentUser?.uid! },
    onError: (e) => {
      logger.error(e)
    }
  })
  /**
  useEffect(() => {
    const o = window.pendo || {}
    o._q = o._q || []

    function func(m: string, a: 'unshift' | 'push') {
      const s = [].slice.call(arguments, 0)
      const d = [m].concat(s)
      o._q[a](d)
    }

    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
    const x = v.length
    let w = 0

    while (w < x) {
      const m = v[w]
      const a = m === v[0] ? 'unshift' : 'push'
      o[m] = o[m] ?? func(m, a)
      w += 1
    }
    const y = document.createElement('script')
    y.async = !0
    y.src = 'https://content.guide.photobucket.com/agent/static/252c2f77-b8c3-4003-6712-bd8e02da315b/pendo.js'
    const z = document.getElementsByTagName('script')[0]
    z.parentNode?.insertBefore(y, z)
  }, [])
 */
  useEffect(() => {
    if (!results.data?.userProfile) return
    const newProfile = results.data.userProfile
    const hasGroupBuckets = results.data.bucketsByUserId?.items?.some((bucket) => bucket?.bucketType === BucketType.Group)
    const isBucketOwner = !!(hasGroupBuckets && results.data.bucketsByUserId?.items?.some((bucket) => bucket?.ownerId === currentUser?.uid))
    const isBucketMemberOnly = !!(hasGroupBuckets && !results.data.bucketsByUserId?.items?.some((bucket) => bucket?.ownerId === currentUser?.uid))

    const isConverted = results.data.bucketsByUserId?.items?.some((bucket) => bucket?.bucketType === BucketType.Mybucket && bucket?.isConverted)

    try {
      pendo?.initialize({
        visitor: {
          id: currentUser?.uid as string,
          email: currentUser?.email ?? '',
          firstName: newProfile.firstName as string,
          lastName: newProfile.lastName as string,
          sex: newProfile.gender as string,
          country: newProfile.country as string,
          zipcode: newProfile.zipCode as string,
          version: config.app.version
        },
        account: {
          id: newProfile.id,
          email: currentUser?.email ?? '',
          emailStatus: currentUser?.emailVerified as boolean,
          planId: newProfile.plan?.plan_name as string,
          mediaCountUsed: newProfile.counters?.totalMedia as number,
          storageSpaceUsed: newProfile.counters?.totalSize as number,
          mediaCountLimit: newProfile.plan?.media_limit as number,
          storageSpaceLimit: newProfile.plan?.storage_limit as number,
          isTrial: newProfile.subscription?.isTrial as boolean,
          trailExpDate: newProfile.subscription?.trialExpDate as string,
          planFirstBillingDate: newProfile.subscription?.firstBillingDate as string,
          planLastBillingDate: newProfile.subscription?.lastBillingDate as string,
          planExpirationDate: newProfile.subscription?.expirationDate as string,
          planRecurring: newProfile.subscription?.recurring as boolean,
          firstName: newProfile.firstName as string,
          lastName: newProfile.lastName as string,
          country: newProfile.country as string,
          sex: newProfile.gender as string,
          zip: newProfile.zipCode as string,
          stateOrProvince: newProfile.state as string,
          signupDate: newProfile.createdAt as string,
          subscriptionStatus: newProfile.subscription?.subscription?.status as string,
          recurring: newProfile.subscription?.recurring as boolean,
          totalImagesCount: newProfile.counters?.totalMedia as number,
          totalUserUsedSpace: newProfile.counters?.totalSize as number,
          isGroupBucketOwner: isBucketOwner as boolean,
          isGroupBucketMemberOnly: isBucketMemberOnly as boolean,
          application: 'Next',
          isConverted: isConverted as boolean
        }
      })
    } catch (error) {
      logger.error(error)
    }
  }, [currentUser?.email, currentUser?.uid, currentUser?.emailVerified, results])

  return null
}

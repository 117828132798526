import { makeVar, useLazyQuery, useReactiveVar } from '@apollo/client'
import { BucketCredentials, BucketCredentialsDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

// Do not export this. This is being temporarily placed here until we can deprecate the other selectedMediaVar
const bucketCredentialsVar = makeVar<BucketCredentials | null>(null)

export default function useBucketCredentials() {
  const { createAlert } = useAlerts()
  const credentials = useReactiveVar(bucketCredentialsVar)
  const [query, results] = useLazyQuery(BucketCredentialsDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was a problem retriving your bucket credentials. Please try again later.')
      }
    },
    onCompleted: ({ bucketCredentials }) => {
      if (!bucketCredentials) return
      bucketCredentialsVar(bucketCredentials)
    }
  })

  return { credentials, query, results }
}

import { NewAccountLayout } from './components/Layout'
import Confirm from './components/confirmation/Confirmation'

export default function ConfirmEmail() {
  return (
    <NewAccountLayout>
      <Confirm />
    </NewAccountLayout>
  )
}

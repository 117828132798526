import { FirebaseError } from '@firebase/util'
import { browserLocalPersistence, FacebookAuthProvider, GoogleAuthProvider, OAuthCredential } from 'firebase/auth'
import { merge } from 'lodash'
import { isMobile } from 'mobile-device-detect'
import { Environment } from '.'
import AppleLogo from '../svg/AppleLogo'
import FacebookLogo, { Dimensions } from '../svg/FacebookLogo'
import GoogleLogo from '../svg/GoogleLogo'
import MicrosoftLogo from '../svg/MicrosoftLogo'
import PaypalLogo from '../svg/PaypalLogo'
import AppleAuthProvider from './idps/apple'
import MicrosoftAuthProvider from './idps/microsoft'
import PaypalAuthProvider from './idps/paypal'

export const persistance = browserLocalPersistence
export const firebaseDebugToken = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN
const environment: Environment = (process.env.REACT_APP_ENVIRONMENT as Environment | undefined) || 'development'

// The auth domain should be assumed to be the hostname any time the `window.location.hostname` includes photobucket.com.
// However, when running the application on amplify, or localhost, we need to default the authDomain to
// the handlers in their s3 bucket.
// A redirect is handled in CloudFront using the "/__/auth/" rule, but this does not exist for the amplify branches, nor localhost.
// Read: https://firebase.google.com/docs/auth/web/third-party-storage-mitigation
const useDefaultAuthDomain = window.location.hostname === 'localhost' || window.location.hostname.includes('d12u1568a9pzoe.amplifyapp.com')

const firebaseConfigMap = {
  development: {
    apiKey: 'AIzaSyDF_P6QAxIliU9J9nHjgo7Vmu-XQ1rAeK0',
    authDomain: 'dev.photobucket.com',
    projectId: 'photobucket-dev',
    storageBucket: 'photobucket-dev.appspot.com',
    messagingSenderId: '608801390226',
    appId: '1:608801390226:web:0b66500505cd1cb2b8704b',
    measurementId: 'G-F5GTYGS5Z9'
  },
  stage: {
    apiKey: 'AIzaSyCpBq9BkBHZr8Xk0yssd3aEP0o-V9DwiXg',
    authDomain: useDefaultAuthDomain ? 'stage.photobucket.com' : window.location.hostname || 'stage.photobucket.com',
    projectId: 'photobucket-stage',
    storageBucket: 'photobucket-stage.appspot.com',
    messagingSenderId: '869364698262',
    appId: '1:869364698262:web:e04a219a0a8e3d88f406b7',
    measurementId: 'G-X8VN4BS3YY'
  },
  production: {
    apiKey: 'AIzaSyBlrfvTHm4y-yxxzXmZGt7n8TRijnxA0qo',
    authDomain: useDefaultAuthDomain ? 'photobucket.com' : window.location.hostname || 'photobucket.com',
    projectId: 'photobucket-mobile-apps',
    storageBucket: 'photobucket-mobile-apps.appspot.com',
    messagingSenderId: '949276031942',
    appId: '1:949276031942:web:b11120820e81aa325350c7',
    measurementId: 'G-FJ5F3ESPKH'
  }
}
export const firebaseConfig = firebaseConfigMap[environment]

export type LoginProvider = AppleAuthProvider | FacebookAuthProvider | GoogleAuthProvider

interface Scopes {
  [providerId: string]: string[]
}

/**
 * Defines the scope requested for each of the OAuth providers
 *
 * @see Scope Documentation - https://oauth.net/2/scope/
 * @see Apple - https://developer.apple.com/documentation/sign_in_with_apple/clientconfigi/3230955-scope
 * @see Google - https://developers.google.com/identity/protocols/oauth2/scopes
 * @see Facebook - https://developers.facebook.com/docs/permissions/reference
 */
const scopeMap: Scopes = {
  [AppleAuthProvider.PROVIDER_ID]: ['name', 'email'],
  [GoogleAuthProvider.PROVIDER_ID]: [
    'https://www.googleapis.com/auth/userinfo.email', // email
    'https://www.googleapis.com/auth/userinfo.profile' // profile
  ],
  [FacebookAuthProvider.PROVIDER_ID]: ['email', 'public_profile']
}

const withScope = (provider: LoginProvider): LoginProvider => {
  const scopes = scopeMap[provider.providerId]
  const scope = scopes.join(' ')
  provider.addScope(scope)

  return provider
}

export type IdpFeatureFlag =
  | 'isFirebaseAppleIdpEnabled'
  | 'isFirebaseGoogleIdpEnabled'
  | 'isFirebaseFacebookIdpEnabled'
  | 'isFirebaseMicrosoftIdpEnabled'
  | 'isFirebasePaypalIdpEnabled'

interface SSOLoginProviders {
  [providerId: string]: {
    getCredentialsFromError: (error: FirebaseError) => OAuthCredential | null
    featureFlagName: IdpFeatureFlag
    LogoSvg: (dimensions: Dimensions) => JSX.Element
    getProvider: () => LoginProvider
    name: string
  }
}

export type ProviderId = typeof AppleAuthProvider.PROVIDER_ID | typeof GoogleAuthProvider.PROVIDER_ID

/**
 * SSO providers, email should not be included here
 */
const ssoLoginProviders: SSOLoginProviders = {
  [AppleAuthProvider.PROVIDER_ID]: {
    getCredentialsFromError: AppleAuthProvider.credentialFromError,
    featureFlagName: 'isFirebaseAppleIdpEnabled',
    LogoSvg: AppleLogo,
    getProvider: () => withScope(new AppleAuthProvider()),
    name: 'apple'
  },
  [FacebookAuthProvider.PROVIDER_ID]: {
    getCredentialsFromError: FacebookAuthProvider.credentialFromError,
    featureFlagName: 'isFirebaseFacebookIdpEnabled',
    LogoSvg: FacebookLogo,
    getProvider: () => withScope(new FacebookAuthProvider()),
    name: 'facebook'
  },
  [GoogleAuthProvider.PROVIDER_ID]: {
    getCredentialsFromError: GoogleAuthProvider.credentialFromError,
    featureFlagName: 'isFirebaseGoogleIdpEnabled',
    LogoSvg: GoogleLogo,
    getProvider: () => withScope(new GoogleAuthProvider()),
    name: 'google'
  },
  [MicrosoftAuthProvider.PROVIDER_ID]: {
    getCredentialsFromError: MicrosoftAuthProvider.credentialFromError,
    featureFlagName: 'isFirebaseMicrosoftIdpEnabled',
    LogoSvg: MicrosoftLogo,
    getProvider: () => new MicrosoftAuthProvider(),
    name: 'microsoft'
  },
  [PaypalAuthProvider.PROVIDER_ID]: {
    getCredentialsFromError: PaypalAuthProvider.credentialFromError,
    featureFlagName: 'isFirebasePaypalIdpEnabled',
    LogoSvg: PaypalLogo,
    getProvider: () => new PaypalAuthProvider(),
    name: 'paypal'
  }
}

export type IdpFeatureFlagMap = {
  [featureFlagName in IdpFeatureFlag]: boolean
}

export const supportedLoginProviderIds = Object.keys(ssoLoginProviders)

export const getLoginProviderConfig = (providerId: string) => {
  return ssoLoginProviders[providerId]
}

export const isValidLoginProvider = (providerId: string) => {
  return providerId in ssoLoginProviders
}

/**
 * Apple sso related configs
 */
const appleClientIdMap = {
  development: 'photobucket.web.dev',
  stage: 'photobucket.web.stage',
  production: 'photobucket.web.prod'
}

const defaultAppUrlMap = {
  development: 'https://dev.photobucket.com',
  stage: 'https://stage.photobucket.com',
  production: 'https://photobucket.com'
}

const appleIdConfig = {
  clientId: appleClientIdMap[environment],
  redirectURI: useDefaultAuthDomain ? `${defaultAppUrlMap[environment]}/apple/saml` : `${window.location.origin}/apple/saml`,
  scope: 'email name',
  usePopup: !isMobile
}

interface AppleSignInState {
  component: string
  uid?: string
}
export type ComponentWithAppleId = 'login' | 'register' | 'update-password' | 'link-account'
export const generateAppleIdConfig = (state: AppleSignInState) => merge({ ...appleIdConfig, state: JSON.stringify(state) })

import { useSearchParams } from 'react-router-dom'

export default function useCheckoutParams() {
  const [params] = useSearchParams()

  const isTrial = true
  const planName = params.get('plan_name') ?? params.get('id')
  const billingCycle = params.get('billing_cycle') ?? params.get('type')

  const generatePurchaseLink = (_planName?: string, _billingCycle?: string) => {
    const pn = _planName ?? planName
    const bc = _billingCycle ?? billingCycle
    return `/subscription/purchase?plan_name=${pn}&billing_cycle=${bc}`
  }

  const generatePaymentMethodLink = (_planName?: string, _billingCycle?: string) => {
    const pn = _planName ?? planName
    const bc = _billingCycle ?? billingCycle
    return `/subscription/payment-method?plan_name=${pn}&billing_cycle=${bc}`
  }

  const generatePlansLink = (_planName?: string, _billingCycle?: string) => {
    const pn = _planName ?? planName
    const bc = _billingCycle ?? billingCycle
    return `/subscription/select-plan?plan_name=${pn}&billing_cycle=${bc}`
  }

  return { planName, billingCycle, isTrial, generatePaymentMethodLink, generatePlansLink, generatePurchaseLink }
}

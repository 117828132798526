import { useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { Image } from '../../../../../graphql/generated'
import { selectedMediaVar, selectModeEnabledVar } from '../../../../../providers/apollo/cache'
import logger from '../../../../../utils/logger'

interface SelectMedia {
  isGroupSelected: (id: string[]) => boolean
  selectGroup: (id: string[]) => void
  isSelected: (id: string) => boolean
  toggleSelect: (id: string) => void
  unselectGroup: (id: string[]) => void
  deselectAll: () => void
  unselect: (id: string) => void
  isEmpty: () => boolean
  selectedMedia: string[]
  toggleBulkSelect: (id: string, items: Image[]) => void
}

export default function useSelectMedia(): SelectMedia {
  const selectedMedia = useReactiveVar<string[]>(selectedMediaVar)

  const isSelected = (id: string) => {
    logger.debug('Check if Selected triggered %s', id)
    return _.includes(selectedMedia, id)
  }

  const isGroupSelected = (ids: string[]) => {
    return _.difference(ids, selectedMedia).length === 0
  }

  const select = (id: string) => {
    if (!isSelected(id)) {
      logger.debug('Select triggered %s', id)
      selectedMedia.push(id)
    }
  }

  const unselect = (id: string) => {
    if (isSelected(id)) {
      logger.debug('Unselect triggered %s', id)
      _.remove(selectedMedia, (itemId) => itemId === id)
    }
  }

  const unselectGroup = (ids: string[]) => {
    ids.forEach((id) => unselect(id))
    selectedMediaVar([...selectedMedia])
  }

  const selectGroup = (ids: string[]) => {
    ids.forEach((id) => select(id))
    selectedMediaVar([...selectedMedia])
  }

  const invoke = (id: string, shouldSelect?: boolean) => {
    if (shouldSelect) {
      select(id)
    } else {
      unselect(id)
    }
  }

  const toggleSelect = (id: string) => {
    invoke(id, !isSelected(id))
    selectedMediaVar([...selectedMedia])
  }

  const toggleBulkSelect = (id: string, items: Image[]) => {
    const selectedMediaValues = selectedMediaVar()
    const peekValue = selectedMediaValues[selectedMedia.length - 1]
    const indexOfPeekValue = _.findIndex(items, (i) => i.id === peekValue)
    const indexOfCurrentId = _.findIndex(items, (i) => i.id === id)
    const startIndex = indexOfPeekValue === -1 ? 0 : indexOfPeekValue
    const shouldSelect = !isSelected(id)
    logger.debug('Bulk: From %s to %s: %s', startIndex, indexOfCurrentId, shouldSelect)
    if (indexOfCurrentId <= startIndex) {
      for (let i: number = startIndex; i >= indexOfCurrentId; i -= 1) {
        logger.debug('Bulk: Selected numbers: %s %s %s', i, items[i], shouldSelect)
        invoke(items[i].id, shouldSelect)
      }
    } else {
      for (let i: number = startIndex; i <= indexOfCurrentId; i += 1) {
        logger.debug('Bulk: Selected numbers: %s %s %s', i, items[i], shouldSelect)
        invoke(items[i].id, shouldSelect)
      }
    }
    selectedMediaVar([...selectedMedia])
    logger.debug('Updated Selected Media: ', JSON.stringify(selectedMedia))
  }

  const isEmpty = () => {
    return !selectedMedia.length
  }

  const deselectAll = () => {
    selectedMediaVar([])
    selectModeEnabledVar({})
  }

  return { isGroupSelected, isSelected, deselectAll, toggleSelect, toggleBulkSelect, unselect, selectGroup, unselectGroup, isEmpty, selectedMedia }
}

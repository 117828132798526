import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined'
import { IconButton, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'
import { BucketMedia } from '../../../../../graphql/generated'
import useBucketDownload from '../../../hooks/useBucketDownload'
import useSelectedMedia from '../../../hooks/useSelectedMedia'

interface Props {
  media?: BucketMedia | null
}

export default function MediaDownload({ media }: Props) {
  const { bucketId } = useParams<{ bucketId: string }>()
  const { selectedMedia } = useSelectedMedia()
  const { downloadSingleMedia, downloadMultipleMedia, downloadMultipleMediaResults, downloadSingleMediaResults } = useBucketDownload()
  const mediaIds: string[] = media ? [media.id] : Array.from(selectedMedia.keys())
  const loading = downloadMultipleMediaResults.loading || downloadSingleMediaResults.loading

  const handleClick = () => {
    if (!bucketId || !mediaIds.length) return

    if (mediaIds.length > 1) {
      downloadMultipleMedia(bucketId, mediaIds)
      return
    }

    downloadSingleMedia(bucketId, mediaIds[0])
  }

  return (
    <IconButton disabled={!mediaIds.length} onClick={handleClick} color="primary" aria-label="Download" data-test="download" id="download-button">
      {loading && <CircularProgress size={20} />}
      {!loading && (
        <Tooltip title="Download Media" arrow>
          <DownloadIcon />
        </Tooltip>
      )}
    </IconButton>
  )
}

MediaDownload.defaultProps = {
  media: null
}

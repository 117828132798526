import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client'
import { AccountCircleOutlined, Close, ExpandMore, Launch } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Drawer, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { isMobile } from 'mobile-device-detect'
import moment from 'moment'
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BucketAlbumFragmentFragmentDoc, BucketMediaFragmentFragmentDoc, BucketUserDocument } from '../../../../graphql/generated'
import { drawerVar } from '../../../../providers/apollo/cache'
import formatBytes from '../../../../utils/formatBytes'
import useBucket from '../../hooks/useBucket'
import useBucketMediaUpdate from '../../hooks/useBucketMediaUpdate'
import useSelectedMedia from '../../hooks/useSelectedMedia'
import TagManagement from './components/TagManagement'

interface Fields {
  title?: string | null
  description?: string | null
}

export default function MediaDetailsDrawer() {
  const { selectedMedia } = useSelectedMedia()
  const { bucketUser } = useBucket()
  const { bucketId, mediaId: pathMediaId } = useParams<{ bucketId: string; mediaId: string }>()
  const mediaIds = Array.from(selectedMedia.keys())
  const theme = useTheme()
  const [fields, setFields] = useState<Fields>({})
  const drawer = useReactiveVar(drawerVar)
  const [moreExpanded, setMoreExpanded] = useState(true)
  const { mutation } = useBucketMediaUpdate()
  const cache = useApolloClient()
  const mediaId = mediaIds[0] || pathMediaId
  const media = cache.readFragment({ id: `BucketMedia:${mediaId}`, fragment: BucketMediaFragmentFragmentDoc })
  const uploaderQuery = useQuery(BucketUserDocument, {
    variables: { userId: media?.userId!, bucketId: bucketId! },
    skip: !media?.userId || !bucketId
  })
  const album = cache.readFragment({ id: `BucketAlbum:${media?.albumId}`, fragment: BucketAlbumFragmentFragmentDoc })
  const albumTitle = album ? album.title : 'All Media'
  const disabled = !bucketUser?.permissions?.mediaUpdate

  useEffect(() => {
    setFields({
      title: media?.title ?? '',
      description: media?.description ?? ''
    })
  }, [media])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFields = { ...fields }
    const field = event.target.name as keyof Fields
    updatedFields[field] = event.target.value
    setFields(updatedFields)
  }

  const handleSaveTags = (userTags: string[]) => {
    if (!media) {
      return
    }
    const variables = {
      bucketId: media.bucketId,
      mediaId: media.id,
      data: { ...fields, userTags }
    }
    mutation({ variables })
  }

  const handleSubmit = (event: FocusEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (!media) {
      return
    }
    const variables = {
      bucketId: media.bucketId,
      mediaId: media.id,
      data: fields
    }
    mutation({ variables })
  }

  const handleClose = () => {
    drawerVar(null)
  }

  if (!media) {
    return null
  }

  return (
    <Drawer
      anchor="right"
      open={drawer === 'bucketMediaDetails'}
      onClose={handleClose}
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? undefined : 400
        }
      }}
      elevation={0}
      variant={pathMediaId && !isMobile ? 'persistent' : 'temporary'}
    >
      <Grid container justifyContent="space-between" alignItems="center" sx={{ padding: 2 }}>
        <Typography sx={{ fontSize: 18, color: '#081230' }} data-test="image-details-drawer">
          Details
        </Typography>
        <IconButton onClick={handleClose} data-test="close" size="large">
          <Close />
        </IconButton>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ borderTop: '1px solid #EAEAEA', borderBottom: '1px solid #EAEAEA', color: '#9195A4', padding: theme.spacing(2) }}
      >
        <AccountCircleOutlined fontSize="small" />
        <span style={{ marginLeft: '10px' }} data-test="username">
          {uploaderQuery.data?.bucketUser?.userProfile?.name ?? 'Unknown User'}
        </span>
      </Grid>
      <div style={{ padding: 16 }}>
        <Typography sx={{ color: '#9195A4' }}>Album</Typography>
        <Button
          href={media.albumId ? `/bucket/${bucketId}/album/${media.albumId}` : `/bucket/${bucketId}`}
          key={media.id}
          sx={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(1, 0),
            textTransform: 'unset',
            '& svg': {
              marginRight: theme.spacing(1.5)
            }
          }}
          color="primary"
          variant="text"
        >
          <Launch />
          {albumTitle}
        </Button>

        {!disabled && (
          <>
            <Box mb={2}>
              <TextField
                data-test="title"
                label="Item Title"
                placeholder="Item Title"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name="title"
                onBlur={handleSubmit}
                value={fields.title ?? ''}
              />
            </Box>
            <Box mb={2}>
              <TextField
                data-test="description"
                label="Item Description"
                placeholder="Item Description"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                onChange={handleChange}
                name="description"
                onBlur={handleSubmit}
                value={fields.description ?? ''}
              />
            </Box>
          </>
        )}
        {disabled && (
          <Box>
            <Typography data-test="title">
              <strong>Title</strong>
            </Typography>
            <Typography>{media?.title ?? 'Untitled'}</Typography>
            <Typography data-test="description" sx={{ mt: 2 }}>
              <strong>Description</strong>
            </Typography>
            <Typography>{media?.description ?? 'No description.'}</Typography>
          </Box>
        )}
      </div>
      <Accordion
        expanded={moreExpanded}
        onChange={(_e, expanded) => setMoreExpanded(expanded)}
        sx={{
          boxShadow: 'none',
          '&:before': {
            display: 'none'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{ background: moreExpanded ? 'rgba(6, 114, 203, 0.08)' : '', color: moreExpanded ? '#0672CB' : '' }}
        >
          <Typography>More Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ padding: 16 }}>
            {media.dateTaken && (
              <Grid container sx={{ padding: theme.spacing(0.25, 0) }}>
                <Typography sx={{ paddingRight: '5px', fontWeight: 800 }} variant="overline">
                  Date Taken:
                </Typography>
                <div>
                  <Typography sx={{ color: '#2F364E', paddingLeft: '5px', fontStyle: 'italic', textTransform: 'none' }} variant="overline">
                    {moment(media.dateTaken).format('MMM D[,] YYYY [at] LT')}
                  </Typography>
                </div>
              </Grid>
            )}
            <Grid container sx={{ padding: theme.spacing(0.25, 0) }}>
              <Typography sx={{ paddingRight: '5px', fontWeight: 800 }} variant="overline">
                Original Filename:
              </Typography>
              <div>
                <Typography sx={{ color: '#2F364E', paddingLeft: '5px', fontStyle: 'italic', textTransform: 'none' }} variant="overline">
                  {media.filename}
                </Typography>
              </div>
            </Grid>
            <Grid container sx={{ padding: theme.spacing(0.25, 0) }}>
              <Typography sx={{ paddingRight: '5px', fontWeight: 800 }} variant="overline">
                File Size:
              </Typography>
              <div>
                <Typography sx={{ color: '#2F364E', paddingLeft: '5px', fontStyle: 'italic', textTransform: 'none' }} variant="overline">
                  {formatBytes(media.fileSize ?? 0)}
                </Typography>
              </div>
            </Grid>
            {media.width && media.height && (
              <Grid container sx={{ padding: theme.spacing(0.25, 0) }}>
                <Typography sx={{ paddingRight: '5px', fontWeight: 800 }} variant="overline">
                  Image Size:
                </Typography>
                <div>
                  <Typography sx={{ color: '#2F364E', paddingLeft: '5px', fontStyle: 'italic', textTransform: 'none' }} variant="overline">
                    {media.width}px x {media.height}px
                  </Typography>
                </div>
              </Grid>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {!disabled && <TagManagement handleSaveTags={handleSaveTags} currentImage={media} />}
    </Drawer>
  )
}

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { MenuItem } from '@mui/material'
import { useParams } from 'react-router-dom'
import { activeAlbumIdVar, drawerVar } from '../../../../../providers/apollo/cache'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'

interface Props {
  handleClose: () => void
}

export default function AlbumDetails({ handleClose }: Props) {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const { selectedAlbums } = useSelectedAlbums()
  const containRootAlbum = Array.from(selectedAlbums.keys()).includes(bucketId as string)

  const toggleDrawer = () => {
    drawerVar('bucketAlbumDetails')
    activeAlbumIdVar(albumId)
    handleClose()
  }

  return (
    <MenuItem
      aria-label="Album Details"
      color="inherit"
      onClick={toggleDrawer}
      disabled={containRootAlbum || !(albumId || selectedAlbums.size === 1)}
      id="details-menu-item"
      data-test="details-menu-item"
    >
      <InfoIcon style={{ marginRight: 8 }} /> Album Details
    </MenuItem>
  )
}

const license = {
  api_token: 'x9GqY-dHi-mwCiwbCeBCAg',
  app_identifiers: [
    'localhost',
    '127.0.0.1',
    'https://hosting.photobucket.com',
    'hosting.photobucket.com',
    'https://dev.photobucket.com',
    'https://stage-hosting.photobucket.com',
    'stage-hosting.photobucket.com',
    'dev.photobucket.com',
    'https://stage.photobucket.com',
    'stage.photobucket.com',
    'https://beta.photobucket.com',
    'beta.photobucket.com',
    'https://photobucket.com',
    'photobucket.com',
    'https://app.photobucket.com',
    'app.photobucket.com',
    'https://next.stage.photobucket.com',
    'next.stage.photobucket.com',
    'https://next.photobucket.com',
    'next.photobucket.com',
    'www.photobucket.com'
  ],
  available_actions: [],
  domains: ['https://api.photoeditorsdk.com'],
  enterprise_license: false,
  expires_at: null,
  features: [
    'camera',
    'library',
    'export',
    'customassets',
    'whitelabel',
    'adjustment',
    'brush',
    'filter',
    'focus',
    'frame',
    'overlay',
    'sticker',
    'text',
    'transform'
  ],
  issued_at: 1687452694,
  minimum_sdk_version: '1.0',
  owner: 'Photobucket.com Inc',
  platform: 'HTML5',
  products: ['pesdk'],
  version: '2.4',
  signature:
    'gjpc70D5zpK0h0/OQPqHGYMZlHRJghNEoTJ1DqNHA/Z1aG31sRebtpYLpyDW1GVEZI6shJqGyLSycJenCx/gQfNunBtkTAi06zlfJFOA1PqXfvCLWCgL1638CAsEzHZqthqQtX2epBDxeX0WOjR0uryEx44U3g56TTPqdT/SOP/26TCHVJRfPBLDldm86i7NZNVipDUXniwDNyZNKn7XIl3gVCs9Dl8f17IWOCEIlsQbK5KgJZyfOEMQ1BZQlFegn3CqPjyUQW0Cr+vOjmADZ3OQEKRm48Ua3AiOvNhU/62LwWfuKydvYTScRufZO6sRvGeyevOpmuAIvGX/DuoZ/k1fyXF+b/URqHWngUtrh4dVq+QVA0pQz/mG2nHayOoQtvpaz1ccF9gpbueczhxKQDPaC0IsFTrgc+WbX9Lt/hx2oak1Mtdi267EvMZrbvptN+psVs4hW+OE8UdkEkkpyQS3SBJB28BpD/xVbxbhsjZOXdcZWKbeN2K/5Vjg0AzRUTXcrXBjnklo3s0UIII5sFG/wWRuHsL0DbD8zNWLRvCNUa6LcQDqcWjpktE2CdJyqP9f6yx7PMHPv3Ny2iEU92Zti5ViBGILfnXHXYiohWH7kwJ+mb3j5qGWYS6UCwYCu2IzRg4PDJLfDnwLrE7kgaoIzAwq8NocNH3os/gp/fY='
}

export default license

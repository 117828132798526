import { useReactiveVar } from '@apollo/client'
import { Stack } from '@mui/material'
import MuiAlert, { AlertColor } from '@mui/material/Alert'
import { useEffect, useRef } from 'react'
import { alertsVar } from '../../../providers/apollo/cache'

interface Props {
  id: string
  message: string
  severity: AlertColor
  removeAlert: (id: string) => void
}

function Alert({ id, message, severity, removeAlert }: Props) {
  const removeAlertRef = useRef(removeAlert)

  useEffect(() => {
    setTimeout(() => {
      removeAlertRef.current(id)
    }, 6000)
  }, [id])

  const handleClose = () => {
    removeAlert(id)
  }

  return (
    <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={severity}>
      {message}
    </MuiAlert>
  )
}

export default function Alerts() {
  const alerts = useReactiveVar(alertsVar)

  const removeAlert = (id: string) => {
    const filteredAlerts = alertsVar().filter((alert) => alert.id !== id)
    alertsVar(filteredAlerts)
  }

  return (
    <Stack sx={{ position: 'absolute', bottom: 24, left: 24, zIndex: 1400 }} spacing={2}>
      {alerts.map((alert) => (
        <Alert key={alert.id} id={alert.id} removeAlert={removeAlert} message={alert.message} severity={alert.severity as AlertColor} />
      ))}
    </Stack>
  )
}

import { getAuth } from 'firebase/auth'
import CurrentBucketMenu from './CurrentBucketMenu'
import ProfileDropDown from './ProfileDropDown'

interface Props {
  currentBucketMenu?: boolean
}

export default function SettingsMenu({ currentBucketMenu }: Props) {
  if (!getAuth().currentUser) {
    return null
  }

  return (
    <>
      {currentBucketMenu && <CurrentBucketMenu />}
      <ProfileDropDown />
    </>
  )
}

SettingsMenu.defaultProps = {
  currentBucketMenu: true
}

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, DialogContent, Typography, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(158.55deg, #202CD3 5.3%, #02E39F 102.34%)',
  borderRadius: '75px',
  border: 'none',
  color: '#fff',
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 600,
  padding: '10px 30px',
  '&:hover': {
    background: 'linear-gradient(158.55deg, #02E39F 5.3%, #202CD3 102.34%)',
    border: 'none'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.875rem',
    padding: '8px 34px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.375rem',
    padding: '15px 60px'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.56rem'
  }
})) as typeof Button

const styles = {
  header: {
    position: 'relative',
    paddingBottom: 0
  },
  titleStyles: {
    position: 'relative',
    fontSize: '36px'
  },
  closeBtn: {
    position: 'absolute',
    cursor: 'pointer',
    right: '10px',
    top: '10px'
  },
  content: {
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5px',
    textAlign: 'center',
    fontSize: '20px'
  },
  linkBtn: {
    textTransform: 'none',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5px',
    textAlign: 'center',
    fontSize: '1rem',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  dialogContent: {
    justifyContent: 'center'
  }
}

interface CancelUpgradeModalProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  content?: string
  link?: string
  upgradeLink: () => void
  url?: string
}

function CancelUpgradeModal({ isOpen, onClose, title, content, link, upgradeLink, url }: CancelUpgradeModalProps) {
  const handleOpenPaymentLink = () => {
    window.open(url, '_blank')
  }

  return (
    <Dialog data-test="cancel-membership-modal" open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle sx={styles.header}>
        <Typography color="primary" align="center" sx={styles.titleStyles}>
          {title}
        </Typography>
        <CloseIcon data-test="cancel-membership-modal-close-btn" sx={styles.closeBtn} onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Typography sx={styles.content}>{content}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '20px' }}>
          <Button sx={styles.linkBtn} color="primary" onClick={upgradeLink} data-pendo="upgrade-membership--step-02">
            Upgrade or Change Plan
          </Button>
          <Button sx={styles.linkBtn} color="error" onClick={handleOpenPaymentLink} data-pendo="cancel-membership--step-02">
            {link}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

CancelUpgradeModal.defaultProps = {
  title: '',
  content: '',
  link: '',
  url: ''
}

export default CancelUpgradeModal

import { useApolloClient } from '@apollo/client'
import { TuneOutlined } from '@mui/icons-material'
import { MenuItem, Tooltip, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { BucketMediaFragmentFragmentDoc, BucketUserRole } from '../../../../../graphql/generated'
import useAlerts from '../../../hooks/useAlerts'
import useBucket from '../../../hooks/useBucket'
import useBucketPlan from '../../../hooks/useBucketPlan'
import useSelectedMedia from '../../../hooks/useSelectedMedia'

interface Props {
  handleClose: () => void
}

export default function EditImage({ handleClose }: Props) {
  const { createAlert } = useAlerts()
  const { selectedMedia } = useSelectedMedia()
  const client = useApolloClient()
  const { bucketUser } = useBucket()
  const { restrictions, tooltipTitle } = useBucketPlan()
  const navigate = useNavigate()
  const location = useLocation()

  const restricted = restrictions.imageEditor
  const hideTooltip = !restricted
  const title = tooltipTitle('imageEditor')

  const handleClick = () => {
    const mediaId: string = selectedMedia.keys().next().value
    const { bucketId } = bucketUser || {}
    if (!mediaId || !bucketId) {
      createAlert('There was an error fetching your bucket details')
      handleClose()
      return
    }

    const media = client.readFragment({ id: `BucketMedia:${mediaId}`, fragment: BucketMediaFragmentFragmentDoc })
    if (!media) {
      createAlert('There was an error fetching this media')
      handleClose()
      return
    }

    handleClose()
    navigate(`/bucket/${bucketId}/media/${mediaId}/editor`, { state: { from: location } })
  }

  return (
    <Tooltip
      PopperProps={{
        disablePortal: hideTooltip
      }}
      disableFocusListener={hideTooltip}
      disableHoverListener={hideTooltip}
      disableTouchListener={hideTooltip}
      title={<Typography sx={{ fontSize: '18px' }}>{title}</Typography>}
    >
      <MenuItem
        color="inherit"
        aria-label="Edit Image"
        disabled={restricted || selectedMedia.size !== 1 || [undefined, BucketUserRole.Viewer].includes(bucketUser?.userRole)}
        onClick={handleClick}
        id="edit-image-menu-item"
        data-test="edit-image-menu-item"
      >
        <TuneOutlined style={{ marginRight: 8 }} /> Edit Image
      </MenuItem>
    </Tooltip>
  )
}

import { useMutation } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { Bucket, BucketCreateDocument, BucketsByUserIdDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

export default function useBucketCreate() {
  const { createAlert } = useAlerts()
  const userId = getAuth().currentUser?.uid
  const [mutation, results] = useMutation(BucketCreateDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        switch (error.extensions?.code) {
          default:
            createAlert(error.message, 'error', error)
        }
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was a problem creating your bucket. Please try again later.')
      }
    },
    update: (cache, { data }) => {
      if (!data || !userId) return
      cache.updateQuery({ query: BucketsByUserIdDocument, variables: { userId } }, (current) => {
        if (!current) return null
        const items = [...(current.bucketsByUserId?.items || []), data.bucketCreate as Bucket]
        return {
          ...current,
          bucketsByUserId: {
            ...current.bucketsByUserId,
            items
          }
        }
      })
    }
  })

  const { loading } = results

  return { loading, mutation, results }
}

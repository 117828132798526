import { PlayCircleOutline } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'

// Play Slideshow icon button as seen on the following pages:
// Albums (mobile)
export default function PlaySlideshow() {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const navigate = useNavigate()
  const { selectedAlbums } = useSelectedAlbums()
  const match = useMatch('/bucket/:bucketId/:slug')
  const disabled = match?.params.slug === 'albums' && selectedAlbums.size !== 1

  const onClick = () => {
    let path = albumId ? `/bucket/${bucketId}/album/${albumId}` : `/bucket/${bucketId}`
    path += `/slideshow`

    navigate(path)
  }
  return (
    <MenuItem onClick={onClick} disabled={disabled} id="delete-menu-item" data-test="delete-menu-item">
      <PlayCircleOutline style={{ marginRight: 8 }} /> Play Slideshow
    </MenuItem>
  )
}

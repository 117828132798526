import { useQuery } from '@apollo/client'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListSubheader,
  Typography,
  useMediaQuery
} from '@mui/material'
import { ChangeEvent, useState } from 'react'
import Loader from '../../../../components/Loader'
import theme from '../../../../config/theme'
import { UserProfileDocument } from '../../../../graphql/generated'
import useBucket from '../../hooks/useBucket'
import useBucketGallerySettings from '../../hooks/useBucketGallerySettings'
import useUserProfileUpdate from '../../hooks/useUserProfileUpdate'

type SettingsFields = {
  showImageTitle: boolean
  imgLarge: boolean
  imgThumb: boolean
  isQuickLinksEnabled: boolean
  directLink: boolean
  htmlThumb: boolean
  htmlLarge: boolean
}

export default function Media() {
  const { bucketUser } = useBucket()
  const { loading: settingsLoading, settings } = useBucketGallerySettings()
  const { mutation, loading: mutationLoading } = useUserProfileUpdate()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [settingsFields, setSettingsFields] = useState<SettingsFields>({
    showImageTitle: Boolean(settings?.showImageTitle),
    imgLarge: Boolean(bucketUser?.userProfile?.linkSettings?.imgLarge),
    imgThumb: Boolean(bucketUser?.userProfile?.linkSettings?.imgThumb),
    isQuickLinksEnabled: Boolean(bucketUser?.userProfile?.isQuickLinksEnabled),
    directLink: Boolean(bucketUser?.userProfile?.linkSettings?.directLink),
    htmlThumb: Boolean(bucketUser?.userProfile?.linkSettings?.htmlThumb),
    htmlLarge: Boolean(bucketUser?.userProfile?.linkSettings?.htmlLarge)
  })

  const userProfileResults = useQuery(UserProfileDocument, {
    onCompleted: (res) => {
      setSettingsFields({
        ...settingsFields,
        showImageTitle: Boolean(res.userProfile?.desktopSettings?.showImageTitle),
        imgLarge: Boolean(res.userProfile?.linkSettings?.imgLarge),
        imgThumb: Boolean(res.userProfile?.linkSettings?.imgThumb),
        isQuickLinksEnabled: Boolean(res.userProfile?.isQuickLinksEnabled),
        directLink: Boolean(res.userProfile?.linkSettings?.directLink),
        htmlThumb: Boolean(res.userProfile?.linkSettings?.htmlThumb),
        htmlLarge: Boolean(res.userProfile?.linkSettings?.htmlLarge)
      })
    }
  })

  const isHostingEnabled = bucketUser?.userProfile?.plan?.is_hosting_enabled ?? userProfileResults.data?.userProfile?.plan?.is_hosting_enabled

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name as keyof SettingsFields
    const isChecked = event.target.checked

    setSettingsFields((prev) => ({ ...prev, [key]: isChecked }))
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const variables = {
      data: {
        desktopSettings: {
          showImageTitle: settingsFields.showImageTitle
        },
        linkSettings: {
          imgLarge: settingsFields.imgLarge,
          imgThumb: settingsFields.imgThumb,
          directLink: settingsFields.directLink,
          htmlThumb: settingsFields.htmlThumb,
          htmlLarge: settingsFields.htmlLarge
        },
        isQuickLinksEnabled: settingsFields.isQuickLinksEnabled
      }
    }

    mutation({ variables })
  }

  if (userProfileResults.loading || settingsLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <List>
          <ListSubheader>
            <Typography variant="h6" style={{ color: 'black', marginBottom: '5px' }}>
              Image Settings
            </Typography>
            <Typography>Show Image Title</Typography>
          </ListSubheader>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(settingsFields.showImageTitle)}
                  onChange={handleChange}
                  name="showImageTitle"
                  color="primary"
                  data-test="show-image-title"
                />
              }
              label="Turn on to show image title on grid"
            />
          </ListItem>
        </List>

        <List>
          <ListSubheader>
            <Typography variant="h6" style={{ color: 'black', marginBottom: '5px' }}>
              My Bucket Links
            </Typography>
          </ListSubheader>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settingsFields.directLink}
                  disabled={!isHostingEnabled}
                  onChange={handleChange}
                  name="directLink"
                  color="primary"
                  data-test="direct-link-option"
                />
              }
              label="Direct Link"
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settingsFields.htmlLarge}
                  disabled={!isHostingEnabled}
                  onChange={handleChange}
                  name="htmlLarge"
                  color="primary"
                  data-test="html-code-option"
                />
              }
              label="HTML Code"
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settingsFields.htmlThumb}
                  disabled={!isHostingEnabled}
                  onChange={handleChange}
                  name="htmlThumb"
                  color="primary"
                  data-test="html-thumb-option"
                />
              }
              label="HTML Thumb"
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settingsFields.imgLarge}
                  disabled={!isHostingEnabled}
                  onChange={handleChange}
                  name="imgLarge"
                  color="primary"
                  data-test="image-option"
                />
              }
              label="IMG"
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settingsFields.imgThumb}
                  disabled={!isHostingEnabled}
                  onChange={handleChange}
                  name="imgThumb"
                  color="primary"
                  data-test="image-thumb-option"
                />
              }
              label="IMG Thumb"
            />
          </ListItem>
          <ListSubheader>
            <Typography>Easy Linking Mode</Typography>
          </ListSubheader>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settingsFields.isQuickLinksEnabled}
                  disabled={!isHostingEnabled}
                  onChange={handleChange}
                  name="isQuickLinksEnabled"
                  color="primary"
                  data-test="easy-linking-option"
                />
              }
              label="Turn on easy linking mode"
            />
          </ListItem>
        </List>
      </FormGroup>
      <Button
        style={{ width: isMobile ? '100%' : '550px' }}
        color="primary"
        variant="contained"
        type="submit"
        data-test="submit-ablum-settings-button"
        disabled={mutationLoading}
        startIcon={mutationLoading ? <CircularProgress /> : null}
      >
        {mutationLoading ? 'Saving...' : 'Save Changes'}
      </Button>
    </form>
  )
}

import { useMutation } from '@apollo/client'
import { logger } from 'workbox-core/_private'
import { BucketFragmentFragmentDoc, BucketMediaByAlbumIdDocument, BucketMediaStatus, BucketTrashEmptyDocument } from '../../../graphql/generated'
import useAlerts from './useAlerts'

export default function useBucketTrashEmpty() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(BucketTrashEmptyDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was an error moving your media. Please try again later.')
      }
    },
    update: (cache, { data }, { variables }) => {
      if (!data?.bucketTrashEmpty || !variables?.bucketId) return

      cache.updateFragment(
        {
          id: `Bucket:${variables.bucketId}`,
          fragment: BucketFragmentFragmentDoc
        },
        (current) => {
          if (!current) return null

          const totalMedia = current.counters?.totalMedia ?? 0
          const totalSize = current.counters?.totalSize ?? 0
          const totalMediaMinusTrash = current.counters?.totalMediaMinusTrash ?? 0
          const totalMediaInTrash = current?.counters?.totalMediaInTrash ?? 0
          const totalSizeInTrash = current?.counters?.totalSizeInTrash ?? 0
          const totalSizeMinusTrash = current?.counters?.totalSizeMinusTrash ?? 0

          return {
            ...current,
            counters: {
              totalMedia: totalMedia - totalMediaInTrash,
              totalMediaInTrash: 0,
              totalMediaMinusTrash,
              totalSize: totalSize - totalSizeInTrash,
              totalSizeInTrash: 0,
              totalSizeMinusTrash
            }
          }
        }
      )

      cache.updateQuery(
        {
          query: BucketMediaByAlbumIdDocument,
          variables: {
            bucketId: variables.bucketId,
            filterBy: { status: BucketMediaStatus.Trash },
            sortBy: null
          }
        },
        (cachedData) => {
          if (!cachedData) return null

          const { bucketMediaByAlbumId } = cachedData

          return {
            bucketMediaByAlbumId: {
              ...bucketMediaByAlbumId,
              items: []
            }
          }
        }
      )
    },
    onCompleted: () => {
      createAlert('Trash has been emptied!', 'success')
    }
  })

  return { mutation, results }
}

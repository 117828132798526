import { useReactiveVar } from '@apollo/client'
import { ArrowDropDown, Close } from '@mui/icons-material'
import { LinearProgress, Box, Button, Chip, InputBase, Menu, MenuItem, Typography, styled, useTheme } from '@mui/material'
import { startCase } from 'lodash'
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BucketUserRole } from '../../../../graphql/generated'
import { modalVar } from '../../../../providers/apollo/cache'
import useAlerts from '../../hooks/useAlerts'
import useBucketInvitationCreate from '../../hooks/useBucketInvitationCreate'

const CustomInput = styled('div')(({ theme }) => ({
  flex: 1,
  borderColor: theme.palette.customGrey[400],
  gap: '4px',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 5,
  padding: '8px 8px',
  marginRight: '8px',
  display: 'flex',
  flexWrap: 'wrap'
}))

const Form = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: '10px'
}))

const items = [BucketUserRole.Contributor, BucketUserRole.Viewer, BucketUserRole.Admin]

const ITEM_HEIGHT = 48

const emailRegex = /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/

export default function EmailLinkForm() {
  const { createAlert } = useAlerts()
  const theme = useTheme()
  const modal = useReactiveVar(modalVar)
  const [userRole, setUserRole] = useState(BucketUserRole.Contributor)
  const [email, setEmail] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [emails, setEmails] = useState<Map<string, true>>(new Map())
  const { bucketId } = useParams<{ bucketId: string }>()
  const { mutation, loading } = useBucketInvitationCreate()
  const emailIsValid = email && emailRegex.test(email)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClickDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.toLowerCase())
    setError(null)
  }

  const handleDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', ' ', ','].includes(event.key) && email) {
      event.preventDefault()
      if (!emailIsValid) {
        setError(`${email} is an invalid email format`)
        return
      }
      emails.set(email.toLowerCase(), true)
      setEmails(new Map(emails))
      setEmail(null)
    }
  }

  const handleOption = (role: BucketUserRole) => {
    return () => {
      setAnchorEl(null)
      setUserRole(role)
    }
  }

  const handleDelete = (key: string) => () => {
    emails.delete(key)
    setEmails(new Map(emails))
  }

  const handleSubmit = () => {
    if (!bucketId || loading) {
      return
    }

    const emailArr = Array.from(emails.keys())

    // Current value in textfield that has not been added as a chip
    if (emailIsValid) {
      emailArr.push(email)
    }

    mutation({
      variables: { bucketId, emails: emailArr, userRole },
      onCompleted: () => {
        createAlert('Email have been sent', 'success')
        setEmail('')
        setEmails(new Map())
      }
    })
  }

  // resets form on close
  useEffect(() => {
    if (modal !== 'bucketInvite' && emails.size > 0) {
      setEmail(null)
      setEmails(new Map())
    }
  }, [emails.size, modal])

  return (
    <>
      <Form data-test="invite-users-form">
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: '10px', sm: 0 }, width: '100%' }}>
          <CustomInput sx={{ width: { xs: '100%', sm: 'auto' } }}>
            {Array.from(emails.keys()).map((emailAddress) => (
              <Chip
                deleteIcon={<Close />}
                key={emailAddress}
                label={emailAddress}
                onDelete={handleDelete(emailAddress)}
                size="small"
                variant="outlined"
              />
            ))}
            <InputBase
              value={email ?? ''}
              error={!!error}
              size="small"
              inputProps={{
                id: 'bucket-invitation-input'
              }}
              placeholder="Invite by email"
              style={{ textTransform: 'lowercase' }}
              sx={{ '.MuiInputBase-input': { padding: 0, minWidth: '240px' } }}
              onKeyDown={handleDown}
              onChange={handleChange}
            />
          </CustomInput>
          <Button
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClickDropdown}
            size="small"
            sx={{ mr: 1, '&.MuiButton-outlined': { color: theme.palette.customGrey[800], fontWeight: 'normal' } }}
            variant="outlined"
          >
            {startCase(userRole.toLowerCase())}
            <ArrowDropDown htmlColor={theme.palette.customGrey[800]} />
          </Button>
          <Button
            data-test="bucket-invitation-send-invites"
            onClick={handleSubmit}
            disabled={(emails.size === 0 && !emailIsValid) || loading}
            variant="contained"
            sx={{ display: 'inline-block' }}
          >
            Send Invites
          </Button>
          <Menu
            id="email-link-role-menu"
            MenuListProps={{
              'aria-labelledby': 'email-link-role-menu'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseDropdown}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5
              }
            }}
          >
            {items.map((option) => (
              <MenuItem key={option} onClick={handleOption(option)}>
                {startCase(option.toLowerCase())}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Form>
      {error && <Typography color="red">{error}</Typography>}
      {loading && <LinearProgress />}
    </>
  )
}

import { useMediaQuery, useTheme } from '@mui/material'
import { useMatch } from 'react-router-dom'
import useSelectedAlbums from '../../hooks/useSelectedAlbums'
import useSelectedMedia from '../../hooks/useSelectedMedia'
import AlbumDelete from './iconButtons/AlbumDelete'
import AlbumDetails from './iconButtons/AlbumDetails'
import AlbumDownload from './iconButtons/AlbumDownload'
import AlbumMove from './iconButtons/AlbumMove'
import MediaDelete from './iconButtons/MediaDelete'
import MediaDetails from './iconButtons/MediaDetails'
import MediaDownload from './iconButtons/MediaDownload'
import MediaMove from './iconButtons/MediaMove'
import MediaPrintShop from './iconButtons/MediaPrintShop'
import PlaySlideshow from './iconButtons/PlaySlideshow'
import EditImage from './iconButtons/EditImage'
import MediaShare from './iconButtons/MediaShare'
import MediaFavorite from './iconButtons/MediaFavorite'

function AlbumIconMenu() {
  return (
    <>
      <AlbumDetails />
      <AlbumDownload />
      <AlbumDelete />
      <AlbumMove />
      <PlaySlideshow />
    </>
  )
}

function MediaIconMenu() {
  return (
    <>
      <MediaDetails />
      <MediaShare />
      <MediaDownload />
      <MediaFavorite />
      <MediaDelete />
      <MediaMove />
      <EditImage />
      <MediaPrintShop />
    </>
  )
}

export default function GalleryIconButtons() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const bucketPath = useMatch('/bucket/:bucketId/:slug')
  const albumPath = useMatch('/bucket/:bucketId/album/:albumId/:slug')
  const { selectedModeEnabled: selectedModeEnabledAlbums } = useSelectedAlbums()
  const { selectedModeEnabled: selectedModeEnabledMedia } = useSelectedMedia()
  const selectedModeEnabled = selectedModeEnabledAlbums || selectedModeEnabledMedia

  if (isMobile) {
    return null
  }

  const slugIsAlbumsView = bucketPath?.params.slug === 'albums' || albumPath?.params.slug === 'subalbums'

  if (slugIsAlbumsView || !selectedModeEnabled) {
    return <AlbumIconMenu />
  }

  return <MediaIconMenu />
}

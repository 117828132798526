import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined'
import { MenuItem } from '@mui/material'
import { useParams } from 'react-router-dom'
import { activeAlbumIdVar } from '../../../../../providers/apollo/cache'
import useBucketDownload from '../../../hooks/useBucketDownload'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'

interface Props {
  handleClose: () => void
}

export default function AlbumDownload({ handleClose }: Props) {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const { selectedAlbums } = useSelectedAlbums()
  const { downloadAlbums } = useBucketDownload()
  const containRootAlbum = Array.from(selectedAlbums.keys()).includes(bucketId as string)

  const onClick = () => {
    if (!bucketId || (!albumId && selectedAlbums.size < 1)) return

    if (albumId) activeAlbumIdVar(albumId)
    downloadAlbums(bucketId)
    handleClose()
  }

  return (
    <MenuItem
      disabled={containRootAlbum || !(albumId || selectedAlbums.size !== 0)}
      color="inherit"
      aria-label="Download Album"
      onClick={onClick}
      id="download-menu-item"
      data-test="download-menu-item"
    >
      <DownloadIcon style={{ marginRight: 8 }} /> Album Download
    </MenuItem>
  )
}

import { Box, Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography, useTheme } from '@mui/material'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import useAlerts from '../hooks/useAlerts'
import useBucketCreate from '../hooks/useBucketCreate'
import { BucketType } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import config from '../../../config'
import useProfilePlan from '../hooks/useProfilePlan'

interface Props {
  modalTitle: string
  modalDescription?: string
}

export default function Create({ modalTitle, modalDescription }: Readonly<Props>) {
  const { createAlert } = useAlerts()
  const { isStoragePlan, isSharingPlan } = useProfilePlan()
  const { mutation, loading } = useBucketCreate()
  const theme = useTheme()
  const [title, setTitle] = useState('')
  const [bucketType, setBucketType] = useState(BucketType.Mybucket)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTitle(e.target.value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (loading) {
      return
    }

    if (isEmpty(title)) {
      createAlert('Please enter a valid bucket name')
      return
    }

    mutation({
      onCompleted: (results) => {
        const bucketId = results.bucketCreate?.id
        const suffix = bucketType === BucketType.Group ? '?inviteModal=true' : ''
        window.location.href = `/bucket/${bucketId}${suffix}`
      },
      onError: (error) => {
        createAlert('There was an error creating your bucket')
        logger.error(error)
      },
      variables: { data: { title, bucketType } }
    })
  }

  const handleCheck = () => setBucketType(bucketType === BucketType.Mybucket ? BucketType.Group : BucketType.Mybucket)

  return (
    <div
      style={{
        marginTop: '32px',
        marginBottom: theme.spacing(1),
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box maxWidth="sm" component="form" onSubmit={handleSubmit}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          {modalTitle}
          <br />
          {modalDescription}
        </Typography>
        <figure style={{ width: 'intrinsic' }}>
          {/* Scales image to 33% original size */}
          <img style={{ width: '33%' }} src={`${config.assets.url}/img/group-bucket/create-bucket.png`} alt="New bucket" />
        </figure>
        {isStoragePlan && (
          <Typography variant="body1" sx={{ mb: 1 }}>
            Photobucket is changing the way you are able to use buckets. You can now create as many buckets as you wish.
          </Typography>
        )}
        {isSharingPlan && (
          <Typography variant="body1" sx={{ mb: 1 }}>
            Photobucket is changing the way you are able to use buckets. You can now create as many buckets as you wish. These buckets can be either
            personal or collaborative. If you have a plan that supports Group Buckets, you&rsquo;ll see a checkbox below that you can use to invite to
            your bucket.
          </Typography>
        )}
        <TextField
          fullWidth
          onChange={handleChange}
          label="Bucket Name"
          id="bucket-name"
          name="bucket-name"
          data-test="create-bucket-title"
          size="small"
          required
        />
        {isStoragePlan && <div style={{ padding: 10 }} />}
        {isSharingPlan && (
          <FormGroup sx={{ mt: 1, ml: 1 }}>
            <FormControlLabel
              control={<Checkbox data-test="create-bucket-checkbox" value={bucketType === BucketType.Group} onChange={handleCheck} />}
              label="Invite people to your bucket"
            />
          </FormGroup>
        )}
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button data-test="create-bucket-submit" type="submit" variant="contained">
            Create Bucket
          </Button>
        </div>
      </Box>
    </div>
  )
}

Create.defaultProps = {
  modalDescription: undefined
}

import { OAuthProvider } from 'firebase/auth'

class PaypalAuthProvider extends OAuthProvider {
  static PROVIDER_ID = 'oidc.paypal'

  constructor() {
    super(PaypalAuthProvider.PROVIDER_ID)
  }
}

export default PaypalAuthProvider

import { useApolloClient, useQuery } from '@apollo/client'
import { Box, Button, Hidden, useTheme } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { Link, useParams } from 'react-router-dom'
import { BucketFragmentFragmentDoc, BucketsByUserIdDocument } from '../../../../graphql/generated'
import logger from '../../../../utils/logger'

export default function CurrentBucketMenu() {
  const theme = useTheme()
  const client = useApolloClient()
  const userId = getAuth().currentUser?.uid
  const queryParams = useParams<{ bucketId: string }>()
  const results = useQuery(BucketsByUserIdDocument, {
    skip: !userId,
    variables: { userId: userId! },
    onError: (e) => {
      logger.error(e)
    }
  })
  const bucketId = queryParams.bucketId ?? results.data?.userProfile?.currentBucketId
  const bucket = client.readFragment({ id: `Bucket:${bucketId}`, fragment: BucketFragmentFragmentDoc })

  if (!bucket) {
    return null
  }

  return (
    <Hidden mdDown>
      <Button
        component={Link}
        to={`/bucket/${bucket.id}`}
        data-test="appbar-current-bucket"
        id="appbar-current-bucket"
        sx={{
          textTransform: 'none',
          color: theme.palette.secondary.main,
          maxWidth: '140px',
          '&:hover': {
            backgroundColor: theme.palette.primary.highlight,
            color: theme.palette.primary.main
          }
        }}
      >
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{bucket.title}</Box>
      </Button>
    </Hidden>
  )
}

import { useCallback } from 'react'
import logger from '../../../utils/logger'

export default function useGAEvent() {
  const pushToDataLayer = (event: string, data?: any) => {
    if (!window.dataLayer) {
      logger.warn('GA4 events are not setup, event capture failed: ', event)
      return
    }
    if (data) {
      window.dataLayer.push({ event, ...data })
    } else {
      window.dataLayer.push({ event })
    }
  }

  const trackEvent = useCallback((event: string, data?: any) => {
    logger.debug('Event tracking started: ', event, data)
    pushToDataLayer(event, data)
  }, [])

  return { trackEvent }
}

import { useReactiveVar } from '@apollo/client'
import { Box, Dialog } from '@mui/material'
import { modalVar } from '../../../../providers/apollo/cache'
import Create from '../Create'

export default function BucketCreateModal() {
  const modal = useReactiveVar(modalVar)

  const handleClose = () => {
    modalVar(null)
  }

  return (
    <Dialog open={modal === 'createBucket'} onClose={handleClose}>
      <Box sx={{ m: 3 }}>
        <Create modalTitle="Create a new bucket!" />
      </Box>
    </Dialog>
  )
}

import { Box, Button, Fade, SxProps, Theme, Tooltip, styled } from '@mui/material'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import pbTheme from '../../../../config/theme'
import useGAEvent from '../../../auth/hooks/useGAEvent'
import { GenericHeader } from '../../homepage/components/elements/StepHeader'
import { MonthlyPlans, YearlyPlans } from './components/PlanDetails'

const PlansSection = styled(Box)(({ theme }) => ({
  margin: '40px 0',
  textAlign: 'center',
  padding: '0 15px 10px',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: '0 125px 10px',
    margin: '40px 0 140px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '0 75px 10px',
    margin: '40px 0 140px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 140px 10px'
  }
}))

const MonthButton = styled(Button, { shouldForwardProp: (prop: string) => prop !== 'plans' })<{ plans: string }>(({ plans, theme }) => ({
  backgroundColor: plans === 'monthly' ? theme.palette.primary.main : theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.contrastText,
  borderRadius: '8px',
  boxShadow: plans === 'monthly' ? '0px 0.482333px 0.964667px rgba(16, 24, 40, 0.05)' : 'none',
  color: plans === 'monthly' ? theme.palette.primary.contrastText : '#000',
  fontWeight: plans === 'monthly' ? 500 : 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: plans === 'monthly' ? theme.palette.primary.main : theme.palette.primary.contrastText,
    borderColor: plans === 'monthly' ? theme.palette.primary.contrastText : theme.palette.primary.main,
    color: plans === 'monthly' ? theme.palette.primary.contrastText : theme.palette.primary.main,
    boxShadow: '0px 0.482333px 0.964667px rgba(16, 24, 40, 0.05)'
  }
}))

const YearButton = styled(Button, { shouldForwardProp: (prop: string) => prop !== 'plans' })<{ plans: string }>(({ plans, theme }) => ({
  backgroundColor: plans === 'yearly' ? theme.palette.primary.main : theme.palette.primary.contrastText,
  boxShadow: plans === 'yearly' ? '0px 0.482333px 0.964667px rgba(16, 24, 40, 0.05)' : 'none',
  borderColor: theme.palette.primary.contrastText,
  borderRadius: '8px',
  color: plans === 'yearly' ? theme.palette.primary.contrastText : '#000',
  fontWeight: plans === 'yearly' ? 500 : 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: plans === 'yearly' ? theme.palette.primary.main : theme.palette.primary.contrastText,
    borderColor: plans === 'yearly' ? theme.palette.primary.contrastText : theme.palette.primary.main,
    color: plans === 'yearly' ? theme.palette.primary.contrastText : theme.palette.primary.main,
    boxShadow: '0px 0.482333px 0.964667px rgba(16, 24, 40, 0.05)'
  }
}))

const TooltipSx: SxProps<Theme> = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'black',
    borderRadius: '1.5rem',
    fontSize: '.85rem',
    lineHeight: 1.8,
    maxWidth: '18rem',
    padding: '1rem'
  },
  '& .MuiTooltip-arrow': {
    color: 'black'
  }
}

interface Props {
  hideTitle?: boolean
  plan?: string
  planCycle?: string
}

export default function Plans({ hideTitle, plan, planCycle }: Readonly<Props>) {
  const { trackEvent } = useGAEvent()
  const cadence = planCycle === 'annual' ? 'yearly' : 'monthly'
  const [plans, setPlans] = useState<'monthly' | 'yearly'>(cadence)

  const handleClick = (show: 'monthly' | 'yearly') => () => {
    setPlans(show)
  }

  // capture event for page view
  useEffectOnce(() => {
    trackEvent('plans_page_view', { newPlanPage: true, id: plan === undefined ? 'select_plan' : 'change_plan' })
  })

  return (
    <PlansSection component="section" id="plansBlock">
      {hideTitle !== true && <GenericHeader title={plan === undefined ? 'Select a Plan' : 'Change Your Plan'} />}
      <Box
        sx={{
          border: '1px solid #dadada',
          borderRadius: '8px',
          padding: { xs: '4px 6px', md: '6px 8px', lg: '8px 10px', xl: '10px 12px' },
          minWidth: { xs: '200px', md: '200px', lg: '250px', xl: '300px' },
          marginTop: '20px',
          marginBottom: '20px',
          display: 'inline-flex',
          gap: '10px',
          [pbTheme.breakpoints.up('xl')]: {
            marginBottom: '50px'
          }
        }}
      >
        <Tooltip
          arrow
          PopperProps={{ sx: TooltipSx }}
          title="A monthly billing schedule allows you to be flexible with your plan, we'll automatically renew your subscription each month for you until you decide to cancel."
        >
          <MonthButton
            plans={plans}
            onClick={handleClick('monthly')}
            color="primary"
            variant={plans === 'monthly' ? 'contained' : 'outlined'}
            data-testid="plans-monthly-toggle"
            sx={{
              padding: { xs: '0px 20px', md: '0px 30px', lg: '0px 30px', xl: '5px 35px' },
              fontSize: { xs: '0.875rem', md: '1rem', lg: '1.25rem' },
              minWidth: { xs: '100px', sm: '125px', md: '150px', lg: '175px', xl: '200px' }
            }}
          >
            Monthly
          </MonthButton>
        </Tooltip>
        <Tooltip
          arrow
          PopperProps={{ sx: TooltipSx }}
          title="An annual billing schedule helps you get the best price on your subscription and you don't have to worry about a monthly renewal."
        >
          <YearButton
            plans={plans}
            onClick={handleClick('yearly')}
            color="primary"
            variant={plans === 'yearly' ? 'contained' : 'outlined'}
            data-testid="plans-yearly-toggle"
            sx={{
              padding: { xs: '0px 20px', md: '0px 30px', lg: '0px 30px', xl: '5px 35px' },
              fontSize: { xs: '0.875rem', md: '1rem', lg: '1.25rem' },
              minWidth: { xs: '100px', sm: '125px', md: '150px', lg: '175px', xl: '200px' }
            }}
          >
            Yearly
          </YearButton>
        </Tooltip>
      </Box>
      <div style={{ width: '100%', display: 'grid' }}>
        <Fade in={plans === 'monthly'} unmountOnExit>
          <div>
            <MonthlyPlans key="monthly" plan={plan} isMonthly={planCycle?.toLowerCase().includes('month')} />
          </div>
        </Fade>
        <Fade in={plans === 'yearly'} unmountOnExit>
          <div>
            <YearlyPlans
              key="yearly"
              plan={plan}
              isYearly={planCycle?.toLowerCase().includes('annual') || planCycle?.toLowerCase().includes('yearly')}
            />
          </div>
        </Fade>
      </div>
    </PlansSection>
  )
}

Plans.defaultProps = {
  plan: undefined,
  planCycle: undefined
}

import { useMutation } from '@apollo/client'
import { BucketAlbumFragmentFragmentDoc, BucketFragmentFragmentDoc, BucketMediaCreateDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

export default function useBucketMediaCreate() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(BucketMediaCreateDocument, {
    onCompleted: ({ bucketMediaCreate }) => {
      if (!bucketMediaCreate) return
      createAlert(`A new file has been uploaded`, 'success')
    },
    update: (cache, { data }) => {
      if (!data?.bucketMediaCreate) return
      const { bucketId, albumId } = data.bucketMediaCreate

      // Update the bucket's counters
      cache.updateFragment(
        {
          id: `Bucket:${bucketId}`,
          fragment: BucketFragmentFragmentDoc
        },
        (current) => {
          if (!current) return null

          const totalMedia = current?.counters?.totalMedia ?? 0
          const totalMediaMinusTrash = current.counters?.totalMediaMinusTrash ?? 0
          const totalSize = current?.counters?.totalSize ?? 0
          const fileSize = data?.bucketMediaCreate?.fileSize ?? 0

          return {
            ...current,
            counters: {
              ...current.counters,
              totalMedia: totalMedia + 1,
              totalMediaMinusTrash: totalMediaMinusTrash + 1,
              totalSize: totalSize + fileSize
            }
          }
        }
      )

      // Update the bucket album's counters
      cache.updateFragment(
        {
          id: `BucketAlbum:${albumId}`,
          fragment: BucketAlbumFragmentFragmentDoc
        },
        (current) => {
          if (!current) return null

          const totalMedia = current?.counters?.totalMedia ?? 0

          return {
            ...current,
            counters: {
              ...current.counters,
              totalMedia: totalMedia + 1
            }
          }
        }
      )
    },
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        switch (error.extensions?.code) {
          default:
            createAlert(error.message, 'error', error)
        }
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was a problem creating your media. Please try again later.')
      }
    }
  })

  return { mutation, results }
}

import { useMutation } from '@apollo/client'
import { ChangeEmailDocument } from '../../../../../graphql/generated'
import logger from '../../../../../utils/logger'
import useAlerts from '../../../hooks/useAlerts'

export default function useChangeEmail() {
  const { createAlert } = useAlerts()
  const [mutation, results] = useMutation(ChangeEmailDocument, {
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message)
          logger.error(e)
        })
        return
      }
      logger.error(error)
      createAlert('There was a problem changing email. Please try again later.')
    }
  })

  return { mutation, results }
}

import { OAuthProvider } from 'firebase/auth'

class MicrosoftAuthProvider extends OAuthProvider {
  static PROVIDER_ID = 'microsoft.com'

  constructor() {
    super(MicrosoftAuthProvider.PROVIDER_ID)
  }
}

export default MicrosoftAuthProvider

import { Button } from '@mui/material'
import { useMatch } from 'react-router-dom'
import useSelectedAlbums from '../../hooks/useSelectedAlbums'
import useSelectedMedia from '../../hooks/useSelectedMedia'

export default function SelectButton() {
  const { toggleSelectionMode: toggleSelectionModeAlbums } = useSelectedAlbums()
  const { toggleSelectionMode: toggleSelectionModeMedia } = useSelectedMedia()
  const path = useMatch('/bucket/:bucketId/:slug')
  const isActivityFeed = path?.params.slug === 'activity'

  const handleClick = () => {
    if (path?.params.slug === 'albums') {
      toggleSelectionModeAlbums()
      return
    }

    toggleSelectionModeMedia()
  }

  return (
    <Button
      variant="outlined"
      sx={{ textTransform: 'none', marginRight: '12px', paddingTop: '4px', paddingBottom: '4px' }}
      size="small"
      data-test="select-mode"
      aria-label="Select Mode"
      id="select-mode"
      onClick={handleClick}
      disabled={isActivityFeed}
    >
      Select
    </Button>
  )
}

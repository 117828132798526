/* tslint:disable */
/* eslint-disable */
export enum SendEmailScreenState {
  UNSENT,
  SENT
}

export enum State {
  DEFAULT,
  LOADING,
  SUCCESS,
  ERROR
}

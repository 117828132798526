import { useReactiveVar } from '@apollo/client'
import { Box, Button, Collapse, Hidden, LinearProgress, List, Stack, Typography } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { styled, useTheme } from '@mui/material/styles'
import { useState } from 'react'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import { drawerVar } from '../../../../../providers/apollo/cache'
import logger from '../../../../../utils/logger'
import useBucket from '../../../hooks/useBucket'
import SearchBar from '../../appbar/SearchBar'
import BucketAlbum from '../components/Album'
import AlbumSubHeader from '../components/AlbumSubHeader'
import BucketAdminActions from '../components/BucketAdminActions'
import BucketMenu from '../components/BucketMenu'
import BucketSwitcher from '../components/BucketSwitcher'
import StaticAlbums from '../components/StaticAlbums'
import UtilizationMenu from '../components/UtilizationMenu'

interface Props {
  bucketId: string
  userId: string
}

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  width: '280px',
  '& a': {
    color: 'inherit'
  },
  '& #album-menu': {
    minWidth: '260px'
  }
}))

const formatCount = (count: number = 0) => {
  switch (count) {
    case 0:
      return 'No images'
    case 1:
      return `1 image`
    default:
      return `${count} images`
  }
}

export default function MobileDrawer({ bucketId, userId }: Props) {
  const theme = useTheme()
  const drawer = useReactiveVar(drawerVar)
  const [isOpen, setIsOpen] = useState(true)
  const { bucket, bucketAlbums, bucketUser, fetchMore, loading, loadingMore } = useBucket()

  const imgCount = formatCount(bucket?.counters?.totalMediaMinusTrash ?? 0)
  const bucketTitle = bucket?.title
  const albums = bucketAlbums?.items || []
  const nextToken = bucketAlbums?.nextToken
  const permissions = bucketUser?.permissions

  const handleOpen = () => drawerVar('main')
  const handleClose = () => drawerVar(null)

  const toggle = () => setIsOpen(!isOpen)

  logger.info('MobileDrawer', { hasMore: Boolean(nextToken), bucketAlbums: albums?.length })

  if (!bucketId || !userId) {
    return null
  }

  return (
    <StyledSwipeableDrawer
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      onClose={handleClose}
      onOpen={handleOpen}
      open={drawer === 'main'}
      variant="temporary"
      swipeAreaWidth={0}
      keepMounted
      PaperProps={{ id: 'album-menu' }}
    >
      <Box sx={{ borderBottom: '1px solid #dfdfdf', padding: '10px', maxWidth: '280px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div>
            <Stack direction="row" spacing={1} alignItems="flex-start">
              {bucket && <BucketAdminActions bucket={bucket} permissions={permissions} />}
              <Typography component="h3" sx={{ fontSize: '1rem', fontWeight: '600', lineHeight: 1.25 }}>
                {bucketTitle}
              </Typography>
            </Stack>
            {!bucket?.isBanned && <Typography variant="caption">{imgCount}</Typography>}
          </div>
          <BucketMenu permissions={permissions} bucketId={bucketId} />
        </Box>
        <BucketSwitcher userId={userId} />
      </Box>
      {!bucket?.isBanned && (
        <Hidden lgUp>
          <Box sx={{ borderBottom: '1px solid #dfdfdf', padding: '10px 0', maxWidth: '280px' }}>
            <SearchBar />
          </Box>
        </Hidden>
      )}
      {!bucket?.isBanned && (
        <Box sx={{ padding: '0 10px' }}>
          <StaticAlbums />
        </Box>
      )}
      {loading && !bucket?.isBanned && <LinearProgress color="primary" />}
      {!bucket?.isBanned && (
        <List sx={{ flex: 'auto', padding: '0 10px', maxWidth: '280px' }}>
          <ReactInfiniteScroll
            dataLength={albums.length || 0}
            loader={
              <Typography variant="h6" align="center">
                Loading...
              </Typography>
            }
            next={fetchMore}
            hasMore={Boolean(nextToken)}
          >
            {!loading && <AlbumSubHeader isOpen={isOpen} toggle={toggle} />}
            <Collapse in={isOpen}>
              {albums.map((album) => (
                <BucketAlbum album={album} key={album.id} bucketId={bucketId} />
              ))}
              {Boolean(nextToken) && (
                <Button disabled={loadingMore} onClick={fetchMore} fullWidth>
                  load more
                </Button>
              )}
            </Collapse>
          </ReactInfiniteScroll>
        </List>
      )}
      {!bucket?.isBanned && <UtilizationMenu />}
    </StyledSwipeableDrawer>
  )
}

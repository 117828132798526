import useBucket from './useBucket'

export default function useBucketPlan() {
  const { bucket } = useBucket()
  const plan = bucket?.plan ?? { plan_name: 'No Plan' }
  const name = plan.plan_name
  const isStoragePlan = Boolean(plan.is_storage_plan)
  const isSharingPlan = Boolean(plan.is_sharing_plan)

  const mediaUsed = Number(bucket?.counters?.totalMedia ?? 0)
  const mediaAvailable = Number(bucket?.plan?.media_limit ?? 0)
  // mediaAvailable === -1 means unlimited media
  const overMedia = mediaAvailable !== -1 && mediaUsed >= mediaAvailable

  const bytesUsed = Number(bucket?.counters?.totalSize ?? 0)
  const bytesAvailable = Number(bucket?.plan?.storage_limit ?? 0)
  // bytesAvailable === -1 means unlimited storage
  const overStorage = bytesAvailable !== -1 && bytesUsed >= bytesAvailable

  const restrictions = {
    favorites: plan.is_favorites_enabled !== true,
    hosting: plan.is_hosting_enabled !== true,
    imageEditor: plan.is_image_editor_enabled !== true,
    modifyAlbums: plan.is_modify_albums_enabled !== true,
    search: plan.is_search_enabled !== true,
    sharing: plan.is_sharing_enabled !== true,
    sortByTitle: plan.is_sort_by_title_enabled !== true,
    sortByUploadDate: plan.is_sort_by_upload_date_enabled !== true,
    userTags: plan.is_user_tags_enabled !== true,
    videoPlayer: plan.is_video_player_enabled !== true,
    uploadMedia: overMedia || overStorage
  }

  const tooltipTitle = (feature: keyof typeof restrictions) => {
    switch (feature) {
      case 'favorites':
        return "Your plan doesn't include favoriting media."
      case 'imageEditor':
        return "Your plan doesn't include editing images."
      case 'hosting':
        return "Your plan doesn't support hosting images."
      case 'modifyAlbums':
        return "Your plan doesn't support modifying albums."
      case 'search':
        return "Your plan doesn't support searching media."
      case 'sharing':
        return "Your plan doesn't support sharing media."
      case 'sortByTitle':
        return "Your plan doesn't support sorting media by title."
      case 'sortByUploadDate':
        return "Your plan doesn't support sorting media by upload data."
      case 'uploadMedia': {
        const units = overMedia ? 'media' : 'storage'
        return `You have exceeded the amount of ${units} your plan allows.`
      }
      case 'userTags':
        return "Your plan doesn't support adding tags to media."
      case 'videoPlayer':
        return "Your plan doesn't support playing video."
      default:
        return "Your plan doesn't support this feature."
    }
  }

  return { restrictions, plan, isStoragePlan, isSharingPlan, name, tooltipTitle }
}

import { useApolloClient } from '@apollo/client'
import { Box, Popover, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import ScreenShareIcon from '@mui/icons-material/ScreenShareOutlined'
import { BucketAlbumFragmentFragmentDoc } from '../../../../../graphql/generated'

interface Props {
  id: string
  anchorEl: HTMLElement | null
  handleClose(): void
}

export default function AlbumPopover({ id, anchorEl, handleClose }: Props) {
  const client = useApolloClient()
  const currentBucketAlbum = client.readFragment({ id: `BucketAlbum:${id}`, fragment: BucketAlbumFragmentFragmentDoc })
  const theme = useTheme()

  if (!currentBucketAlbum) {
    return null
  }

  return (
    <Popover
      id={`popover-${id}`}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      disableRestoreFocus
      PaperProps={{
        elevation: 2,
        sx: {
          border: `1px solid ${theme.palette.primary.highlight}`
        }
      }}
      sx={{
        pointerEvents: 'none'
      }}
    >
      <div style={{ width: 340 }}>
        <Box sx={{ display: 'flex', backgroundColor: theme.palette.primary.highlight, padding: '10px', justifyContent: 'center' }}>
          {currentBucketAlbum?.hasShares && <ScreenShareIcon />}
          <Typography sx={{ fontWeight: 600, marginLeft: 1, padding: 0 }}>{currentBucketAlbum?.title || 'Loading'}</Typography>
        </Box>
        {/* TODO: currentBucketAlbum images and subalubm counts */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '10px', gap: '10px', justifyContent: 'center' }}>
          <Typography variant="subtitle2" noWrap>
            <strong>Images: </strong>
            {currentBucketAlbum?.counters?.totalMedia || 0} total
          </Typography>
          <Typography variant="subtitle2" noWrap>
            <strong>Albums: </strong> {currentBucketAlbum?.counters?.totalSubalbums || 0} total
          </Typography>
        </Box>
      </div>
    </Popover>
  )
}

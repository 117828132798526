import { useMutation, useQuery } from '@apollo/client'
import { ArrowForward } from '@mui/icons-material'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { Stack, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Architecture,
  Bucket,
  BucketFragmentFragmentDoc,
  BucketMediaByAlbumIdDocument,
  BucketMediaFragmentFragmentDoc,
  BucketMediaMoveCopyBetweenBucketsAction,
  BucketMediaMoveCopyBetweenBucketsDocument,
  BucketType,
  BucketsByUserIdDocument
} from '../../../../../graphql/generated'
import { drawerVar } from '../../../../../providers/apollo/cache'
import logger from '../../../../../utils/logger'
import useAlerts from '../../../hooks/useAlerts'
import useBucketGallerySettings from '../../../hooks/useBucketGallerySettings'
import useSelectedMedia from '../../../hooks/useSelectedMedia'

interface Props {
  bucket: Bucket
  setDestinationBucketId(openDialog: string): void
  selectedBucket: boolean | undefined
}

function BucketButton({ bucket, setDestinationBucketId, selectedBucket }: Readonly<Props>) {
  const theme = useTheme()
  const mediaUsed = Number(bucket?.counters?.totalMedia ?? 0)
  const mediaAvailable = Number(bucket?.plan?.media_limit ?? 0)
  // mediaAvailable === -1 means unlimited media
  const overMedia = mediaAvailable !== -1 && mediaUsed >= mediaAvailable

  const bytesUsed = Number(bucket?.counters?.totalSize ?? 0)
  const bytesAvailable = Number(bucket?.plan?.storage_limit ?? 0)
  // bytesAvailable === -1 means unlimited storage
  const overStorage = bytesAvailable !== -1 && bytesUsed >= bytesAvailable

  const disableBucket = (overMedia || overStorage) ?? false

  const handleClick = (currentBucketId: string) => () => {
    setDestinationBucketId(currentBucketId)
  }

  return (
    <Button
      onClick={handleClick(bucket.id)}
      data-test={`move-to-bucket-${bucket.id}`}
      key={bucket.id}
      disabled={disableBucket}
      sx={{
        gap: '2px',
        minWidth: 0,
        padding: '2px 6px',
        border: `1px solid ${theme.palette.primary.main}`,
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: selectedBucket ? theme.palette.primary.contrastText : theme.palette.secondary.main,
        bgcolor: selectedBucket ? theme.palette.primary.main : 'transparent',
        '&:hover': {
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }
      }}
    >
      {bucket.bucketType === BucketType.Group ? <GroupsOutlinedIcon /> : <PersonOutlineOutlinedIcon />}
      <Box component="span" sx={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {bucket.title}
      </Box>
    </Button>
  )
}

export default function MediaCopyMoveToAnotherBucket() {
  const userId = getAuth().currentUser?.uid
  const { selectedMedia, clearSelectedMedia } = useSelectedMedia()
  const { bucketId } = useParams<{ bucketId: string }>()
  const { settings } = useBucketGallerySettings()
  const { filterBy, sortBy } = settings
  const { createAlert } = useAlerts()
  const [destinationBucketId, setDestinationBucketId] = useState<string>()
  const mediaIds = Array.from(selectedMedia.keys())

  const [mutation] = useMutation(BucketMediaMoveCopyBetweenBucketsDocument, {
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message, 'error', e)
          logger.error(e)
        })
        return
      }
      logger.error(error)
      createAlert('There was a problem copying the selected images to another bucket. Please try again later.')
    },
    update: (cache, { data }) => {
      if (!data?.bucketMediaMoveCopyBetweenBuckets) return

      mediaIds.forEach((id) => {
        // Retrieve current media
        const media = cache.readFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc })
        if (!media) return

        // Add media to destination bucket
        cache.updateQuery(
          { query: BucketMediaByAlbumIdDocument, variables: { bucketId: destinationBucketId as string, filterBy, sortBy } },
          (current) => {
            if (!current?.bucketMediaByAlbumId) return null

            return {
              bucketMediaByAlbumId: {
                ...current.bucketMediaByAlbumId,
                items: [media, ...current.bucketMediaByAlbumId.items]
              }
            }
          }
        )

        // Update the bucket's counters
        cache.updateFragment(
          {
            id: `Bucket:${destinationBucketId as string}`,
            fragment: BucketFragmentFragmentDoc
          },
          (current) => {
            if (!current) return null

            const totalMedia = current?.counters?.totalMedia ?? 0
            const totalMediaMinusTrash = current.counters?.totalMediaMinusTrash ?? 0
            const totalSize = current?.counters?.totalSize ?? 0
            const fileSize = media.fileSize ?? 0

            return {
              ...current,
              counters: {
                ...current.counters,
                totalMedia: totalMedia + 1,
                totalMediaMinusTrash: totalMediaMinusTrash + 1,
                totalSize: totalSize + fileSize
              }
            }
          }
        )
      })
    },
    onCompleted: () => {
      createAlert('Images have been successfully copied.', 'success')
      clearSelectedMedia()
      drawerVar(null)
    }
  })

  const results = useQuery(BucketsByUserIdDocument, {
    skip: !userId,
    variables: { userId: userId as string },
    onError: (e) => {
      logger.error(e)
      createAlert('We are having trouble retrieving your buckets. Please try again later.')
    }
  })

  // Prevent transfers to old architecture buckets
  const buckets = results.data?.bucketsByUserId?.items?.filter((bucket) => bucket.architecture === Architecture.Ddb)

  const handleCopy = () => {
    if (bucketId && destinationBucketId) {
      const variables = {
        bucketId,
        mediaIds,
        data: { action: BucketMediaMoveCopyBetweenBucketsAction.Copy, destinationBucketId }
      }
      mutation({ variables })
    } else {
      createAlert('There was a problem copying the selected images to another bucket. Please try again later.')
    }
  }

  return (
    <>
      <Stack direction="row" gap="6px" alignItems="flex-start" flexWrap="wrap" sx={{ marginTop: '4px', paddingBottom: 1 }}>
        {buckets?.map((bucket) => {
          if (bucketId === bucket.id) {
            return null
          }
          return (
            <BucketButton
              bucket={bucket}
              key={bucket.id}
              setDestinationBucketId={setDestinationBucketId}
              selectedBucket={destinationBucketId === bucket.id}
            />
          )
        })}
      </Stack>
      <Button data-test="move-media-drawer-submit" onClick={handleCopy} variant="outlined" color="primary" fullWidth disabled={!destinationBucketId}>
        Copy to Selected Bucket
        <ArrowForward sx={{ paddingLeft: '5px' }} />
      </Button>
    </>
  )
}

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { drawerVar } from '../../../../../providers/apollo/cache'
import useSelectedMedia from '../../../hooks/useSelectedMedia'

export default function MediaDetails() {
  const { selectedMedia } = useSelectedMedia()

  const toggleDrawer = () => {
    drawerVar('bucketMediaDetails')
  }

  return (
    <IconButton
      aria-label="Media Details"
      color="inherit"
      onClick={toggleDrawer}
      disabled={selectedMedia.size !== 1}
      id="details-button"
      data-test="details-button"
    >
      <Tooltip title="Details" arrow>
        <InfoIcon />
      </Tooltip>
    </IconButton>
  )
}

import { makeVar, useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { BucketMedia } from '../../../graphql/generated'
import logger from '../../../utils/logger'

// Do not export this. This is being temporarily placed here until we can deprecate the other selectedMediaVar
const selectedMediaVar = makeVar<Map<string, boolean>>(new Map())
const selectedModeEnabledMediaVar = makeVar<boolean>(false)

export default function useSelectedMedia() {
  const selectedMedia = useReactiveVar(selectedMediaVar)
  const selectedModeEnabledMedia = useReactiveVar(selectedModeEnabledMediaVar)
  const selectedModeEnabled = selectedModeEnabledMedia || selectedMedia.size > 0

  const selectMedia = (id: string, options?: { replace?: boolean }) => {
    if (options?.replace) {
      selectedMedia.clear()
    }
    selectedMedia.set(id, true)
    selectedMediaVar(new Map(selectedMedia))
  }

  const unSelectMedia = (id: string) => {
    selectedMedia.delete(id)
    selectedMediaVar(new Map(selectedMedia))
  }

  const toggleSelectionMode = () => {
    selectedModeEnabledMediaVar(!selectedModeEnabledMedia)
  }

  const invoke = (id: string, shouldSelect?: boolean) => {
    if (shouldSelect) {
      selectedMedia.set(id, true)
    } else {
      selectedMedia.delete(id)
    }
  }

  const toggleSelectMedia = (id: string) => {
    if (selectedMedia.get(id)) {
      selectedMedia.delete(id)
    } else {
      selectedMedia.set(id, true)
    }

    selectedMediaVar(new Map(selectedMedia))
  }

  const clearSelectedMedia = () => {
    selectedMediaVar(new Map())
    selectedModeEnabledMediaVar(false)
  }

  const toggleBulkSelectMedia = (id: string, items: BucketMedia[]) => {
    const peekValue = Array.from(selectedMedia.keys())[selectedMedia.size - 1]
    const indexOfPeekValue = _.findIndex(items, (i) => i.id === peekValue)
    const indexOfCurrentId = _.findIndex(items, (i) => i.id === id)
    const startIndex = indexOfPeekValue === -1 ? 0 : indexOfPeekValue
    const shouldSelect = !selectedMedia.get(id)
    logger.debug('Bulk: From %s to %s: %s', startIndex, indexOfCurrentId, shouldSelect)
    if (indexOfCurrentId <= startIndex) {
      for (let i: number = startIndex; i >= indexOfCurrentId; i -= 1) {
        logger.debug('Bulk: Selected numbers: %s %s %s', i, items[i], shouldSelect)
        invoke(items[i].id, shouldSelect)
      }
    } else {
      for (let i: number = startIndex; i <= indexOfCurrentId; i += 1) {
        logger.debug('Bulk: Selected numbers: %s %s %s', i, items[i], shouldSelect)
        invoke(items[i].id, shouldSelect)
      }
    }
    selectedMediaVar(new Map(selectedMedia))
    logger.debug('Updated Selected Media: ', JSON.stringify(selectedMedia))
  }

  return {
    clearSelectedMedia,
    selectedMedia,
    selectedModeEnabled,
    selectMedia,
    toggleBulkSelectMedia,
    toggleSelectionMode,
    toggleSelectMedia,
    unSelectMedia
  }
}

import { S3Client, S3ClientConfig } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'
import exifr from 'exifr'
import { getAuth } from 'firebase/auth'
import heic2any from 'heic2any'
import { useMatch, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import { baseDomain, s3Media } from '../../../config'
import { BucketCredentials } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import { videoPlaceholder } from '../utils/placeholders'
import { EXIFData, MediaData } from '../utils/sharedTypes'
import useAlerts from './useAlerts'
import useBucketCredentials from './useBucketCredentials'
import useBucketMediaCreate from './useBucketMediaCreate'
import useBucketPlan from './useBucketPlan'
import useUploadsInProgress from './useUploadsInProgress'
import useGAEvent from '../../auth/hooks/useGAEvent'
import useBucketMigrationProgress from './useBucketMigrationProgress'
import useBucketConvertProgress from './useBucketConvertProgress'

export default function useS3MulitpartUpload() {
  const userId = getAuth().currentUser?.uid
  const params = useParams<{ bucketId: string; albumId: string }>()
  const path = useMatch('/bucket/:bucketId/album/:albumId')
  const { restrictions, tooltipTitle } = useBucketPlan()
  const { createAlert } = useAlerts()
  const { mutation } = useBucketMediaCreate()
  const { credentials, query } = useBucketCredentials()
  const { beginTrackingProgress, updateStatus } = useUploadsInProgress()
  const bucketId = params.bucketId ?? path?.params.bucketId
  const albumId = params.albumId ?? path?.params.albumId

  const isMigrationInProgressForBucket = useBucketMigrationProgress()
  const isBucketConvertInProgressForBucket = useBucketConvertProgress()

  const restricted = restrictions.uploadMedia

  const { trackEvent } = useGAEvent()

  const fetchExif = async (tempUrl: string, isHeic: boolean) => {
    const exifData = await exifr.parse(tempUrl)
    const { ExifImageHeight, ExifImageWidth, CreateDate } = exifData || {}

    // Format date from EXIF or for now use current date as a default value
    const dateTaken = CreateDate ? new Date(CreateDate).toISOString() : new Date().toISOString()

    // If height and width is available from EXIF, use those and skip loading the image below
    if (ExifImageWidth && ExifImageHeight) {
      const data: EXIFData = { width: ExifImageWidth, height: ExifImageHeight, dateTaken }
      return data
    }

    // Fetch image blob
    const res = await fetch(tempUrl)
    let blob = await res.blob()

    // Because HEIC is not supported by any browsers (https://caniuse.com/?search=heic)
    // the image must first be converted to a jpeg before it's height and width can be extracted
    if (isHeic) {
      const heicToJpeg = await heic2any({ blob, toType: 'image/jpeg', quality: 0.5 })
      blob = heicToJpeg as Blob
    }

    const img = new Image()
    img.src = URL.createObjectURL(blob)

    // Promisify the onload callback function provided by the Image class
    const promise = new Promise<EXIFData>((resolve, reject) => {
      img.onerror = reject
      img.onload = () => {
        const data: EXIFData = {
          dateTaken,
          height: img.naturalHeight ?? 1,
          width: img.naturalWidth ?? 1
        }
        resolve(data)
      }
    })

    return promise
  }

  const upload = async (file: File) => {
    if (!file) {
      throw new Error('No file received')
    }

    if (!bucketId) {
      throw new Error('Unable to determine bucket id')
    }

    if (!userId) {
      throw new Error('User must login to upload media')
    }

    if (isMigrationInProgressForBucket || isBucketConvertInProgressForBucket) {
      createAlert('Media upload is disabled during the migration process')
      throw new Error('Media upload is disabled during the migration process')
    }

    let currentCredentials: BucketCredentials | null | undefined = credentials

    if (!currentCredentials) {
      // Fetch credentials if they are not already available
      const results = await query({ variables: { bucketId, userId } })
      currentCredentials = results.data?.bucketCredentials
    }

    if (!currentCredentials) {
      // If credentials are still not available, throw an error
      throw new Error('Unable to retrieve credentials')
    }

    // Begin multipart upload to S3
    const mediaId = v4()
    const mediaType = file.type
    const originalFilename = file.name
    const fileSize = file.size
    const fileExt = originalFilename.split('.').pop()?.toLowerCase()
    const filename = `${mediaId}.${fileExt}`
    const isBanned = false
    const isVideo = mediaType.toLowerCase().includes('video')
    const isHeic = fileExt === 'heic' || fileExt === 'heif'
    const imageUrl = isVideo ? `${baseDomain}/${videoPlaceholder}` : URL.createObjectURL(file)
    const splitName = originalFilename.split('.')
    splitName.pop()
    const title = splitName.join('.')
    // gif and webp do not support exif
    const skipFetchExif = ['gif', 'webp'].includes(fileExt ?? '') || isVideo

    let data: MediaData = {
      albumId,
      createdAt: new Date().toISOString(),
      dateTaken: new Date().toISOString(),
      description: null,
      filename,
      fileSize,
      imageUrl,
      isBanned,
      isVideo,
      mediaType,
      originalFilename,
      scheduledDeletionAt: null,
      title,
      userId,
      userTags: null
    }

    beginTrackingProgress(bucketId, mediaId, data)

    if (!skipFetchExif) {
      const { dateTaken, height, width } = await fetchExif(imageUrl, isHeic)
      data = {
        ...data,
        dateTaken,
        height,
        width
      }
    }

    const configuration: S3ClientConfig = {
      region: s3Media.region,
      credentials: currentCredentials,
      useAccelerateEndpoint: true
    }
    const client = new S3Client(configuration)

    const parallelUploads = new Upload({
      client,
      params: {
        Bucket: s3Media.bucket,
        Key: `${bucketId}/${filename}`,
        Body: file
      },
      queueSize: 4,
      partSize: 1024 * 1024 * 5,
      leavePartsOnError: false
    })

    parallelUploads.on('httpUploadProgress', (progress) => {
      const loaded = progress.loaded ?? 0
      const total = progress.total ?? 0
      const percent = Math.round((loaded / total) * 100)
      updateStatus(mediaId, percent)
      logger.info(progress)
    })

    await parallelUploads.done()

    delete data.createdAt
    delete data.filename
    delete data.imageUrl
    delete data.isBanned
    delete data.isVideo
    delete data.scheduledDeletionAt
    delete data.userId

    return { mediaId, filename, data }
  }

  const uploadSingle = async (file: File) => {
    if (restricted) {
      createAlert('Your plan has exceeded maximum storage.')
      return 'UPLOAD_FAILED'
    }

    if (!bucketId) {
      throw new Error('Unable to determine bucket id')
    }

    trackEvent('media_upload_event', {
      metric: 'media_upload_event',
      id: userId,
      bucketId,
      albumId,
      isMultiple: false,
      platform: 'WEB'
    })

    const { mediaId, filename, data } = await upload(file)

    mutation({
      variables: { bucketId, mediaId, filename, data },
      onCompleted: () => {
        updateStatus(mediaId, 'COMPLETE')
      },
      onError: () => {
        updateStatus(mediaId, 'ERROR')
      }
    })

    logger.info(`Single Upload`, new Date().toISOString())
    return mediaId
  }

  const batchUpload = async (files: File[], num: number, total: number) => {
    if (restricted) {
      const message = tooltipTitle('uploadMedia')
      createAlert(message)
      return
    }

    if (!bucketId) {
      throw new Error('Unable to determine bucket id')
    }

    trackEvent('media_upload_event', {
      metric: 'media_upload_event',
      id: userId,
      bucketId,
      albumId,
      filesCount: files.length,
      isMultiple: true,
      platform: 'WEB'
    })

    const results = Array.from(files).map(async (file) => {
      const { mediaId, filename, data } = await upload(file)

      mutation({
        variables: { bucketId, mediaId, filename, data },
        onCompleted: () => {
          updateStatus(mediaId, 'COMPLETE')
        },
        onError: () => {
          updateStatus(mediaId, 'ERROR')
        }
      })
    })

    await Promise.all(results)
    logger.info(`Batch ${num} of ${total}`, new Date().toISOString())
  }

  return { batchUpload, uploadSingle }
}

import { useApolloClient } from '@apollo/client'
import { BucketMedia, BucketMediaFragmentFragmentDoc } from '../../../../../../graphql/generated'
import useSelectedMedia from '../../../../hooks/useSelectedMedia'
import { generateDirectLink, generateHtmlCode, generateIframeCode, generateImageCode, generateShareLink } from '../../../../utils/urlUtils'

interface Size {
  width: number
  height: number
}

interface UseShareLinkBuilder {
  [key: string]: string
}

export default function useShareLinkBuilder(mediaSize: Size) {
  const { selectedMedia } = useSelectedMedia()
  const client = useApolloClient()
  const mediaIds = Array.from(selectedMedia.keys())
  const bucketMedia: BucketMedia[] = []

  mediaIds.forEach((id) => {
    const media = client.readFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc }) as BucketMedia
    if (media) {
      bucketMedia.push(media)
    }
  })

  const sharingLinks: UseShareLinkBuilder[] = []

  // Generate Media Share Links
  bucketMedia.forEach((media) => {
    const links = {
      direct: generateDirectLink(media.imageUrl ?? ''),
      iframe: generateIframeCode(media.imageUrl ?? '', mediaSize),
      html: generateHtmlCode(media.imageUrl ?? '', media.title ?? '', media.imageUrl ?? '', mediaSize),
      img: generateImageCode(media.imageUrl ?? '', mediaSize),
      imageShare: generateShareLink(media.bucketId ?? '', media.id)
    }
    sharingLinks.push(links)
  })

  return {
    sharingLinks
  }
}

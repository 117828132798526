import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { PropsWithChildren } from 'react'
import config from '../../config'
import logger from '../../utils/logger'
import authLink from './AuthLink'
import cache from './cache'

/**
 * Handles Errors recieved from response
 * @param errorResponse Response from request containing errors
 */

function errorHandler(errorResponse: ErrorResponse) {
  if (errorResponse.graphQLErrors) {
    errorResponse.graphQLErrors.forEach((e) => {
      const name = e?.extensions?.code || 'Unknown'
      const { pathname } = window.location
      // if redirect is '/auth/login?redirect=/auth/login' an infinite rerender cycle is created
      if (name === 'UnauthorizedError' && !pathname?.startsWith('/auth/login')) {
        window.location.href = `/auth/login?redirect=${pathname}`
      }
    })
  }

  if (errorResponse.networkError) {
    logger.error(`[Network error]: ${errorResponse.networkError}`)
  }
}

// Round trip interceptor
const roundTripLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  operation.setContext({ start: new Date() })

  return forward(operation).map((data) => {
    // Called after server responds
    const currDate: any = new Date()
    const time = (currDate - operation.getContext().start) as unknown as Date
    logger.debug(`Operation ${operation.operationName} took ${time}ms to complete`)
    return data
  })
})

export default function Provider(props: PropsWithChildren<Record<string, unknown>>) {
  const httpLink = createHttpLink({ uri: config.api.graphql })
  const errorLink = onError(errorHandler)
  // retries for 3 times with a delay of 0.2-0.5s
  const link = new RetryLink({ delay: { initial: 200, max: 500, jitter: true }, attempts: { max: 3 } })
    .concat(authLink)
    .concat(roundTripLink)
    .concat(errorLink)
    .concat(httpLink)
  const client = new ApolloClient({ name: config.app.name, version: config.app.version, cache, link, connectToDevTools: true })
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

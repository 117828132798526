import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useParams } from 'react-router-dom'
import { activeAlbumIdVar, drawerVar } from '../../../../../providers/apollo/cache'
import useSelectedAlbums from '../../../hooks/useSelectedAlbums'

export default function AlbumDetails() {
  const { bucketId, albumId } = useParams<{ bucketId: string; albumId: string }>()
  const { selectedAlbums } = useSelectedAlbums()
  const containRootAlbum = Array.from(selectedAlbums.keys()).includes(bucketId as string)

  const toggleDrawer = () => {
    drawerVar('bucketAlbumDetails')
    activeAlbumIdVar(albumId)
  }

  return (
    <IconButton
      aria-label="Album Details"
      color="inherit"
      onClick={toggleDrawer}
      disabled={containRootAlbum || !(albumId || selectedAlbums.size === 1)}
      id="details-button"
      data-test="details-button"
    >
      <Tooltip title="Album Details" arrow>
        <InfoIcon />
      </Tooltip>
    </IconButton>
  )
}

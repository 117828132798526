import { useApolloClient } from '@apollo/client'
import { BucketMedia, BucketMediaByAlbumIdDocument, BucketMediaByAlbumIdQueryVariables } from '../../../graphql/generated'
import useBucketGallerySettings from './useBucketGallerySettings'

export default function useBucketMediaFavoriteCacheUpdate() {
  const client = useApolloClient()
  const { settings } = useBucketGallerySettings()
  const { filterBy, sortBy } = settings

  const updateCache = (media: BucketMedia) => {
    // Update the "All Media" query (No albumId) where isFavorite is true
    // If this query has not been made then nothing will happen here

    // Define the variables to update the cache for queries where isFavorite is true
    const variables: BucketMediaByAlbumIdQueryVariables = {
      bucketId: media.bucketId,
      filterBy: {
        ...filterBy,
        isFavorite: true
      },
      sortBy
    }

    client.cache.updateQuery(
      {
        query: BucketMediaByAlbumIdDocument,
        variables
      },
      (cachedData) => {
        if (!cachedData) return null
        const { bucketMediaByAlbumId } = cachedData

        let items = bucketMediaByAlbumId?.items ?? []

        if (media.isFavorite) {
          items = [...items, media]
        } else {
          items = items.filter((current) => current.id !== media.id)
        }

        return {
          bucketMediaByAlbumId: {
            ...bucketMediaByAlbumId,
            items
          }
        }
      }
    )

    // Update the album query (albumId defined) where isFavorite is true
    // If this query has not been made then nothing will happen here
    client.cache.updateQuery(
      {
        query: BucketMediaByAlbumIdDocument,
        variables: {
          ...variables,
          albumId: media.albumId
        }
      },
      (cachedData) => {
        if (!cachedData) return null
        const { bucketMediaByAlbumId } = cachedData

        let items = bucketMediaByAlbumId?.items ?? []

        if (media.isFavorite) {
          items = [...items, media]
        } else {
          items = items.filter((current) => current.id !== media.id)
        }

        return {
          bucketMediaByAlbumId: {
            ...bucketMediaByAlbumId,
            items
          }
        }
      }
    )
  }

  return { updateCache }
}

import { useReactiveVar } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Dialog, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { modalVar } from '../../../../providers/apollo/cache'
import useBucket from '../../hooks/useBucket'
import useBucketTrashEmpty from '../../hooks/useBucketTrashEmpty'
import useSelectedMedia from '../../hooks/useSelectedMedia'

export default function MediaDeletePermanently() {
  const { clearSelectedMedia } = useSelectedMedia()
  const modal = useReactiveVar(modalVar)
  const { bucket } = useBucket()
  const { mutation } = useBucketTrashEmpty()
  const theme = useTheme()
  const totalMediaInTrash = bucket?.counters?.totalMediaInTrash || 0
  const noun = totalMediaInTrash === 1 ? 'image' : 'images'

  const handleClose = () => {
    modalVar(null)
  }

  const handleSubmit = () => {
    if (!bucket?.id) return

    mutation({
      variables: { bucketId: bucket.id },
      onCompleted: () => {
        modalVar(null)
        clearSelectedMedia()
      }
    })
  }

  return (
    <Dialog data-test="delete-modal" open={modal === 'bucketTrashEmpty'} onClose={handleClose} maxWidth="sm" fullWidth>
      <CloseIcon sx={{ position: 'absolute', cursor: 'pointer', right: 10, top: 10 }} onClick={handleClose} data-test="close-modal" />
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '80px',
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            padding: '20px'
          }
        }}
      >
        <div style={{ background: 'rgba(6, 114, 203, 0.08)', borderRadius: '50%', width: '64px', height: '64px', textAlign: 'center' }}>
          <DeleteIcon color="primary" style={{ width: '30px', height: '100%' }} />
        </div>
        <Typography sx={{ fontWeight: 600, fontSize: 28, lineHeight: '32px', color: '#081230' }}>
          Are you sure you want to empty the trash?
        </Typography>
        <Typography sx={{ color: '#2F364E', lineHeight: '24px', marginTop: '24px', marginBottom: '48px', maxWidth: '80%' }}>
          You are about to delete {totalMediaInTrash} {noun}.
        </Typography>
        <DialogActions
          sx={{
            width: '100%',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            },
            '& button': {
              width: '50%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                margin: '4px 0'
              }
            }
          }}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            sx={{ backgroundColor: theme.palette.warning.main }}
            onClick={handleSubmit}
            data-testid="buckets-empty-trash-modal-confirm"
          >
            Delete {noun}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

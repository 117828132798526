import { useApolloClient, useQuery } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import { BucketAndAlbumsAndUserDocument, UserProfileFragmentFragmentDoc } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

/**
 * Hook accepting an optional albumId as we do NOT always want to fetch the bucketAlbums by albumId on all views
 * @param albumId
 * @returns
 */

export default function useBucket(albumId?: string) {
  const { createAlert } = useAlerts()
  const client = useApolloClient()
  const { bucketId } = useParams<{ bucketId: string }>()
  const userId = getAuth().currentUser?.uid
  const userProfile = client.cache.readFragment({
    id: `UserProfile:${userId}`,
    fragment: UserProfileFragmentFragmentDoc,
    fragmentName: 'UserProfileFragment'
  })

  const userBucketId = bucketId ?? userProfile?.currentBucketId
  const results = useQuery(BucketAndAlbumsAndUserDocument, {
    skip: !userBucketId || !userId || userBucketId === userId,
    variables: { bucketId: userBucketId!, albumId, userId: userId! },
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message, 'error', e)
        })
        return
      }

      createAlert('There was a problem retrieving your bucket details. Please try again later.')
      logger.error(error)
    }
  })

  const bucket = results.data?.bucket
  const bucketAlbums = results.data?.bucketAlbums
  const bucketUser = results.data?.bucketUser
  const nextToken = results.data?.bucketAlbums?.nextToken
  const loading = !results.called || results.loading
  const isDefaultTitle = bucket?.title?.toLowerCase() === 'my bucket'
  const loadingMore = results.loading
  const currentUserIsOwner = userId && bucket && userId === bucket?.ownerId

  const func = () => {
    if (nextToken) {
      results.fetchMore({ variables: { nextToken } })
    }
  }
  const fetchMore = debounce(func, 500, { leading: true })

  return { bucket, bucketAlbums, bucketUser, isDefaultTitle, loading, loadingMore, fetchMore, nextToken, currentUserIsOwner }
}

import { useMutation } from '@apollo/client'
import { BucketUpdateDocument } from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'

export default function useUpdateBucketTitle() {
  const { createAlert } = useAlerts()

  const [mutation, results] = useMutation(BucketUpdateDocument, {
    onError: (error) => {
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((e) => {
          createAlert(e.message, 'error', e)
          logger.error(e)
        })
        return
      }
      logger.error(error)
      createAlert('There was a problem changing email. Please try again later.')
    }
  })

  const { loading } = results

  return { loading, mutation, results }
}

import config from '../../../config'

export interface Size {
  width: number
  height: number
}

const DEFAULT_IMAGE_WIDTH = 1920 // Large Image Size
const DEFAULT_IMAGE_HEIGHT = 1080 // Large Image Size

const generateImageCode = (url: string, size?: Size) =>
  `[URL=${url}${size ? `?width=${size?.width || DEFAULT_IMAGE_WIDTH}&height=${size?.height || DEFAULT_IMAGE_HEIGHT}&fit=bounds` : ''}][IMG]${url}${
    size ? `?width=${size?.width}&height=${size?.height}&fit=bounds` : ''
  }[/IMG][/URL]`
const generateImageThumbCode = (url: string) => generateImageCode(url, { width: 180, height: 180 })
const generateImageUrl = (url: string, title: string, size?: Size) =>
  `<img src="${url}${size ? `?width=${size?.width}&height=${size?.height}&fit=bounds` : ''}" alt="${title}"/>`
const generateAnchor = (url: string, element: string, size?: Size) =>
  `<a href="${url}${size ? `?width=${size?.width}&height=${size?.height}&fit=bounds` : ''}" target="_blank">${element}</a>`
const generateEmailCode = (url: string, title: string, email: string) => generateAnchor(`mailto:${email}`, generateImageUrl(url, title))
const generateHtmlCode = (imageUrl: string, title: string, anchorUrl?: string, size?: Size) =>
  anchorUrl ? generateAnchor(anchorUrl, generateImageUrl(imageUrl, title, size), size) : generateImageUrl(imageUrl, title, size)
const generateHtmlThumbCode = (url: string, title: string) => generateHtmlCode(url, title, undefined, { width: 180, height: 180 })
const generateIframeCode = (url: string, mediaSize: Size) => `<iframe width="${mediaSize.width}" height="${mediaSize.height}" src="${url}"></iframe>`
const generateShareLink = (bucketId: string, mediaId: string) => `${config.appUrl.legacy}/bucket/${bucketId}/media/${mediaId}`
const cutQueryString = (url: string) => url?.split('?')[0] || ''
const generateDirectLink = (url: string) => cutQueryString(url)
const joinLinks = (firstLink: string, secondLink: string) => [firstLink, secondLink].filter((s) => !!s).join('\n')

export const getDirectAlbumLink = (albumId: string, username: string) => encodeURI(`${config.appUrl.legacy}/u/${username}/a/${albumId}`)

const srcParseParams = (src: string) => {
  const params: Record<string, string> = {}
  const splitData = src.split('?')

  if (splitData.length === 1) {
    return params
  }

  const vars = splitData[1].split('&')
  vars.forEach((item) => {
    const pair = item.split('=')
    params[pair[0]] = decodeURIComponent(pair[1])
  })
  return params
}

export {
  generateHtmlCode,
  generateHtmlThumbCode,
  generateImageCode,
  generateImageThumbCode,
  generateEmailCode,
  generateIframeCode,
  generateDirectLink,
  generateShareLink,
  joinLinks,
  srcParseParams
}

import { Typography } from '@mui/material'

interface Props {
  newEmail: string
}

export default function Success({ newEmail }: Props) {
  return (
    <div>
      <Typography variant="h6">Success!</Typography>
      <Typography>Your email has been updated to {newEmail}</Typography>
      <Typography>Check your email to confirm your address.</Typography>
    </div>
  )
}

export default function formatBytes(bytes: number | undefined, decimals = 2) {
  if (bytes === undefined) return ''

  if (bytes === 0) {
    return '0 Bytes'
  }

  const b = Number(bytes)
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const i = Math.floor(Math.log(b) / Math.log(k))
  return `${parseFloat((b / k ** i).toFixed(dm))} ${sizes[i]}`
}

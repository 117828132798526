import { useReactiveVar } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Dialog, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import {
  BucketFragmentFragmentDoc,
  BucketMediaByAlbumIdDocument,
  BucketMediaFragmentFragmentDoc,
  BucketMediaStatus
} from '../../../../graphql/generated'
import { modalVar } from '../../../../providers/apollo/cache'
import useBucketMediaMove from '../../hooks/useBucketMediaMove'
import useSelectedMedia from '../../hooks/useSelectedMedia'

export default function MediaDeletePermanently() {
  const { bucketId } = useParams<{ bucketId: string }>()
  const modal = useReactiveVar(modalVar)
  const { selectedMedia, clearSelectedMedia } = useSelectedMedia()
  const mediaIds = Array.from(selectedMedia.keys())
  const { mutation } = useBucketMediaMove()
  const theme = useTheme()
  const noun = selectedMedia.size === 1 ? 'image' : 'images'

  const handleClose = () => {
    modalVar(null)
  }

  const handleSubmit = () => {
    if (!bucketId || !mediaIds.length) return

    mutation({
      variables: { bucketId, mediaIds, data: { deleteMedia: true } },
      update: (cache, { data }) => {
        if (!data?.bucketMediaMove) return
        data.bucketMediaMove?.forEach((id) => {
          // Retrieve current media
          const media = cache.readFragment({ id: `BucketMedia:${id}`, fragment: BucketMediaFragmentFragmentDoc })

          // Modify bucket media count
          cache.updateFragment(
            {
              id: `Bucket:${bucketId}`,
              fragment: BucketFragmentFragmentDoc
            },
            (current) => {
              if (!current) return null

              const totalMediaInTrash = current?.counters?.totalMediaInTrash ?? 0
              const fileSize = media?.fileSize ?? 0
              const totalSizeInTrash = current?.counters?.totalSizeInTrash ?? 0
              const totalMedia = current?.counters?.totalMedia ?? 0
              const totalSize = current?.counters?.totalSize ?? 0

              return {
                ...current,
                counters: {
                  ...current.counters,
                  totalMediaInTrash: Math.max(totalMediaInTrash - 1, 0),
                  totalSizeInTrash: Math.max(totalSizeInTrash - fileSize, 0),
                  totalMedia: Math.max(totalMedia - 1, 0),
                  totalSize: Math.max(totalSize - fileSize, 0)
                }
              }
            }
          )

          // Remove media from album
          cache.updateQuery(
            {
              query: BucketMediaByAlbumIdDocument,
              variables: {
                bucketId,
                filterBy: { status: BucketMediaStatus.Trash },
                sortBy: null
              }
            },
            (cachedData) => {
              if (!media || !cachedData) return null

              const { bucketMediaByAlbumId } = cachedData
              const currentItems = bucketMediaByAlbumId?.items || []
              const items = currentItems.filter((item) => item.id !== media.id)

              return {
                bucketMediaByAlbumId: {
                  ...bucketMediaByAlbumId,
                  items
                }
              }
            }
          )
        })
        cache.gc()
      },
      onCompleted: () => {
        modalVar(null)
        clearSelectedMedia()
      }
    })
  }

  return (
    <Dialog data-test="delete-modal" open={modal === 'bucketMediaDeletePermanently'} onClose={handleClose} maxWidth="sm" fullWidth disableScrollLock>
      <CloseIcon sx={{ position: 'absolute', cursor: 'pointer', right: 10, top: 10 }} onClick={handleClose} data-test="close-modal" />
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '80px',
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            padding: '20px'
          }
        }}
      >
        <div style={{ background: 'rgba(6, 114, 203, 0.08)', borderRadius: '50%', width: '64px', height: '64px', textAlign: 'center' }}>
          <DeleteIcon color="primary" style={{ width: '30px', height: '100%' }} />
        </div>
        <Typography sx={{ fontWeight: 600, fontSize: 28, lineHeight: '32px', color: '#081230' }}>
          Are you sure you want to delete these forever?
        </Typography>
        <Typography sx={{ color: '#2F364E', lineHeight: '24px', marginTop: '24px', marginBottom: '48px', maxWidth: '80%' }}>
          You are about to delete {selectedMedia.size} {noun}.
        </Typography>
        <DialogActions
          sx={{
            width: '100%',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            },
            '& button': {
              width: '50%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                margin: '4px 0'
              }
            }
          }}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            sx={{ backgroundColor: theme.palette.warning.main }}
            onClick={handleSubmit}
            data-test="bucket-permanently-delete-modal-button"
          >
            Delete {noun}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

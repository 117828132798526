import { useMutation } from '@apollo/client'
import {
  BucketAlbum,
  BucketAlbumCreateDocument,
  BucketAlbumFragmentFragmentDoc,
  BucketAlbumsDocument,
  BucketAlbumsQueryVariables
} from '../../../graphql/generated'
import logger from '../../../utils/logger'
import useAlerts from './useAlerts'
import useBucket from './useBucket'

const compareFn = (a: BucketAlbum, b: BucketAlbum) => {
  if (a.title.toLowerCase() > b.title.toLowerCase()) {
    return 1
  }

  if (a.title.toLowerCase() < b.title.toLowerCase()) {
    return -1
  }

  return 0
}

export default function useBucketAlbumCreate() {
  const { bucketUser } = useBucket()
  const { createAlert } = useAlerts()
  const disabled = !bucketUser?.permissions?.albumCreate

  const [mutation, results] = useMutation(BucketAlbumCreateDocument, {
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('There was a problem creating your album. Please try again later.')
      }
    },
    update: (cache, { data }) => {
      if (!data) return
      const album = data.bucketAlbumCreate as BucketAlbum
      const { bucketId, parentId } = album
      const variables: BucketAlbumsQueryVariables = { bucketId }

      if (bucketId !== parentId) {
        variables.albumId = parentId
      }

      cache.updateQuery({ query: BucketAlbumsDocument, variables }, (current) => {
        // If the query does not exist
        if (!current) return null

        // If we get here then the query must exist in cache and we will update it with the new album
        const items = [album, ...(current.bucketAlbums?.items || [])]
        items.sort(compareFn)

        return {
          bucketAlbums: {
            ...current.bucketAlbums,
            items
          }
        }
      })

      if (bucketId === parentId) {
        return
      }

      // Update the Bucket Album's counters
      cache.updateFragment({ id: `BucketAlbum:${parentId}`, fragment: BucketAlbumFragmentFragmentDoc }, (current) => {
        if (!current) return null

        const totalSubAlbums = current?.counters?.totalSubalbums ?? 0

        return {
          ...current,
          subAlbumCount: current.subAlbumCount != null ? current.subAlbumCount + 1 : 1,
          counters: {
            ...current.counters,
            totalSubalbums: totalSubAlbums + 1
          }
        }
      })
    },
    onCompleted: () => {
      createAlert('A new album has been created', 'success')
    }
  })

  return { disabled, mutation, results }
}

import { useLazyQuery } from '@apollo/client'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { Box, IconButton, useTheme, LinearProgress } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useState } from 'react'
import { BucketAlbum, BucketAlbumsDocument } from '../../../../../graphql/generated'
import logger from '../../../../../utils/logger'
import useAlerts from '../../../hooks/useAlerts'

interface Props {
  album: BucketAlbum
  allItems?: boolean
  setAllItems?: (allItems: boolean) => void
  selectedAlbumId: string | null
  setSelectedAlbumId: (albumId: string | null) => void
  disabled?: boolean
  mediaMoveDisabled?: boolean
  albumIds?: string[]
}

export default function SelectAlbum({
  album,
  allItems,
  setAllItems,
  selectedAlbumId,
  setSelectedAlbumId,
  disabled,
  mediaMoveDisabled,
  albumIds
}: Props) {
  const theme = useTheme()
  const { createAlert } = useAlerts()
  const [openSubAlbums, setOpenSubAlbums] = useState(false)
  const [execute, results] = useLazyQuery(BucketAlbumsDocument, {
    variables: { bucketId: album.bucketId, albumId: album.id },
    onError: (e) => {
      e.graphQLErrors.forEach((err) => {
        createAlert(err.message)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('We are having trouble retrieving your albums. Please try again later.')
      }
    }
  })
  const subalbums = results.data?.bucketAlbums?.items || []
  const selected = selectedAlbumId === album.id

  const handleClick = () => {
    if (allItems && setAllItems) {
      setAllItems(!allItems)
    }

    if (selectedAlbumId === album.id) {
      setSelectedAlbumId(null)
      return
    }

    setSelectedAlbumId(album.id)
  }

  const handleToggle = () => {
    if (!openSubAlbums && album.subAlbumCount) {
      execute()
    }

    setOpenSubAlbums(!openSubAlbums)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '5px',
          paddingRight: '5px',
          backgroundColor: selected ? theme.palette.primary.main : theme.palette.primary.highlight,
          borderRadius: selected ? '4px' : 0
        }}
      >
        {!!album.subAlbumCount && (
          <IconButton
            onClick={handleToggle}
            size="small"
            sx={{
              color: selected ? theme.palette.primary.highlight : theme.palette.primary.main,
              height: '20px',
              width: '20px',
              '&:hover': { color: theme.palette.secondary.main, backgroundColor: theme.palette.primary.highlight }
            }}
          >
            {openSubAlbums ? <ArrowDropDown fontSize="small" /> : <ArrowRight fontSize="small" />}
          </IconButton>
        )}
        <ListItemButton
          data-test="select-album"
          divider
          sx={{
            paddingLeft: '5px',
            paddingRight: 0,
            border: 'none',
            '&:hover': {
              background: 'transparent',
              color: theme.palette.primary.main
            },
            '&.Mui-disabled': {
              opacity: 1.0,
              cursor: 'not-allowed',
              pointerEvents: 'auto',
              '&:hover': {
                cursor: 'not-allowed'
              }
            },
            '&.Mui-disabled span': {
              fontWeight: 700,
              color: theme.palette.secondary.main
            }
          }}
          disableRipple
          disabled={disabled || mediaMoveDisabled}
        >
          <ListItemText
            sx={{ color: selected ? '#fff' : 'inherit' }}
            primaryTypographyProps={{
              sx: { fontWeight: selected ? 'bold' : 'normal', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
            }}
            onClick={handleClick}
            primary={album.title}
          />
        </ListItemButton>
      </Box>
      <Collapse in={openSubAlbums} timeout="auto" unmountOnExit>
        {results.loading && <LinearProgress />}
        {subalbums.map((subalbum) => (
          <Box key={subalbum.id} sx={{ paddingLeft: '10px', backgroundColor: theme.palette.primary.highlight }}>
            <SelectAlbum
              album={subalbum}
              selectedAlbumId={selectedAlbumId}
              setSelectedAlbumId={setSelectedAlbumId}
              disabled={disabled || albumIds?.includes(subalbum.id)}
            />
          </Box>
        ))}
      </Collapse>
    </>
  )
}

SelectAlbum.defaultProps = {
  allItems: false,
  setAllItems: () => {},
  disabled: false,
  mediaMoveDisabled: false,
  albumIds: []
}

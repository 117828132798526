import { Box, Button, Card, CardActions, Grid, styled, Typography } from '@mui/material'
import ExternalLink from '@mui/material/Link'
import { useFlag } from '@unleash/proxy-client-react'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import config, { environment } from '../../../../../config'
import useGAEvent from '../../../../auth/hooks/useGAEvent'
import FeatureList from './FeatureList'

const StyledGrid = styled(Grid)(() => ({
  justifyContent: 'center',
  marginTop: '10px'
}))

const StyledCard = styled(Card)(() => ({
  position: 'relative',
  borderRadius: '30px',
  marginTop: '20px',
  textAlign: 'center',
  border: '1px solid #DADADA',
  boxShadow: '0px 5.59441px 151.049px rgba(227, 227, 227, 0.25)',
  flex: 1,
  overflow: 'visible'
}))

const CardHead = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: 1,
  marginBottom: '10px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.75rem',
    marginBottom: '5px',
    marginTop: '75px'
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: '80px'
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '80px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.75rem'
  }
}))

const CardStorage = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1,
  textTransform: 'none',
  [theme.breakpoints.up('sm')]: {
    padding: '0 10px',
    fontSize: '0.75rem',
    lineHeight: 1.25
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem'
  }
}))

const CardPrice = styled(Typography)(({ theme }) => ({
  color: '#0b0b0b',
  fontSize: '5.5rem',
  fontWeight: 600,
  lineHeight: 1,
  marginTop: '20px',
  marginBottom: '10px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '4.25rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4.75rem'
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '25px',
    fontSize: '5.5rem'
  },
  [theme.breakpoints.up('xl')]: {
    marginTop: '30px',
    fontSize: '7.5rem'
  }
}))

const CardTerm = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1,
  margin: '5px 0 0',
  marginBottom: '0',
  [theme.breakpoints.up('lg')]: {
    margin: '10px 0',
    fontSize: '1.25rem'
  },
  [theme.breakpoints.up('xl')]: {
    margin: '10px 0 20px',
    fontSize: '1.5rem'
  }
}))

const PaddingActions = styled(CardActions)(({ theme }) => ({
  padding: '20px 16px',
  [theme.breakpoints.up('sm')]: {
    padding: '20px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '20px 20px'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '20px 35px'
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  borderRadius: '86px',
  color: theme.palette.primary.contrastText,
  fontSize: '1rem',
  fontWeight: 500,
  textTransform: 'none',
  margin: '0 auto',
  padding: '8px 20px',
  width: '80%',
  '&:hover': {
    background: 'linear-gradient(175deg, #02E39F 5.3%, #202CD3 102.34%)',
    color: theme.palette.primary.contrastText
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
    padding: '8px 15px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    padding: '5px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '5px 5px',
    fontSize: '1.25rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '10px 20px',
    fontSize: '1.5rem'
  }
}))

const CurrentPlan = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: 1.75,
  color: theme.palette.primary.contrastText,
  background: '#658CBB',
  borderRadius: '86px',
  fontWeight: 500,
  margin: '0 auto',
  padding: '8px 20px',
  width: '80%',
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
    padding: '8px 15px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    padding: '5px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '5px',
    fontSize: '1.275rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '10px 20px',
    fontSize: '1.5rem'
  }
}))

const CardSavings = styled(Typography)(({ theme }) => ({
  marginBottom: 0,
  marginTop: 0,
  padding: '18px 16px 20px',
  color: '#2b2b2b',
  fontSize: '0.875rem',
  fontWeight: 300,
  lineHeight: 1.25,
  '& span': {
    fontWeight: '600',
    color: theme.palette.primary.main
  },
  [theme.breakpoints.up('lg')]: {
    padding: '20px 20px',
    fontSize: '1.125rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '40px 50px 40px',
    fontSize: '1.5rem'
  }
}))

const CurrentPlanSelected = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  color: 'black',
  background: '#EFEFFF',
  borderRadius: '10px',
  border: '1px solid blue',
  fontWeight: 300,
  margin: '0 auto',
  padding: '8px 35px',
  position: 'absolute',
  zIndex: 1,
  width: '90%',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1rem',
    padding: '8px 20px',
    top: '25px',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '5px 30px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '.75rem',
    top: '30px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem'
  },
  [theme.breakpoints.up('xl')]: {
    top: '30px'
  }
}))

const Recommended = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  color: 'black',
  background: '#00DB98',
  borderRadius: '50px',
  fontWeight: 500,
  margin: '0 auto',
  padding: '8px 35px',
  position: 'absolute',
  zIndex: 1,
  width: '70%',
  maxWidth: '300px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1rem',
    padding: '8px 20px',
    top: '-22px',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '8px 30px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '8px 10px',
    top: '-20px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '10px 20px'
  },
  [theme.breakpoints.up('xl')]: {
    top: '-25px'
  }
}))

const BlackLink = styled(ExternalLink)(({ theme }) => ({
  marginTop: 12,
  fontWeight: 600,
  display: 'block',
  color: 'primary',
  textDecoration: 'none',
  [theme.breakpoints.down('sm')]: {
    // targeting mobile devices
    margin: 3
  }
}))

interface YearlyProps {
  plan?: string
  isYearly?: boolean
}

interface MonthlyProps {
  plan?: string
  isMonthly?: boolean
}

function BillingURL(id: 'storage' | 'sharing' | 'premium', type: 'monthly' | 'yearly' | 'trial') {
  const data = {
    id,
    type
  }

  const url = config.appUrl.billing
  const querystring = new URLSearchParams(data).toString()
  return [url, querystring].join('?')
}

function MonthlyPlans({ plan, isMonthly }: Readonly<MonthlyProps>) {
  const location = useLocation()
  const { pathname } = location
  const subscriptionPage = pathname.includes('/subscription')
  const membershipPage = pathname.includes('/settings')
  const newPaymentPage = useFlag('isNewCheckoutVisible')
  const goToNewPaymentPage = newPaymentPage && !membershipPage
  const subscriptionNextPageUrl = subscriptionPage ? '/subscription/payment' : '/auth/register'
  const { trackEvent } = useGAEvent()

  // capture event for page view
  useEffectOnce(() => {
    trackEvent('view_item_list', {
      ecommerce: {
        item_list_id: 'monthly plans',
        item_list_name: 'monthly plans',
        items: [
          {
            item_id: environment === 'stage' ? '6f0ba9cb-7dcf-4f89-9fda-ba430662b006' : '6f0ba9cb-7dcf-4f89-9fda-ba430662b006',
            item_name: 'storage_monthly',
            price: 5
          },
          {
            item_id: environment === 'stage' ? 'f4041bef-924b-451b-88dc-3a8aba90ec5e' : 'f4041bef-924b-451b-88dc-3a8aba90ec5e',
            item_name: 'sharing_monthly',
            price: 8
          },
          {
            item_id: environment === 'stage' ? '3e6d9700-24cf-4a70-9b22-ece1fab471b1' : '3e6d9700-24cf-4a70-9b22-ece1fab471b1',
            item_name: 'sharing_trial',
            price: 0
          },
          {
            item_id: environment === 'stage' ? 'ce2dd8aa-69c7-47f4-ae4d-9f09e094f13c' : 'ce2dd8aa-69c7-47f4-ae4d-9f09e094f13c',
            item_name: 'hosting_monthly',
            price: 13
          }
        ]
      }
    })
  })

  const determainPlanId = (planOrTrial: string | undefined) => {
    if (planOrTrial === 'trial') {
      return environment === 'stage' ? '3e6d9700-24cf-4a70-9b22-ece1fab471b1' : '3e6d9700-24cf-4a70-9b22-ece1fab471b1'
    }
    return environment === 'stage' ? 'f4041bef-924b-451b-88dc-3a8aba90ec5e' : 'f4041bef-924b-451b-88dc-3a8aba90ec5e'
  }

  let planNameOrTrial: 'monthly' | 'annual' | 'trial'
  if (plan === undefined) {
    planNameOrTrial = 'trial'
  } else if (subscriptionPage) {
    planNameOrTrial = 'trial'
  } else {
    planNameOrTrial = 'monthly'
  }

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          {subscriptionPage && plan === 'storage' && isMonthly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
          <CardHead>My Bucket</CardHead>
          <CardStorage>1 TB of basic storage</CardStorage>
          <CardPrice>$5</CardPrice>
          <CardTerm>per month</CardTerm>
          {!subscriptionPage && plan === 'storage' && isMonthly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-monthly-storage"
                fullWidth
                href={goToNewPaymentPage ? `${subscriptionNextPageUrl}?plan_name=storage&billing_cycle=monthly` : BillingURL('storage', 'monthly')}
                variant="contained"
                onClick={() => {
                  trackEvent('select_item', {
                    ecommerce: {
                      item_list_id: environment === 'stage' ? '6f0ba9cb-7dcf-4f89-9fda-ba430662b006' : '6f0ba9cb-7dcf-4f89-9fda-ba430662b006',
                      item_list_name: 'storage-monthly',
                      items: [{ item_id: 'monthly_storage', item_name: 'monthly storage', price: 5 }]
                    }
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="storage" />
        </StyledCard>
        <BlackLink
          href={goToNewPaymentPage ? `${subscriptionNextPageUrl}?plan_name=storage&billing_cycle=annual` : BillingURL('storage', 'yearly')}
          onClick={() => {
            trackEvent('select_item', {
              ecommerce: {
                item_list_id: environment === 'stage' ? '9901c53a-671a-41f2-9f4d-c22d013400bf' : '9901c53a-671a-41f2-9f4d-c22d013400bf',
                item_list_name: 'storage-annual',
                items: [{ item_id: 'storage_annual', item_name: 'storage annual', price: 50 }]
              }
            })
          }}
        >
          <Box component="span">Save another $10 by paying yearly</Box>
        </BlackLink>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard sx={{ border: '1px solid blue' }}>
          <Recommended>Most Popular</Recommended>
          {subscriptionPage && plan === 'sharing' && isMonthly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
          <CardHead>Group Buckets</CardHead>
          <CardStorage>1 TB of storage + sharing</CardStorage>
          <CardPrice>$8</CardPrice>
          <CardTerm>per month</CardTerm>
          {!subscriptionPage && plan === 'sharing' && isMonthly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-testid="plans-monthly-sharing"
                fullWidth
                href={
                  goToNewPaymentPage
                    ? `${subscriptionNextPageUrl}?plan_name=sharing&billing_cycle=${planNameOrTrial}`
                    : BillingURL('sharing', planNameOrTrial)
                }
                variant="contained"
                sx={{ background: 'linear-gradient(175deg, #202CD3 5.3%, #02E39F 102.34%)' }}
                onClick={() => {
                  trackEvent('select_item', {
                    ecommerce: {
                      item_list_id: determainPlanId(planNameOrTrial),
                      item_list_name: `sharing-${planNameOrTrial}`,
                      items: [
                        {
                          item_id: `sharing_${planNameOrTrial}`,
                          item_name: `sharing ${planNameOrTrial}`,
                          price: planNameOrTrial === 'trial' ? 0 : 8,
                          location_id: planNameOrTrial === 'trial' ? 'trial' : ''
                        }
                      ]
                    }
                  })
                }}
              >
                {planNameOrTrial === 'trial' ? 'Start 7-day Free Trial' : 'Purchase'}
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="sharing" />
        </StyledCard>
        <BlackLink
          href={goToNewPaymentPage ? `${subscriptionNextPageUrl}?plan_name=sharing&billing_cycle=annual` : BillingURL('sharing', 'yearly')}
          onClick={() => {
            trackEvent('select_item', {
              item_list_id: environment === 'stage' ? 'f4041bef-924b-451b-88dc-3a8aba90ec5e' : 'f4041bef-924b-451b-88dc-3a8aba90ec5e',
              item_list_name: 'sharing-annual',
              items: [{ item_id: 'sharing_annual', item_name: 'sharing annual', price: 8 }]
            })
          }}
        >
          <Box component="span">Save another $16 by paying yearly</Box>
        </BlackLink>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          {subscriptionPage && plan === 'expert' && isMonthly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
          <CardHead>Hosting</CardHead>
          <CardStorage>Embedded sharing</CardStorage>
          <CardPrice>$13</CardPrice>
          <CardTerm>per month</CardTerm>
          {!subscriptionPage && plan === 'expert' && isMonthly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-testid="plans-monthly-hosting"
                fullWidth
                href={goToNewPaymentPage ? `${subscriptionNextPageUrl}?plan_name=expert&billing_cycle=monthly` : BillingURL('premium', 'monthly')}
                variant="contained"
                onClick={() => {
                  trackEvent('select_item', {
                    ecommerce: {
                      item_list_id: environment === 'stage' ? 'ce2dd8aa-69c7-47f4-ae4d-9f09e094f13c' : 'ce2dd8aa-69c7-47f4-ae4d-9f09e094f13c',
                      item_list_name: 'hosting-monthly',
                      items: [{ item_id: 'hosting_monthly', item_name: 'hosting monthly', price: 13 }]
                    }
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="premium" />
        </StyledCard>
        <BlackLink
          href={goToNewPaymentPage ? `${subscriptionNextPageUrl}?plan_name=expert&billing_cycle=annual` : BillingURL('premium', 'yearly')}
          onClick={() => {
            trackEvent('select_item', {
              ecommerce: {
                item_list_id: environment === 'stage' ? '82ce8670-7abc-4fa4-8868-bce86e5da8ef' : '82ce8670-7abc-4fa4-8868-bce86e5da8ef',
                item_list_name: 'hosting-annual',
                items: [{ item_id: 'hosting_annual', item_name: 'hosting annual', price: 140 }]
              }
            })
          }}
        >
          <Box data-testid="black-link-annual-hosting" component="span">
            Save another $16 by paying yearly
          </Box>
        </BlackLink>
      </Grid>
    </StyledGrid>
  )
}

MonthlyPlans.defaultProps = {
  plan: undefined,
  isMonthly: false
}

function YearlyPlans({ plan, isYearly }: Readonly<YearlyProps>) {
  const newPaymentPage = useFlag('isNewCheckoutVisible')
  const location = useLocation()
  const { pathname } = location
  const subscriptionPage = pathname.includes('/subscription')
  const membershipPage = pathname.includes('/settings')
  const goToNewPaymentPage = newPaymentPage && !membershipPage
  const subscriptionNextPageUrl = subscriptionPage ? '/subscription/payment' : '/auth/register'
  const { trackEvent } = useGAEvent()

  // capture event for page view
  useEffectOnce(() => {
    trackEvent('view_item_list', {
      ecommerce: {
        item_list_id: 'annual plans',
        item_list_name: 'annual plans',
        items: [
          {
            item_id: environment === 'stage' ? '9901c53a-671a-41f2-9f4d-c22d013400bf' : '9901c53a-671a-41f2-9f4d-c22d013400bf',
            item_name: 'storage_annual',
            price: 50
          },
          {
            item_id: environment === 'stage' ? '96cda310-2859-4f4c-bbc6-f6f4c5d09296' : '96cda310-2859-4f4c-bbc6-f6f4c5d09296',
            item_name: 'sharing_annual',
            price: 80
          },
          {
            item_id: environment === 'stage' ? '82ce8670-7abc-4fa4-8868-bce86e5da8ef' : '82ce8670-7abc-4fa4-8868-bce86e5da8ef',
            item_name: 'hosting_annual',
            price: 140
          }
        ]
      }
    })
  })

  return (
    <StyledGrid container spacing={2} display="flex" justifyContent="center" alignItems="stretch">
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          {subscriptionPage && plan === 'storage' && isYearly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
          <CardHead>My Bucket</CardHead>
          <CardStorage>1 TB of basic storage</CardStorage>
          <CardPrice>$50</CardPrice>
          <CardTerm>per year</CardTerm>
          {!subscriptionPage && plan === 'storage' && isYearly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-yearly-storage"
                fullWidth
                href={goToNewPaymentPage ? `${subscriptionNextPageUrl}?plan_name=storage&billing_cycle=annual` : BillingURL('storage', 'yearly')}
                variant="contained"
                onClick={() => {
                  trackEvent('select_item', {
                    ecommerce: {
                      item_list_id: environment === 'stage' ? '9901c53a-671a-41f2-9f4d-c22d013400bf' : '9901c53a-671a-41f2-9f4d-c22d013400bf',
                      item_list_name: 'storage-annual',
                      items: [{ item_id: 'storage_annual', item_name: 'storage annual', price: 50 }]
                    }
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="storage" />
        </StyledCard>
        <CardSavings>
          You <Box component="span">save $10</Box> vs a monthly payment
        </CardSavings>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          {subscriptionPage && plan === 'sharing' && isYearly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
          <CardHead>Group Buckets</CardHead>
          <CardStorage>1 TB of storage + sharing</CardStorage>
          <CardPrice>$80</CardPrice>
          <CardTerm>per year</CardTerm>
          {!subscriptionPage && plan === 'sharing' && isYearly === true ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-yearly-sharing"
                fullWidth
                href={goToNewPaymentPage ? `${subscriptionNextPageUrl}?plan_name=sharing&billing_cycle=annual` : BillingURL('sharing', 'yearly')}
                variant="contained"
                onClick={() => {
                  trackEvent('select_item', {
                    ecommerce: {
                      item_list_id: environment === 'stage' ? '96cda310-2859-4f4c-bbc6-f6f4c5d09296' : '96cda310-2859-4f4c-bbc6-f6f4c5d09296',
                      item_list_name: 'sharing-annual',
                      items: [{ item_id: 'sharing_annual', item_name: 'sharing annual', price: 80 }]
                    }
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="sharing" />
        </StyledCard>
        <CardSavings>
          You <Box component="span">save $16</Box> vs a monthly payment
        </CardSavings>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          {subscriptionPage && plan === 'expert' && isYearly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
          <CardHead>Hosting</CardHead>
          <CardStorage>Embedded sharing</CardStorage>
          <CardPrice>$140</CardPrice>
          <CardTerm>per year</CardTerm>
          {!subscriptionPage && plan === 'expert' && isYearly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-testid="plans-annual-hosting"
                fullWidth
                href={goToNewPaymentPage ? `${subscriptionNextPageUrl}?plan_name=expert&billing_cycle=annual` : BillingURL('premium', 'yearly')}
                variant="contained"
                onClick={() => {
                  trackEvent('select_item', {
                    ecommerce: {
                      item_list_id: environment === 'stage' ? '82ce8670-7abc-4fa4-8868-bce86e5da8ef' : '82ce8670-7abc-4fa4-8868-bce86e5da8ef',
                      item_list_name: 'hosting-annual',
                      items: [{ item_id: 'hosting_annual', item_name: 'hosting annual', price: 140 }]
                    }
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="premium" />
        </StyledCard>
        <CardSavings>
          You <Box component="span">save $16</Box> vs a monthly payment
        </CardSavings>
      </Grid>
    </StyledGrid>
  )
}

YearlyPlans.defaultProps = {
  plan: undefined,
  isYearly: false
}
export { MonthlyPlans, YearlyPlans }

import { Button, Divider, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { getAuth } from 'firebase/auth'
import VerifyEmailLogo from '../../../../svg/VerifyEmailLogo'
import useEmailVerification from '../../hooks/useEmailVerification'
import { State } from './types'

const ContainerDiv = styled('div')((props) => ({
  border: '1px dashed #999',
  margin: '20px',
  padding: '90px 20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  [props.theme.breakpoints.down('sm')]: {
    padding: 0,
    border: 'none'
  }
}))

const TitleTypography = styled(Typography)((props) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  margin: '10px 0 20px',
  [props.theme.breakpoints.down('sm')]: {
    margin: '20px 0 40px'
  }
}))

const ContentDiv = styled('div')((props) => ({
  marginLeft: '30px',
  width: '65%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  [props.theme.breakpoints.down('sm')]: {
    margin: 0
  }
}))

const StyledDivider = styled(Divider)((props) => ({
  width: '100%',
  marginBottom: '20px',
  backgroundColor: '#024b9e',
  [props.theme.breakpoints.down('sm')]: {
    margin: 0,
    display: 'none'
  }
}))

const StyledButton = styled(Button)((props) => ({
  marginRight: '20px',
  marginBottom: '10px',
  textTransform: 'none',
  [props.theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

const ButtonContentDiv = styled('div')((props) => ({
  marginLeft: '30px',
  width: '50%',
  textAlign: 'center',
  marginTop: '20px',
  [props.theme.breakpoints.down('sm')]: {
    marginLeft: 0
  }
}))

export default function EmailNotVerified() {
  const theme = useTheme()
  const isSmallSize = useMediaQuery(theme.breakpoints.down('md'))
  const { resendEmailConfirmation, resendState, resetSendState } = useEmailVerification()

  const email = getAuth().currentUser?.email ?? 'your email'
  const subtitle = `In order to use Photobucket, you must verify your email. For security purposes, please click the link we sent you at ${email} in order to access your account. To change your email submit a support ticket.`

  const handleChangeEmail = () => {
    window.open('https://support.photobucket.com/hc/en-us/requests/new')
  }

  const handleResendEmail = () => {
    resendEmailConfirmation()
  }

  return (
    <ContainerDiv style={{ minHeight: 'calc(100vh - 270px)' }}>
      <VerifyEmailLogo width={isSmallSize ? 121 : 188} height={isSmallSize ? 76 : 120} />
      <ContentDiv>
        <TitleTypography variant="h2" id="secure-email-title" data-test="secure-email-title">
          Verify Your Email
        </TitleTypography>
        <StyledDivider />
        <Typography variant="body1">{subtitle}</Typography>
      </ContentDiv>
      <ButtonContentDiv>
        <StyledButton
          disabled={[State.SUCCESS, State.LOADING].includes(resendState)}
          data-test="verifybtn-send-again"
          id="verifybtn-send-again"
          size="large"
          variant="contained"
          onClick={handleResendEmail}
        >
          Send Again
        </StyledButton>
        <StyledButton data-test="verifybtn-change-email" id="verifybtn-send-again" size="large" variant="contained" onClick={handleChangeEmail}>
          Submit Request To Support
        </StyledButton>
        {resendState === State.SUCCESS && (
          <>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Didn&rsquo;t get an email?
            </Typography>
            <StyledButton data-test="verifybtn-try-again" id="verifybtn-try-again" size="large" variant="outlined" onClick={resetSendState}>
              Try again?
            </StyledButton>
          </>
        )}
      </ButtonContentDiv>
    </ContainerDiv>
  )
}
